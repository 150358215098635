import { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap';
import css from './SideMenuItem.module.scss';

type SideMenuItemProps = {
    text: string,
    icon: string,
    url: string
}

export class SideMenuItem extends Component<SideMenuItemProps, {}> {
    render() {
        return (
            <div className={css.sideMenuItem}>
                <NavItem>
                    <NavLink tag={RRNavLink} className={`text-dark ${css.menuLink}`} to={this.props.url}>
                        <span className={css.icon}>
                            <i className={this.props.icon}/>
                        </span>
                        <span className={css.text}>
                            {this.props.text}
                        </span>
                    </NavLink>
                </NavItem>
            </div>
        );
    }
}
