import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select, { MultiValue, SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { createGroupOption, createGroupOptions, GroupOption, GroupedOption } from 'utils/selectHelper';
import { Group, PermissionGroup } from 'logic/apiCalls';
import { toDisplayString } from 'logic/displayString';

export type DoneInfo = {
    group: Group,
    permissions: string[],
    isUpdate: boolean
}

type PermissionOption = {
    value: string, 
    label: string
}

type ModalPermissionGroupAddOrUpdateProps = {
    isOpen: boolean,
    groups: Array<Group>,
    groupToUpdate: PermissionGroup | null,
    availablePermissions: Array<string>,
    onToggle: () => void,
    onAddOrUpdate: (doneInfo: DoneInfo) => void
}

type ModalPermissionGroupAddOrUpdateState = {
    groupOptions: Array<GroupedOption> | undefined,
    availablePermissionOptions:  Array<PermissionOption>,
    isUpdating: boolean,
    isGroupDropdownOpen: boolean,        
    selectedGroupOption: GroupOption | null,
    selectedPermissionOptions: Array<PermissionOption> | null
}

export class ModalPermissionGroupAddOrUpdate extends Component<ModalPermissionGroupAddOrUpdateProps, ModalPermissionGroupAddOrUpdateState> {
    state: ModalPermissionGroupAddOrUpdateState = {
        groupOptions: undefined,
        availablePermissionOptions: [],
        isUpdating: false,
        isGroupDropdownOpen: false,        
        selectedGroupOption: null,        
        selectedPermissionOptions: null
    }
   
    componentDidUpdate(prevProps: ModalPermissionGroupAddOrUpdateProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const groupOptions = createGroupOptions(this.props.groups);
        const availablePermissionOptions = this.createAvailablePermissionsOptions()
        const isUpdating = !!this.props.groupToUpdate;

        const selectedGroupOption = isUpdating && this.props.groupToUpdate ? createGroupOption(this.props.groupToUpdate) : null;
        const selectedPermissionOptions = isUpdating && this.props.groupToUpdate ? this.getPermissionOptionsToUpdate(this.props.groupToUpdate.permissions, availablePermissionOptions) : null;

        this.setState(
            {
                groupOptions: groupOptions,
                availablePermissionOptions: availablePermissionOptions,
                isUpdating: isUpdating,
                selectedGroupOption: selectedGroupOption,
                selectedPermissionOptions: selectedPermissionOptions
            }
        );
    }

    createAvailablePermissionsOptions = () => {
        return this.props.availablePermissions.sort().map(permission => { return this.createPermissionOption(permission); });
    }

    createPermissionOption = (permission: string) => {
        return { value: permission, label: toDisplayString(permission) };
    }

    getPermissionOptionsToUpdate = (permissions: string[], availablePermissionsOptions: PermissionOption[]) => {
        let matchingPermissions = [];

        for (let permission of permissions.sort()) {
            let matchingPermission = availablePermissionsOptions.find(item => item.value === permission);
            if (matchingPermission) {
                matchingPermissions.push(matchingPermission);
            }
        }

        return matchingPermissions;
    }

    handleSelectedGroupChange = (selectedGroupOption: SingleValue<GroupOption>) => {
        let groupOption: GroupOption = selectedGroupOption as GroupOption;

        if (selectedGroupOption?.label === selectedGroupOption?.value) {
            groupOption = createGroupOption({ id: groupOption.value.toString(), name: groupOption.value.toString(), type: '' });
        }

        this.setState({
            selectedGroupOption: groupOption
        });
    }

    handleSelectedPermissionsChange = (selectedPermissionOptions: MultiValue<PermissionOption>) => {
        this.setState(
            {
                selectedPermissionOptions: selectedPermissionOptions as Array<PermissionOption>
            }
        );
    }

    handleDoneButtonClicked = () => {
        if (!this.state.selectedGroupOption) {
            return;
        }

        const addResponse: DoneInfo = {
            group: this.state.selectedGroupOption.value ,
            permissions: this.state.selectedPermissionOptions ? this.state.selectedPermissionOptions.map(permissionOption => permissionOption.value) : [],
            isUpdate: this.state.isUpdating
        }

        this.props.onAddOrUpdate(addResponse);
    }

    handleCancelButtonClicked = () => {
        this.setState({
            selectedGroupOption: null,
            selectedPermissionOptions: null
        });

        this.props.onToggle();
    }   

    render() {
        const doneButtonText = this.state.isUpdating ? 'Update' : 'Add';
        const isButtonEnabled = !!this.state.selectedGroupOption && this.state.selectedPermissionOptions && this.state.selectedPermissionOptions.length > 0;  

        const formatCreateLabel = (inputValue: string) => `Use Entra ID: ${inputValue}`;

        return <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>Group permissions</ModalHeader>
            <ModalBody>
                <CreatableSelect formatCreateLabel={formatCreateLabel} isDisabled={this.state.isUpdating} isSearchable value={this.state.selectedGroupOption} options={this.state.groupOptions} onChange={this.handleSelectedGroupChange} placeholder='Select group or enter Microsoft Entra ID...' />
                <Select className='my-3' isMulti inputValue='' value={this.state.selectedPermissionOptions} options={this.state.availablePermissionOptions} onChange={this.handleSelectedPermissionsChange} placeholder='Select permissions...' />              
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!isButtonEnabled} onClick={this.handleDoneButtonClicked}>{doneButtonText}</Button>{' '}
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

