import { Component, useContext } from 'react';
import { Alert } from 'reactstrap';
import { Loader } from 'components/Loader';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { MobileRecordedUserEntries } from 'components/MobileRecordedUserEntries';
import { DoneInfo, ModalMobileUserAddOrUpdate } from 'components/ModalMobileUserAddOrUpdate';
import { MobileRecordedUser } from 'logic/apiCalls';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

type MobileRecordingInternalProps = {
    notificationContext: NotificationContextState
}

type MobileRecordingInternalState = {
    isLoading: boolean,
    showApiError: boolean,
    mobileRecordedUsers: MobileRecordedUser[],    
    mobileRecordedUserToUpdate: MobileRecordedUser | null,
    isUpdateModalOpen: boolean
}

class MobileRecordingInternal extends Component<MobileRecordingInternalProps, MobileRecordingInternalState> {
    state: MobileRecordingInternalState = {
        isLoading: true,
        showApiError: false,
        mobileRecordedUsers: [],
        mobileRecordedUserToUpdate: null,
        isUpdateModalOpen: false
    }   

    async componentDidMount() {
        await this.loadPageData();
    }

    toggleUpdateModal = () => {
        this.setState({
            isUpdateModalOpen: !this.state.isUpdateModalOpen
        });
    }

    openUpdateModal = (mobileRecordedUserToUpdate: MobileRecordedUser) => {
        this.setState({
            isUpdateModalOpen: true,
            mobileRecordedUserToUpdate: mobileRecordedUserToUpdate
        });
    }

    handleEditButtonClicked = (mobileRecordedUserToUpdate: MobileRecordedUser) => {
        this.openUpdateModal(mobileRecordedUserToUpdate);
    }

    loadPageData = async () => {
        try {
            this.setState({                
                isLoading: true
            });

            const mobileRecordedUsers = await apiCalls.getMobileRecordedUsers();

            this.setState({
                isLoading: false,
                showApiError: false,
                mobileRecordedUsers: mobileRecordedUsers
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,             
                showApiError: true
            });
        }
    }

    executeUpdate = async (doneInfo: DoneInfo) => {
        log.debug(`[executeUpdate] Updating mobile user ${doneInfo.phoneNumber}...`);

        try {
            await apiCalls.updateMobileRecordedUser(doneInfo.phoneNumber, doneInfo.name, doneInfo.azureUserId, doneInfo.recorded);
                        
            await this.loadPageData();

            const notificationText = `Successfully updated phone number info.`;
            this.props.notificationContext.setNotification("Phone number configuration modified", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the phone number configuration.`, 'danger');
        }
        finally {
            this.toggleUpdateModal();
        }
    }
   
    renderMobileRecorderUserSection = () => {
        return (
            <div className="my-5">                    
                    <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                        <>
                            {this.renderMobileRecordedUserEntries()}
                        </>
                    </Loader>
            </div>            
        )        
    }

    renderMobileRecordedUserEntries = () => {
        
        if (this.state.mobileRecordedUsers.length > 0) {
            return <MobileRecordedUserEntries mobileUserInfos={this.state.mobileRecordedUsers} handleEdit={this.handleEditButtonClicked} handleDelete={() => {}} showDelete={false} />
        }

        return <Alert color='danger'>
            No phone numbers are provisioned.
        </Alert>
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <>                
                    <ModalMobileUserAddOrUpdate isOpen={this.state.isUpdateModalOpen} mobileUserToUpdate={this.state.mobileRecordedUserToUpdate} allowUpdateRecorded={false} onToggle={this.toggleUpdateModal} onAddOrUpdate={this.executeUpdate}/>
                    {this.renderMobileRecorderUserSection()}
                </>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const MobileRecording = (props: any) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <MobileRecordingInternal {...props} notificationContext={notificationContext} />
    )
}