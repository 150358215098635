import { Component, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { triggerDownloadBlob } from 'logic/download';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

type TabMobileSettingsDownloadInternalProps = {
    notificationContext: NotificationContextState
}

type TabMobileSettingsDownloadInternalState = {
    excelEnhancement: boolean
}

class TabMobileSettingsDownloadInternal extends Component<TabMobileSettingsDownloadInternalProps, TabMobileSettingsDownloadInternalState> {
    state: TabMobileSettingsDownloadInternalState = {
        excelEnhancement: false
    }

    downloadCsv = async () => {
        try {
            const configurationScriptBlob = await apiCalls.getMobilePhoneNumbersAsCsv(this.state.excelEnhancement);
            triggerDownloadBlob(configurationScriptBlob, 'phone_numbers.csv');
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during download of the export of the mobile phone numbers.`, 'danger');
        }
    }

    handleExcelEnhancementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            excelEnhancement: e.target.checked
        })
    }

    render() {
        return (
            <Loader isLoading={false} showApiError={false}>
                <> 
                    <div className="my-3">Click the button below to download all the provisioned mobile phone numbers for all tenants.</div>
                    <Form.Switch
                        id='excelFormat'
                        label='Excel enhancement'
                        checked={this.state.excelEnhancement}
                        onChange={this.handleExcelEnhancementChange}
                    />              
                    <Button className='mt-3' color='primary' onClick={this.downloadCsv}>Download</Button>
                </>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabMobileSettingsDownload = (props: any) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabMobileSettingsDownloadInternal {...props} notificationContext={notificationContext} />
    )
}