import { FC } from 'react';
import * as skinning from 'logic/skinning';
import css from './CompanyLogo.module.scss';

export interface CompanyLogoProps {
    className?: string;
}

export const CompanyLogo: FC<CompanyLogoProps> = ({className}) => {
  const logoImageSource = skinning.isKpn() ? "images/KPN-Logo.png" : "images/cybertwice_logo_v2_notext.svg";      

  return (
    <div className={`${css.logo} ${className}`}>
        <img src={logoImageSource} alt="Company logo" />
    </div>
  );
};