import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import { DoneButton } from 'components/DoneButton';
import { MobileRecordedUser } from 'logic/apiCalls';
import * as validation from 'utils/validation';

type ModalMobileUserAddOrUpdateProps = {
    isOpen: boolean,
    mobileUserToUpdate: MobileRecordedUser | null,
    allowUpdateRecorded: boolean,
    onToggle: ToggleCallBack,
    onAddOrUpdate: AddOrUpdateCallback
}

type ModalMobileUserAddOrUpdateState = {
    isUpdating: boolean,        
    phoneNumber: string,
    name: string,
    azureUserId: string,
    recorded: boolean,
    isBusy: boolean
}

export type DoneInfo = {
    phoneNumber: string,
    name: string,
    azureUserId: string,
    recorded: boolean,
    isUpdate: boolean
}

export type ToggleCallBack = () => void

export type AddOrUpdateCallback = (doneInfo: DoneInfo) => void

export class ModalMobileUserAddOrUpdate extends Component<ModalMobileUserAddOrUpdateProps, ModalMobileUserAddOrUpdateState> {
    state : ModalMobileUserAddOrUpdateState = {
        isUpdating: false,
        phoneNumber: "",
        name: "",
        azureUserId: "",
        recorded: false ,
        isBusy: false       
    }

    componentDidUpdate(prevProps: ModalMobileUserAddOrUpdateProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const isUpdating = !!this.props.mobileUserToUpdate;
        
        this.setState(
            {
                isUpdating: isUpdating,
                phoneNumber: this.props.mobileUserToUpdate?.mobilePhoneNumber || "",
                name: this.props.mobileUserToUpdate?.name || "",
                azureUserId: this.props.mobileUserToUpdate?.azureAdUserId || "",
                recorded: this.props.mobileUserToUpdate ? this.props.mobileUserToUpdate.recorded : true,
                isBusy: false
            }
        );
    }

    handleDoneButtonClicked = () => {
        const doneInfo: DoneInfo = {
            phoneNumber: this.state.phoneNumber,
            name: this.state.name,
            azureUserId: this.state.azureUserId,
            isUpdate: this.state.isUpdating,
            recorded: this.state.recorded
        }

        this.setState({
            isBusy: true
        });

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.setState({            
        });
        this.props.onToggle();
    }

    handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0 && !validation.isPartialDutchMobilePhoneNumber(e.target.value)) {
            return;
        }

        this.setState({
            phoneNumber: e.target.value
        })
    }

    handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0 && !validation.isValidMobileUserName(e.target.value)) {
            return;
        }

        this.setState({
            name: e.target.value
        })
    }

    handleAzureUserIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            azureUserId: e.target.value
        })
    }

    handleRecordedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            recorded: e.target.checked
        })
    }

    shouldAddOrUpdateButtonBeEnabled = () : boolean => {
        // If an Azure User Id is provided, it must be a valid GUID. A valid Dutch mobile phone number is required.
        return ((!this.state.azureUserId || validation.isValidGuid(this.state.azureUserId)) &&
                validation.isDutchMobilePhoneNumber(this.state.phoneNumber));
    }

    determinAddOrUpdateButtonTooltip = () : string | undefined => {
        if (!validation.isDutchMobilePhoneNumber(this.state.phoneNumber)) {
            return "A valid Dutch mobile phone number is required";
        }
        
        if (this.state.azureUserId && !validation.isValidGuid(this.state.azureUserId)) {  
            return "Azure User Id must be a valid GUID";
        }

        return undefined;
    }

    renderRecorded = () => {
        if (!this.props.mobileUserToUpdate) {
            return null;
        }

        return (<>           
            <Form.Group controlId="recordingEnabled">
                <Row>
                    <Col sm={4}>
                        <Form.Label>Recorded</Form.Label>
                    </Col>
                    <Col>
                        <Form.Switch disabled={!this.props.allowUpdateRecorded} id="phoneNumberRecorded" type="switch" checked={this.state.recorded} onChange={this.handleRecordedChange} />
                    </Col>
                </Row>
                <Form.Text className="text-muted">
                    Defines whether this mobile phone number is recorded or not.
                </Form.Text>
            </Form.Group>
        </>
        )
    }

    render() {
        const doneButtonText = this.state.isUpdating ? 'Update' : 'Add';

        const enableAddOrUpdateButton = this.shouldAddOrUpdateButtonBeEnabled();
        const addOrUpdateButtonTooltip = enableAddOrUpdateButton ? undefined : this.determinAddOrUpdateButtonTooltip();

        return <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>Phone number info</ModalHeader>
            <ModalBody>
                <Form className="mt-3">
                    <Form.Group controlId="mobileUserPhoneNumber">
                        <Form.Label>PhoneNumber</Form.Label>
                        <Form.Control autoComplete="off" readOnly={this.state.isUpdating} type="text" placeholder="+31612345678" value={this.state.phoneNumber} onChange={this.handlePhoneNumberChange} />
                        {
                            !this.state.isUpdating ? 
                                <Form.Text className="text-muted">
                                    The phone number in E.164 format
                                </Form.Text> : 
                                null    
                        }
                    </Form.Group>
                </Form>
                <Form className="mt-3">
                    <Form.Group controlId="mobileUserName">
                        <Form.Label>User name</Form.Label>
                        <Form.Control autoComplete="off" type="text" placeholder="Enter user name" value={this.state.name} onChange={this.handleNameChanged}  />
                        <Form.Text className="text-muted">
                            The display name of the user.
                        </Form.Text>
                    </Form.Group>
                </Form>
                <Form className="mt-3">
                    <Form.Group controlId="mobileUserAzureId">
                        <Form.Label>Microsoft Entra Id</Form.Label>
                        <Form.Control autoComplete="off" type="text" placeholder="Enter Microsoft Entra Id" value={this.state.azureUserId} onChange={this.handleAzureUserIdChanged}  />
                        <Form.Text className="text-muted">
                            This Id is a guid like 'ef9ed768-3558-461b-871d-dda3b6d37bc2'.
                        </Form.Text>
                    </Form.Group>
                </Form>
                {this.renderRecorded()}
            </ModalBody>

            <ModalFooter>
                <DoneButton id="userDoneButton" showBusy={this.state.isBusy} buttonText={doneButtonText} onClick={this.handleDoneButtonClicked} disabled={!enableAddOrUpdateButton} disabledTooltip={addOrUpdateButtonTooltip}/>                
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

