import { Group } from 'logic/apiCalls';
import { compareCaseInsensitive } from './misc';

export interface GroupOption {
  value: Group;
  label: string;
}

export interface GroupedOption {
  label: string;
  options: GroupOption[];
}

export const createGroupOption = (group: Group): GroupOption => {
  return { value: group, label: group.name };
};

// Method creates the options in the format as required for the 'react-select' component.
// The options are grouped under the different group types ('Microsoft365', 'Team' and 'Security')
// and then sorted on name.
export const createGroupOptions = (groups: Group[]): GroupedOption[] => {
  const groupTypeNames = groups.map((item) => item.type).filter((value, index, self) => self.indexOf(value) === index).sort();

  let groupedOptions: GroupedOption[] = [];
  for (const groupTypeName of groupTypeNames) {
    let groupOption: GroupedOption = {
      label: groupTypeName,
      options: groups.filter((item) => item.type === groupTypeName).sort((a, b) => compareCaseInsensitive(a.name, b.name)).map((item) => createGroupOption(item)),
    };

    groupedOptions.push(groupOption);
  }

  return groupedOptions;
};