import { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext, UserContextState } from 'context/UserContext';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { SpinnerWithDescription } from 'components/SpinnerWithDescription';
import { urls } from 'logic/urls';
import log from 'loglevel';

type InitialPageInternalProps = {
    userContext: UserContextState,
    tenantContext: TenantContextState
}

type InitialPageState = {
    isLoading: boolean,
    showApiError: boolean
}

export class InitialPageInternal extends Component<InitialPageInternalProps, InitialPageState> {
    render() {
        if (!this.props.tenantContext.isTenantInfoRetrieved()) {
            log.debug(`[InitialPage, Render] Tenant info not yet retrieved. Showing spinner...`);
            return <SpinnerWithDescription description="Retrieving tenant info..."/>
        }

        if (this.props.userContext.isDistributor()) {
            log.debug(`[InitialPage, Render] Distributor => redirecting to Tenants view...`);
             return <Redirect to={urls.distributorTenants} />
        }

        if (this.props.tenantContext.isAdminConsentGranted("Attest")) {
            log.debug(`[InitialPage, Render] Tenant, consent granted => redirecting to Dashboard view...`);
            return <Redirect to={urls.dashboard} />
        }
        
        log.debug(`[InitialPage, Render] Tenant, no consent granted => redirecting to Consent view...`);
        return <Redirect to={urls.consent} />
    }
}

// Inject the required contexts.
export const InitialPage = (props: any) => {
    const userContext = useContext(UserContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <InitialPageInternal userContext={userContext} tenantContext={tenantContext} {...props}/>
    )
}