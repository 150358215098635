import { Component } from 'react';
import { Alert } from 'reactstrap';
import { Loader } from 'components/Loader';
import { SubscriptionEntriesWithHeader } from './SubscriptionEntriesWithHeader';
import { Subscription } from 'logic/apiCalls';
import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

type SubscriptionPageState = {
    isLoading: boolean,
    showApiError: boolean,
    subscriptions: Subscription[]
}

export class SubscriptionPage extends Component<{}, SubscriptionPageState> {
    state: SubscriptionPageState = {
        isLoading: true,
        showApiError: false,
        subscriptions: []
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    loadPageData = async () => {
        try {
            const subscriptions = await apiCalls.getSubscriptions();

            this.setState({
                isLoading: false,
                showApiError: false,
                subscriptions: subscriptions          
            });
        }
        catch (error) {
            log.error(`Error occurred while retrieving the subscription data: ${error}`);

            this.setState({                             
                isLoading: false,
                showApiError: true
            });
        }
    }

    getSubscriptionsWithType = (subscriptionType: string) => {
        return this.state.subscriptions.filter(item => item.subscriptionType === subscriptionType);
    }

    renderContent = () => {
        const teamsSubscriptions = this.getSubscriptionsWithType("teams");        
        const cybergateSubscriptions = this.getSubscriptionsWithType("cybergate");
        const mobileSubscriptions = this.getSubscriptionsWithType("mobile");
        
        if (mobileSubscriptions.length === 0 &&
            cybergateSubscriptions.length === 0 &&
            teamsSubscriptions.length === 0) {
                return <Alert color='danger'>No subscriptions found in your organization.</Alert>
        }

        return <>
            <SubscriptionEntriesWithHeader headerName="Teams" subscriptions={teamsSubscriptions} showInfo />            
            <SubscriptionEntriesWithHeader headerName="CyberGate" subscriptions={cybergateSubscriptions} />
            <SubscriptionEntriesWithHeader headerName="Mobile" subscriptions={mobileSubscriptions} />            
        </>
    }
  
    render() {
        return <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError} render={this.renderContent} />
    }
}