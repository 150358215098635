import { Component, useContext } from 'react';
import Select from 'react-select'
import { Alert, Button, Form, FormGroup, Label } from 'reactstrap';
import { Loader } from 'components/Loader';
import { oneWayCompare } from 'utils/misc';
import { ModalConfirm } from 'components/ModalConfirm';
import { TenantContext } from 'context/TenantContext';
import { NotificationContext } from 'context/NotificationContext';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';
import css from './TabProcessingRetention.module.scss';

const oneDaySeconds = 60 * 60 * 24;
const retentionOptions = [
    { value: oneDaySeconds * 10, label: "10 days" },
    { value: oneDaySeconds * 31, label: "1 month" },
    { value: oneDaySeconds * 31 * 3, label: "3 months" }
];

class TabProcessingRetentionInternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showApiError: false,
            isConfirmSaveModalOpen: false,
            stateInBackend: {
                value: 0,
            },
            selectedRetentionOption: retentionOptions[0] 
        }   
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    loadPageData = async () => {
        try {
            const tenant = await apiCalls.getTenant();

            const bestMatchingRetentionOption = this.pickBestMatchingRetentionOption(tenant.defaultRetentionSeconds);

            this.setState({
                isLoading: false,
                showApiError: false,
                selectedRetentionOption: bestMatchingRetentionOption,
                stateInBackend: {
                    selectedRetentionOption: bestMatchingRetentionOption,
                }
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    pickBestMatchingRetentionOption = (retentionSeconds) => {
        if (!retentionSeconds || retentionSeconds < retentionOptions[0].value) {
            return retentionOptions[0];
        }

        if (retentionSeconds > retentionOptions[retentionOptions.length - 1].value) {
            return retentionOptions[retentionOptions.length - 1];
        }
        
        // Find best (or exact) matching.
        let bestMatchingOption = retentionOptions[0];
        let bestMatchingDelta = Math.abs(bestMatchingOption.value - retentionSeconds);

        for (const option of retentionOptions) {
            const delta = Math.abs(option.value - retentionSeconds);
            if (delta < bestMatchingDelta) {
                bestMatchingOption = option;
                bestMatchingDelta = delta;
            }
        }

        return bestMatchingOption;
    }

    toggleConfirmSaveModal = () => {
        this.setState({
            isConfirmSaveModalOpen: !this.state.isConfirmSaveModalOpen
        });
    }

    handleSelectedRetentionChange = (retentionOption) => {
        this.setState(
            {
                selectedRetentionOption: retentionOption
            }
        );  
    }

    isSaveButtonEnabled = () => {
        return !oneWayCompare(this.state.stateInBackend, this.state);
    }

    executeSave = async () => {
        log.debug(`[executeSave] Saving the retention settings...`);

        try {
            await apiCalls.updateDefaultRetention(this.state.selectedRetentionOption.value);
            await this.loadPageData();

            this.props.notificationContext.setNotification("Recording configuration saved", `Successfully updated the retention settings.`, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the retention settings.`, 'danger');
        }
        finally {
            this.toggleConfirmSaveModal();            
        }
    }

    renderContent = () => {
        const readOnly = !this.props.tenantContext.hasTeamsRecordingSubscription();
        const isDecrease = this.state.selectedRetentionOption.value < this.state.stateInBackend.selectedRetentionOption.value;
        
        let warningText = "Are you sure you want to update the retention settings?";
        if (isDecrease) {
            warningText += `\r\n The retention time is decreased which will result in immediate deletion of all existing calls older than ${this.state.selectedRetentionOption.label}.`;
        }

        return (<div className="m-3">
            <ModalConfirm
                headerText="Confirm save"
                detailedText={warningText}
                isOpen={this.state.isConfirmSaveModalOpen}
                onToggle={this.toggleConfirmSaveModal}
                onConfirm={this.executeSave} />

            <div className="mt-3 mb-5">
                {
                    readOnly ?
                        <Alert color="warning">These settings are read-only for CyberGate recording users. To enable this feature an Attest subscription is required.</Alert> :
                        <></>
                }
                <Form>
                    <FormGroup>
                        <Label><strong>Recording storage retention</strong></Label>
                        <p>The retention sets the age of the recording after which it is removed from the Attest recorder.</p>
                        <div className="mt-3">
                            <div className={css.processingRetention}>
                                <p>Default retention</p>
                                <Select isDisabled={readOnly} isSearchable={true} value={this.state.selectedRetentionOption} options={retentionOptions} onChange={this.handleSelectedRetentionChange} placeholder='Select retention...' />
                            </div>
                        </div>
                    </FormGroup>
                </Form>
            </div>
            <Button disabled={!this.isSaveButtonEnabled() || readOnly} onClick={this.toggleConfirmSaveModal} color='primary' >Save</Button>
        </div>)
    }
   
    render() {
        return <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError} render={this.renderContent}/>
    }
}

// Inject the required contexts.
export const TabProcessingRetention = (props) => {
    const tenantContext = useContext(TenantContext);    
    const notificationContext = useContext(NotificationContext);
  
    return (
        <TabProcessingRetentionInternal {...props} tenantContext={tenantContext} notificationContext={notificationContext} />
    )
}