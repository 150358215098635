import { Component, useContext } from 'react';
import { Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { GroupPermissions } from 'components/GroupPermissions';
import { ModalConfirm } from 'components/ModalConfirm';
import { DoneInfo, ModalPermissionGroupAddOrUpdate } from 'components/ModalPermissionGroupAddOrUpdate';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { Group, PermissionGroup } from 'logic/apiCalls';
import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

type PortalAccessInternalProps = {
    notificationContext: NotificationContextState
}

type PortalAccessInternalState = {
    azureGroups: Array<Group>,
    acccessPermissionGroups: Array<PermissionGroup>,
    availablePermissions: Array<string>,
    isLoading: boolean,
    showApiError: boolean,
    isDeleteModalOpen: boolean,
    groupToDelete: Group | null,
    isAddOrUpdateModalOpen: boolean,
    groupToUpdate: PermissionGroup | null
}

export class PortalAccessInternal extends Component<PortalAccessInternalProps, PortalAccessInternalState> {
    state: PortalAccessInternalState = {
        azureGroups: [],
        acccessPermissionGroups: [],
        availablePermissions: [],
        isLoading: true,
        showApiError: false,
        isDeleteModalOpen: false,
        groupToDelete: null,
        isAddOrUpdateModalOpen: false,
        groupToUpdate: null
    }
    
    async componentDidMount() {
        await this.loadPageData();
    }

    toggleDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    }

    toggleAddOrUpdateModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: !this.state.isAddOrUpdateModalOpen
        });
    }

    openAddModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: true,
            groupToUpdate: null
        });
    }

    openUpdateModal = (groupToUpdate: PermissionGroup) => {
        this.setState({
            isAddOrUpdateModalOpen: true,
            groupToUpdate: groupToUpdate
        });
    }

    loadPageData = async () => {
        try {
            const azureGroupsPromise = apiCalls.getAzureGroups();
            const accessPermissionGroupsPromise = apiCalls.getAccessPermissionGroups();
            const availablePermissionsPromise = apiCalls.getAvailableAccessPermissions();

            const [azureGroups, accessPermissionGroups, availablePermissions] = await Promise.all([azureGroupsPromise, accessPermissionGroupsPromise, availablePermissionsPromise]);

            this.setState({
                azureGroups: azureGroups,
                acccessPermissionGroups: accessPermissionGroups,
                availablePermissions: availablePermissions,
                isLoading: false,
                showApiError: false
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    handleEditButtonClicked = (groupToUpdate: PermissionGroup) => {
        this.openUpdateModal(groupToUpdate);
    }

    handleDeleteButtonClicked = (groupToDelete: PermissionGroup) => {
        this.setState({
            groupToDelete: groupToDelete
        });

        this.toggleDeleteModal();
    }

    executeDelete = async () => {
        const groupToDelete = this.state.groupToDelete;

        if (!groupToDelete) {
            return;
        }

        log.debug(`[executeDelete] Removing all permissions from the group '${groupToDelete.name} (${groupToDelete.id})'.`);

        try {
            // The group is not actually removed, but instead an empty list of permissions is set.
            await apiCalls.setAccessPermissions(groupToDelete.id, []);
            await this.loadPageData();            
            this.props.notificationContext.setNotification("Access configuration modified", `Successfully removed permissions from group '${groupToDelete.name}'.`, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the access configuration.`, 'danger');
        }
        finally {
            this.toggleDeleteModal();
        }
    }

    executeAddOrUpdate = async (doneInfo: DoneInfo ) => {
        log.debug(`[executeAddOrUpdate] Adding/updating permission group '${doneInfo.group.name} (${doneInfo.group.id})' with permissions '${doneInfo.permissions}'...`);

        try {
            await apiCalls.setAccessPermissions(doneInfo.group.id, doneInfo.permissions);
            await this.loadPageData();

            const notificationText = doneInfo.isUpdate ? `Successfully updated permission of group '${doneInfo.group.name}'.` : `Successfully added permission group '${doneInfo.group.name}'.`;
            this.props.notificationContext.setNotification("Access configuration modified", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the access configuration.`, 'danger');
        }
        finally {
            this.toggleAddOrUpdateModal();
        }
    }

    render() {
        const groupSettingsRendered = <div className="my-3">
            <GroupPermissions name="access" permissionGroups={this.state.acccessPermissionGroups} handleEdit={this.handleEditButtonClicked} handleDelete={this.handleDeleteButtonClicked}/>
            <Button className='mt-3' color='primary' onClick={this.openAddModal}>Assign</Button>
        </div>

        const groupsWithoutConfig = this.state.azureGroups.filter(azureGroup => !this.state.acccessPermissionGroups.some(accessPermissionGroup => azureGroup.id === accessPermissionGroup.id));
        const groupToDeleteName = this.state.groupToDelete?.name || '';

        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <ModalConfirm
                    headerText="Confirm delete"
                    detailedText={`Are you sure you want to delete the permissions from group '${groupToDeleteName}'?`}
                    isOpen={this.state.isDeleteModalOpen}
                    onToggle={this.toggleDeleteModal}
                    onConfirm={this.executeDelete} />
                <ModalPermissionGroupAddOrUpdate isOpen={this.state.isAddOrUpdateModalOpen} groups={groupsWithoutConfig} groupToUpdate={this.state.groupToUpdate} availablePermissions={this.state.availablePermissions} onToggle={this.toggleAddOrUpdateModal} onAddOrUpdate={this.executeAddOrUpdate} />
                {groupSettingsRendered}
            </Loader>
        );
    }
}

// Inject the required contexts.
export const PortalAccess = () => {
    const notificationContext = useContext(NotificationContext) as NotificationContextState;

    return (
        <PortalAccessInternal notificationContext={notificationContext} />
    )
}