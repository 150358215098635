import React, { FC } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import css from './InfoIcon.module.scss';

interface InfoIconProps {
  tooltip: string;
  id: string;
}

export const InfoIcon: FC<InfoIconProps> = ({ tooltip, id }) => {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip id={id}>{tooltip}</Tooltip>}>
      <span className={`ml-2 fa fa-info ${css.infoIcon}`}></span>
    </OverlayTrigger>
  );
};