import { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import { PublicLayout } from '../public/PublicLayout'; 

export class TenantUnknown extends Component {
    render() {
        return (
            <PublicLayout>
                <Jumbotron className="text-center">
                    <p><strong>Your organization has not signed up yet for the Attest service.</strong></p>
                    <p>Please contact CyberTwice to get a subscription.</p>
                </Jumbotron>
            </PublicLayout>
        )
    }
}