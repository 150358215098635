import React from 'react';
import { Table, Alert } from 'reactstrap';
import { MobileRecordedUserEntry }  from './MobileRecordedUserEntry'
import { MobileRecordedUser } from 'logic/apiCalls';

import css from './MobileRecordedUserEntries.module.scss';

type MobileRecordedUserEntriesProps = {
    mobileUserInfos: MobileRecordedUser[], 
    handleEdit: (mobileUserToUpdate: MobileRecordedUser) => void,
    handleDelete: (mobileUserToDelete: MobileRecordedUser) => void,
    showDelete: boolean
}

export const MobileRecordedUserEntries = ({ mobileUserInfos, handleEdit, handleDelete, showDelete } : MobileRecordedUserEntriesProps) => {

    if (mobileUserInfos.length === 0) {
        return <Alert color='warning'>
            No tenants are configured
        </Alert>
    }

    return (
        <div className={css.mobileUserInfoTable}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-2">Phone number</th>
                        <th className="col-3">Name</th>
                        <th className="col-4">Azure User Id</th>
                        <th className="col-1">Recorded</th>
                        <th className="col-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        mobileUserInfos.map((value) => {
                            return <MobileRecordedUserEntry key={value.mobilePhoneNumber} mobileRecordedUser={value} handleEdit={handleEdit} handleDelete={handleDelete} showDelete={showDelete}/>
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}