import React from 'react';
import { Table, NavLink, Nav, Spinner } from 'reactstrap';

import { AuditEvent } from './AuditEvent';
import { AuditEventData } from 'logic/apiCalls';

import css from './AuditEvents.module.scss';

type AuditEventsProps = {
    events: AuditEventData[],
    isAppending: boolean,
    showLoadMore: boolean,
    onLoadMore: () => void
}

export const AuditEvents = ({ events, isAppending, showLoadMore, onLoadMore: handleLoadMore } : AuditEventsProps) => {

    if (events.length === 0) {
        return <></>
    }

    return (
        <div className={css.auditTable}>
            <Table className="table-striped table-hover">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-2">Timestamp</th>
                        <th className="col-3">Tenant</th>
                        <th className="col-3">Event</th>
                        <th className="col-4">Username</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        events.map((value) => {
                            return <AuditEvent key={value.metaData.operationId} event={value} />
                        })
                    }
                </tbody>
            </Table>
            {showLoadMore &&
                <div className={css.center}>
                    {
                        isAppending ? 
                            <Spinner/> :
                            <div>
                                <Nav>
                                    <NavLink className={css.center} href="" onClick={e => { e.preventDefault(); handleLoadMore();}}>Load more...</NavLink>
                                </Nav>
                            </div>
                    }
                </div>}
        </div>
    )
}