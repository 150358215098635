import { Component } from 'react';
import { FilterInfo, Filters } from './Filters';
import { AuditEvents } from './AuditEvents';
import { Loader } from 'components/Loader';
import { AuditEventData } from 'logic/apiCalls';

import * as apiCalls from 'logic/apiCalls';

import log from 'loglevel';

const resultCount : number = 15;

type DistributorAuditTrailState = {
    isLoading: boolean,
    isAppending: boolean,
    showApiError: boolean,
    events: AuditEventData[],
    showLoadMore: boolean,
    selectedTenantId: string | null,
    startDate: Date,
}

export class DistributorAuditTrail extends Component<{}, DistributorAuditTrailState> {
    state: DistributorAuditTrailState = {
        isLoading: false,
        isAppending: false,
        showApiError: false,
        showLoadMore: true,
        events: [],
        selectedTenantId: null,
        startDate: new Date(),
    }

    handleApply = (filterInfo: FilterInfo) => {
        this.setState(
            {
                selectedTenantId: filterInfo.tenant.value,
                startDate: filterInfo.startDate
            }
        )

       this.loadAuditTrailEvents(false, filterInfo.tenant.value, filterInfo.startDate);
    }

    handleLoadMore = () => {
        if (!this.state.selectedTenantId) {
            return;
        }

        this.loadAuditTrailEvents(true, this.state.selectedTenantId, this.state.startDate);
    }

    loadAuditTrailEvents = async (appendEntries: boolean, tenantId: string, startDate: Date) => {
        try {
            const showLoading = !appendEntries;
            const events = appendEntries ? this.state.events : [];

            this.setState({                
                isLoading: showLoading,
                isAppending: appendEntries,
                events: events
            });

            const auditTrailEvents = await apiCalls.queryAuditTrail(tenantId, startDate, events.length, resultCount);

            const newEvents = this.state.events.concat(auditTrailEvents);

            this.setState({
                isLoading: false,
                isAppending: false,
                showApiError: false,
                events: newEvents,
                showLoadMore: auditTrailEvents.length === resultCount
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                isAppending: false,
                showApiError: true
            });
        }
    }

    render() {
        return (
            <>
                <h1>Audit Trail</h1>
                <Filters onApply={this.handleApply} />
                <hr/>
                <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                    <AuditEvents events={this.state.events} isAppending={this.state.isAppending} showLoadMore={this.state.showLoadMore} onLoadMore={this.handleLoadMore} />
                </Loader>
            </>
        );
    }
}