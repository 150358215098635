import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form } from 'react-bootstrap';

import { createGroupOption, createGroupOptions } from 'utils/selectHelper';
import Select from 'react-select'

export class ModalRecordedGroupAddOrUpdate extends Component {
    state = {  
        groupOptions: null,
        isUpdating: false,     
        selectedGroupOption: null,
        isCompliant: true
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const groupOptions = createGroupOptions(this.props.groupInfos);   
        const isUpdating = !!this.props.groupInfoToUpdate;
        const selectedGroupOption = isUpdating ? createGroupOption(this.props.groupInfoToUpdate) : null;
        const isCompliant = isUpdating ? this.props.groupInfoToUpdate.compliant : true;

        this.setState(
            {
                groupOptions: groupOptions,
                isUpdating: isUpdating,
                selectedGroupOption: selectedGroupOption,
                isCompliant: isCompliant
            }
        );
    }

    handleSelectedGroupChange = (selectedGroupOption) => {
        this.setState(
            {
                selectedGroupOption: selectedGroupOption
            }
        );  
    }
  
    handleDoneButtonClicked = () => {
        const doneInfo = {
            groupInfo: this.state.selectedGroupOption.value,
            isCompliant: this.state.isCompliant,
            isUpdate: this.state.isUpdating
        }

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.setState({
            selectedGroupOption: null,
            isCompliant: true
        });
        this.props.onToggle();
    }

    handleComplianceRecordingChange = () => {
        this.setState(
            {
                isCompliant: !this.state.isCompliant
            }
        );
    }

    render() {
        const doneButtonText = this.state.isUpdating ? 'Update' : 'Add';

        return <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>Recording group</ModalHeader>
            <ModalBody>
                <Select isDisabled={this.state.isUpdating} searchable value={this.state.selectedGroupOption} options={this.state.groupOptions} onChange={this.handleSelectedGroupChange} placeholder='Select group...' /> 

                <Form className="mt-3">
                    <Form.Group controlId="formBasicCheckbox">
                        { /* By default the custom control was rendered on top of the dropdown. */ }
                        <Form.Check style={{zIndex: 0}} custom checked={this.state.isCompliant} type="checkbox" label="Compliance recording" onChange={this.handleComplianceRecordingChange} />
                    </Form.Group>
                </Form>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" disabled={!this.state.selectedGroupOption} onClick={this.handleDoneButtonClicked}>{doneButtonText}</Button>{' '}
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

