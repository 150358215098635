import { Component, useContext } from 'react';
import { Button } from 'reactstrap';
import { TenantEntries } from './TenantEntries';
import { Loader } from 'components/Loader';
import { ModalConfirm } from 'components/ModalConfirm';
import { DoneInfo, ModalTenantAddOrUpdate} from './ModalTenantAddOrUpdate'
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { Tenant, TranscriptionLanguage } from 'logic/apiCalls';
import * as apiCalls from 'logic/apiCalls';

import log from 'loglevel';

// The Delete button was considered to rigorous, so instead tenants can now be enabled/disabled.
// However the functionality is kept because it might be useful in test environments.
const showTenantDeleteButton = false;

type TenantsPageState = {
    isLoading: boolean,
    showApiError: boolean,
    tenants: Tenant[],
    transcriptionLanguages: TranscriptionLanguage[],
    connectorNames: string[],
    tenantToDelete: Tenant | null,
    tenantToUpdate: Tenant | null,
    isDeleteModalOpen: boolean,
    isAddOrUpdateModalOpen: boolean
}

type TenantsInternalProps = {
    notificationContext: NotificationContextState
}

export class DistributorTenantsInternal extends Component<TenantsInternalProps, TenantsPageState>  {
    state: TenantsPageState ={
        isLoading: true,
        showApiError: false,
        tenants: [],
        transcriptionLanguages: [],
        connectorNames: [],
        tenantToDelete: null,
        tenantToUpdate: null,
        isDeleteModalOpen: false,
        isAddOrUpdateModalOpen: false
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    toggleDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    }

    toggleAddOrUpdateModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: !this.state.isAddOrUpdateModalOpen
        });
    }

    openAddModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: true,
            tenantToUpdate: null
        });
    }

    openUpdateModal = (tenantToUpdate: Tenant) => {
        this.setState({
            isAddOrUpdateModalOpen: true,
            tenantToUpdate: tenantToUpdate
        });
    }

    openDeleteModal = (tenantToDelete: Tenant) => {
        this.setState({
            isDeleteModalOpen: true,
            tenantToDelete: tenantToDelete
        });
    }

    handleEditButtonClicked = (tenantToUpdate: Tenant) => {
        this.openUpdateModal(tenantToUpdate);
    }

    handleDeleteButtonClicked = (tenantToDelete: Tenant) => {
        this.openDeleteModal(tenantToDelete);
    }

    loadPageData = async () => {
        try {
            const tenantsPromise = apiCalls.getTenantsByDistributor();
            const transcriptionLanguagesPromise = apiCalls.getTranscriptionLanguages();
            const connectorNamesPromise = apiCalls.getConnectorNames();

            const [tenants, transcriptionLanguages, connectorNames] = await Promise.all([tenantsPromise, transcriptionLanguagesPromise, connectorNamesPromise]);

            this.setState({
                tenants: tenants,
                transcriptionLanguages: transcriptionLanguages,
                connectorNames: connectorNames,
                isLoading: false
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);
        }
    }
  
    executeAddOrUpdate = async (doneInfo: DoneInfo) => {
        log.debug(`[executeAddOrUpdate] Adding/updating tenant ${doneInfo.tenantName} (${doneInfo.tenantId})...`);

        try {
            if (doneInfo.isUpdate) {
                await apiCalls.updateTenantByDistributor(doneInfo.tenantId, doneInfo.tenantName, doneInfo.tenantReference, doneInfo.defaultRetentionSeconds, doneInfo.isTranscriptionEnabled, doneInfo.transcriptionLanguage, doneInfo.connectors, doneInfo.isEnabled);
            }
            else
            {
                await apiCalls.addTenantByDistributor(doneInfo.tenantId, doneInfo.tenantName, doneInfo.tenantReference, doneInfo.defaultRetentionSeconds, doneInfo.isTranscriptionEnabled, doneInfo.transcriptionLanguage, doneInfo.connectors);
            }
            
            await this.loadPageData();

            const notificationText = doneInfo.isUpdate ? `Successfully updated tenant info.` : `Successfully added tenant.`;
            this.props.notificationContext.setNotification("Tenant configuration modified", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the tenant configuration.`, 'danger');
        }
        finally {
            this.toggleAddOrUpdateModal();
        }
    }

    executeDelete = async () => {
        try {
            const tenantToDelete = this.state.tenantToDelete;

            if (tenantToDelete == null) {
                return
            }
    
            const tenantName = tenantToDelete.label;
            const tenantId = tenantToDelete.id;
    
            log.debug(`[executeDelete] Deleting tenant ${tenantName} (${tenantId})...`);

            await apiCalls.deleteTenantByDistributor(tenantToDelete.id);
            await this.loadPageData();

            const notificationText = `Successfully deleted tenant info.`;
            this.props.notificationContext.setNotification("Tenant deleted", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during deleting of the tenant configuration.`, 'danger');
        }
        finally {
            this.toggleDeleteModal();
        }
    }

    renderTenants = () => {
        return <div className="my-3">
            <TenantEntries tenants={this.state.tenants} showDeleteButton={showTenantDeleteButton} handleEdit={this.handleEditButtonClicked} handleDelete={this.handleDeleteButtonClicked} />
            <Button className='mt-3' color='primary' onClick={this.openAddModal}>Add tenant</Button>
        </div>
    }

    renderDeleteModal = () => {
        const tenantToDeleteName = this.state.tenantToDelete?.label || '';
        const tenantToDeleteId = this.state.tenantToDelete?.id || '';

        return <ModalConfirm
            headerText="Confirm tenant delete"
            detailedText={`Are you sure you want to delete the tenant '${tenantToDeleteName}' with Id '${tenantToDeleteId}'? `}
            isOpen={this.state.isDeleteModalOpen}
            onToggle={this.toggleDeleteModal}
            onConfirm={this.executeDelete} /> 
    }

    render() {
        return (
            <>
                <h1>Tenants configuration</h1>
                <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                    {this.renderDeleteModal()}
                    <ModalTenantAddOrUpdate isOpen={this.state.isAddOrUpdateModalOpen} tenantToUpdate={this.state.tenantToUpdate} transcriptionLanguages={this.state.transcriptionLanguages} connectorNames={this.state.connectorNames} onToggle={this.toggleAddOrUpdateModal} onAddOrUpdate={this.executeAddOrUpdate}/>
                    {this.renderTenants()}
                </Loader>
            </>
        );
    }
}

// Inject the required contexts.
export const DistributorTenants = (props: any) => {
    const notificationContext = useContext(NotificationContext);

    return (
        <DistributorTenantsInternal {...props} notificationContext={notificationContext} />
    )
}