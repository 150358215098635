import { Component } from 'react';
import { Tenant } from 'logic/apiCalls';
import Select, { SingleValue }  from 'react-select'

export type SelectEntry = {
    value: string,
    label: string
}

export type TenantEntry = SelectEntry;

export type OnTenantSelectionChangedCallback = (tenantEntry: TenantEntry | null) => void;

type TenantSelectProps = {
    className: string | undefined,
    isClearable: boolean,
    placeholder: string,
    tenants: Tenant[],
    onTenantSelectionChanged: OnTenantSelectionChangedCallback
}

type TenantSelectState = {
    tenantEntries: TenantEntry[],
    selectedTenantEntry: TenantEntry | null,
}

export class TenantSelect extends Component<TenantSelectProps, TenantSelectState> {
    state: TenantSelectState = {
        tenantEntries: [],
        selectedTenantEntry: null
    }

    componentDidMount(): void {
        this.updateTenantEntriesInState();
    }

    componentDidUpdate(prevProps: Readonly<TenantSelectProps>): void {
        if (prevProps.tenants !== this.props.tenants) {
            this.updateTenantEntriesInState();
        }
    }

    updateTenantEntriesInState = () => {
        this.setState({
            tenantEntries: this.convertToTenantEntries(this.props.tenants)
        });
    }

    convertToTenantEntries = (tenants: Tenant[]) : TenantEntry[] => {
        const tenantEntries: TenantEntry[] = [];        
        
        tenants.forEach(tenant => {
            const tenantEntry = {
                value:tenant.id, 
                label:tenant.label
            };
    
            tenantEntries.push(tenantEntry);
        });
    
        return tenantEntries;
    }

    handleSelectedTenantEntryChange = (selectedTenantEntry: SingleValue<TenantEntry>) => {
        this.setState({ selectedTenantEntry: selectedTenantEntry as TenantEntry });

        this.props.onTenantSelectionChanged(selectedTenantEntry as TenantEntry);
    }
    
    render() {
        return (
            <>
                <Select
                    className={this.props.className}
                    isSearchable={true}
                    isClearable={this.props.isClearable}
                    value={this.state.selectedTenantEntry}
                    options={this.state.tenantEntries}
                    onChange={this.handleSelectedTenantEntryChange}
                    placeholder={this.props.placeholder}
                />
            </>
        );
    }
}

