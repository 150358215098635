import qs from 'qs';

// Method attempts to get the MarketPlace token from the URL. 
// In case the token is not found, the value null is returned.
function tryGetMarketPlaceTokenFromUrl(): string | null {
  const queryParameters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if (!queryParameters.token) {
    return null;
  }

  return decodeURIComponent(queryParameters.token as string);
}

// Method gets the MarketPlace token from the URL. An error is thrown when the token is not found.
function getMarketPlaceTokenFromUrl(): string {
  const token = tryGetMarketPlaceTokenFromUrl();

  if (!token) {
    throw new Error("No MarketPlace token found in URL.");
  }

  return token;
}

export const marketPlaceHelper = {
  tryGetMarketPlaceTokenFromUrl,
  getMarketPlaceTokenFromUrl,
};