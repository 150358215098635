const dutchMobilePhoneRegex = new RegExp("^\\+31(6|970)[0-9]{8}$");

export function isNumeric(input: any) {
    const regex = /^[0-9]+$/

    return regex.test(input);   
}

export const isValidGuid = (input: string) : boolean => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

    return regex.test(input);
}

export const isValidMobileUserName = (input: string) : boolean => {
    const mobileUserNameRegex = new RegExp("^[0-9a-zA-zÀ-ž -~]+$");

    return mobileUserNameRegex.test(input);
}

export const isPartialDutchMobilePhoneNumber = (input: string) => {
    if (!input || input.length === 0) {
        return true; // Allow empty string during editing.
    }

    const dutchMobilePhoneNumber = "+31600000000";
    const testMobilePhoneNumber  = "+3197000000000";

    return isPartialMobileNumberMatch(input, dutchMobilePhoneNumber) || 
            isPartialMobileNumberMatch(input, testMobilePhoneNumber);
}

export const isDutchMobilePhoneNumber = (input: string) : boolean => {
    return dutchMobilePhoneRegex.test(input);
}

export const isPartialFingerprint = (input: string) : boolean => {
    const fingerprintRegex = new RegExp("^[0-9a-fA-F]{0,40}$");

    return fingerprintRegex.test(input);
}

export const isFingerprint = (input: string) : boolean => {
    const fingerprintRegex = new RegExp("^[0-9a-fA-F]{40}$");

    return fingerprintRegex.test(input);
}

const isPartialMobileNumberMatch = (input: string, validPhoneNumber: string) => {
    if (input.length > validPhoneNumber.length) {
        return false; // Check if the input is longer than a valid phone number.
    }

    const tempNumber = input + validPhoneNumber.substring(input.length);

    return dutchMobilePhoneRegex.test(tempNumber);
}