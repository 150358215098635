import { FC } from 'react';
import { Button, Spinner } from 'reactstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface DoneButtonProps {
    id: string,
    buttonText: string,
    disabled?: boolean,
    disabledTooltip?: string,
    showBusy: boolean,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
};

export const DoneButton: FC<DoneButtonProps> = ({ id, buttonText, disabled, disabledTooltip, showBusy, onClick }) => {

    const button = <Button color="primary" disabled={disabled || showBusy} onClick={onClick}>
            {showBusy && <Spinner className='mr-2' style={{marginBottom:"2px"}} size='sm'/> } 
            <span>{buttonText}</span>
        </Button>

    if (disabled && disabledTooltip) {
        return (
            <OverlayTrigger placement='right' overlay={<Tooltip id={id}>{disabledTooltip}</Tooltip>}>
                {button}
            </OverlayTrigger>
        );
    }

    return button;
  };