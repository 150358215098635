import { Component, useContext } from 'react';
import { Button, CustomInput, Form, FormGroup } from 'reactstrap';
import { Loader } from 'components/Loader';
import { ModalConfirm } from 'components/ModalConfirm';
import { oneWayCompare } from 'utils/misc';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { GlobalReplayConfiguration } from 'logic/apiCalls';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';


type TabReplayGlobalInternalProps = {
    notificationContext: NotificationContextState
}

type TabReplayGlobalInternalState = {
    isLoading: boolean,
    showApiError: boolean,
    globalAdministratorReplayEnabled: boolean,
    stateInBackend: any,
    isConfirmSaveModalOpen: boolean,
}

type GlobalReplayConfigurationInfo = {
    globalAdministratorReplayEnabled: boolean
}

class TabReplayGlobalInternal extends Component<TabReplayGlobalInternalProps, TabReplayGlobalInternalState> {
    state: TabReplayGlobalInternalState = {
            isLoading: true,
            showApiError: false,
            globalAdministratorReplayEnabled: false,
            stateInBackend: {},
            isConfirmSaveModalOpen: false
    }

    async componentDidMount() {
        await this.loadPageData();
    }    

    loadPageData = async () : Promise<void> => {
        try {
            const globalReplayConfigurationPromise = apiCalls.getGlobalReplayConfiguration();

            const [globalReplayConfiguration] = await Promise.all([globalReplayConfigurationPromise]);

            this.setState({
                isLoading: false,
                showApiError: false,
                ...this.extractStateFromData(globalReplayConfiguration),
                stateInBackend: this.extractStateFromData(globalReplayConfiguration)
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    /** Method returns a flat object from the hierarchical data received from the back-end. */
    extractStateFromData = (globalReplayConfiguration: GlobalReplayConfiguration) : GlobalReplayConfigurationInfo => {
        return {
            globalAdministratorReplayEnabled: globalReplayConfiguration.globalAdministratorReplayEnabled
        }
    }
    
    /** Method returns the hierarchical data to be sent to the back-end based upon the current flat state. */
    constructDataFromState = () : GlobalReplayConfiguration => {
        return {           
            globalAdministratorReplayEnabled: this.state.globalAdministratorReplayEnabled
        }
    }

    toggleConfirmSaveModal = () => {
        this.setState({
            isConfirmSaveModalOpen: !this.state.isConfirmSaveModalOpen
        });
    }

    toggleGlobalAdministratorReplay = () => {
        this.setState(
            {
                globalAdministratorReplayEnabled: !this.state.globalAdministratorReplayEnabled
            }
        );
    }

    isSaveButtonEnabled = () => {
        return !oneWayCompare(this.state.stateInBackend, this.state);
    }

    handleSaveButtonClick = () => {
        this.toggleConfirmSaveModal();
    }

    executeSave = async () => {
        log.debug(`[executeSave] Saving the global replay settings...`);

        try {
            const globalReplayConfiguration = this.constructDataFromState();
            await apiCalls.updateGlobalReplayConfiguration(globalReplayConfiguration);
            await this.loadPageData();

            this.props.notificationContext.setNotification("Replay configuration saved", `Successfully updated the global replay configuration.`, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the global replay configuration.`, 'danger');
        }
        finally {
            this.toggleConfirmSaveModal();            
        }
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <div className="m-3">
                    <ModalConfirm
                        headerText="Confirm save"
                        detailedText="Are you sure you want to update the tenant wide replay configuration?"
                        isOpen={this.state.isConfirmSaveModalOpen}
                        onToggle={this.toggleConfirmSaveModal}
                        onConfirm={this.executeSave} />

                    <div className="mt-3 mb-5">
                        <Form>
                            <FormGroup>
                                <div className="mt-5">
                                    <CustomInput checked={this.state.globalAdministratorReplayEnabled} type="switch" onChange={this.toggleGlobalAdministratorReplay} id="globalAdministratorReplaySwitch" label="Replay access for global administrators users" />
                                </div>
                            </FormGroup>
                        </Form>
                    </div>
                    <Button disabled={!this.isSaveButtonEnabled()} color='primary' onClick={this.handleSaveButtonClick}>Save</Button>
                </div>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabReplayGlobal = () => {
    const notificationContext = useContext(NotificationContext) as NotificationContextState;

    return (
        <TabReplayGlobalInternal notificationContext={notificationContext}/>
    )
}