import { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import { PublicLayout } from '../public/PublicLayout'; 

export class TenantDisabled extends Component {
    render() {
        return (
            <PublicLayout>
                <Jumbotron className="text-center">
                    <p><strong>Your tenant has been disabled.</strong></p>
                    <p>Please contact CyberTwice for more information.</p>
                </Jumbotron>
            </PublicLayout>
        )
    }
}