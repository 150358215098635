import { Component, useContext } from 'react';
import { Loader } from 'components/Loader';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { MobileRecorder } from 'logic/apiCalls';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';
import { MobileRecorderEntries } from './MobileRecorderEntries';
import { ModalConfirm } from 'components/ModalConfirm';

type TabMobileSettingsRecordersInternalProps = {
    notificationContext: NotificationContextState
}

type TabMobileSettingsRecordersInternalState = {
    isLoading: boolean,
    showApiError: boolean,
    mobileRecorders: MobileRecorder[],
    mobileRecorderToChange: MobileRecorder | null,
    isConfirmModalOpen: boolean
}

class TabMobileSettingsRecordersInternal extends Component<TabMobileSettingsRecordersInternalProps, TabMobileSettingsRecordersInternalState> {
    refreshTimerHandle: NodeJS.Timeout | undefined;

    state: TabMobileSettingsRecordersInternalState = {
        isLoading: true,
        showApiError: false,
        mobileRecorders: [],
        mobileRecorderToChange: null,
        isConfirmModalOpen: false
    }   

    async componentDidMount() {
        await this.loadPageData();

        this.startRefreshTimer();
    }

    async componentWillUnmount() {
        this.stopRefreshTimer();
    }

    startRefreshTimer = () => {
        this.refreshTimerHandle = setInterval(() => this.loadPageData(), 1000 * 10);
    }

    stopRefreshTimer = () => {
        if (this.refreshTimerHandle) {
            clearInterval(this.refreshTimerHandle);
        }
    }

    toggleConfirmModal = () => {
        this.setState(
            {
                isConfirmModalOpen: !this.state.isConfirmModalOpen
            }
        );
    }

    loadPageData = async () => {
        try {
            const mobileRecorders = await apiCalls.getMobileRecorders();
            
            this.setState({
                mobileRecorders: mobileRecorders,
                isLoading: false,
            });            
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    handleOperationalStatusChange = (mobileRecorderToChange: MobileRecorder) => {
        this.setState(
            {
                mobileRecorderToChange: mobileRecorderToChange
            }
        );

        this.toggleConfirmModal();
    }

    executeChange = async () => {
        try {     
            const mobileRecorder = this.state.mobileRecorderToChange;
            if (!mobileRecorder) {
                return;
            }

            const newRetiredState = !mobileRecorder.retired;
    
            log.debug(`[executeChange] Changing retired state of mobile recorder '${mobileRecorder.id}' to ${newRetiredState}`);

            await apiCalls.changeMobileRecorderOperationalState(mobileRecorder.id, newRetiredState);
            await this.loadPageData();

            const notificationText = newRetiredState ? 
                `Successfully retired mobile recorder.` :
                `Successfully enabled mobile recorder.`;
            this.props.notificationContext.setNotification(notificationText, notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during changing of the operational state.`, 'danger');
        }
        finally {
            this.toggleConfirmModal();
        }
    }

    renderToggleModal = () => {
        const mobileRecorderToChange = this.state.mobileRecorderToChange;

        if (!mobileRecorderToChange) {
            return null;
        }

        const retiring = !mobileRecorderToChange.retired;
        const headerText = retiring ? "Confirm retire" : "Confirm enable";
        const detailedText = retiring ?
            `Are you sure you want to retire the mobile recorder '${mobileRecorderToChange.id}'?` :
            `Are you sure you want to enable the mobile recorder '${mobileRecorderToChange.id}'?`;
        
        return <ModalConfirm
            headerText={headerText}
            detailedText={detailedText}
            isOpen={this.state.isConfirmModalOpen}
            onToggle={this.toggleConfirmModal}
            onConfirm={this.executeChange} /> 
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <>
                    {this.renderToggleModal()}         
                    <MobileRecorderEntries mobileRecorders={this.state.mobileRecorders} handleOperationalStatusChange={this.handleOperationalStatusChange} />
                </>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabMobileSettingsRecorders = (props: any) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabMobileSettingsRecordersInternal {...props} notificationContext={notificationContext} />
    )
}