import React from 'react';
import { DeleteButton } from 'components/DeleteButton';
import { EditButton } from 'components/EditButton';
import { MobileRecordedUser } from 'logic/apiCalls';

import css from './MobileRecordedUserEntry.module.scss';

type MobileRecordedUserEntryProps = {
    mobileRecordedUser: MobileRecordedUser, 
    handleEdit: (tenantToUpdate: MobileRecordedUser) => void,
    handleDelete: (tenantToDelete: MobileRecordedUser) => void,
    showDelete: boolean
}

export const MobileRecordedUserEntry = ({ mobileRecordedUser, handleEdit, handleDelete, showDelete } : MobileRecordedUserEntryProps) => {
    const azureUserIdRendered = mobileRecordedUser.azureAdUserId ? 
        <>{mobileRecordedUser.azureAdUserId}</> : 
        <i>Not set</i>

    const recordedText = mobileRecordedUser.recorded ?
        "Yes" : 
        "No"

    return (
        <tr key={mobileRecordedUser.mobilePhoneNumber} className={`d-flex ${!mobileRecordedUser.recorded && css.disabledPhoneNumber}`}>
            <td className="col-2">{mobileRecordedUser.mobilePhoneNumber}</td>
            <td className="col-3">{mobileRecordedUser.name}</td>
            <td className="col-4">{azureUserIdRendered}</td>
            <td className="col-1">{recordedText}</td>
            <td className="col-2">
                { showDelete && <DeleteButton className='float-right mr-3' onClick={() => handleDelete(mobileRecordedUser)} /> }
                <EditButton className='float-right mr-3' onClick={() => handleEdit(mobileRecordedUser)}/>
            </td>
        </tr>
    )
}