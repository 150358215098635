import { Component, useContext } from 'react';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { Alert, Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { adminConsent } from 'logic/adminConsent';

import css from '../TabConsent.module.scss';

const adminConsentType = "Attest";

type TabConsentAttestInternalProps = {
    tenantContext: TenantContextState
}

class TabConsentAttestInternal extends Component<TabConsentAttestInternalProps, {}> {
    redirect = () => {
        var appId = this.props.tenantContext.getAdminConsentAppId(adminConsentType);
        adminConsent.redirect(adminConsentType, appId);
    }
    
    renderContent = () => {
        let contentRendered;
        if (!this.props.tenantContext.isAdminConsentGranted(adminConsentType)) {
            // Only show the Admin Consent button when the admin consent has not been granted yet.
            contentRendered = 
                <>
                    <Alert color="warning">
                        The Attest services needs to be granted a limited set of access rights to your Azure environment to function correctly.
                        These rights have to be provided by an administrator of your organization.
                    </Alert>
                    <p>By clicking the link below, you will be redirected to Microsoft to grant these access rights.</p>
                    <Button color="primary" className="mb-3" onClick={this.redirect}>Provide admin consent</Button>
                </>
        } else {
            contentRendered = 
                <Alert color="success">
                    Admin consent has been provided for the Attest base service.
                </Alert>
        }

        return <div className={css.tabConsent}>
            <h2 className="my-3">Attest base access</h2>
            {contentRendered}  
        </div>
    }
  
    render() {
        return <Loader isLoading={!this.props.tenantContext.isTenantInfoRetrieved} showApiError={false} render={this.renderContent} />
    }
}

// Inject the required contexts.
export const TabConsentAttest = (props: any) => {
    const tenantContext = useContext(TenantContext);

    return (
        <TabConsentAttestInternal {...props} tenantContext={tenantContext}/>
    )
}