import { appConfig } from './appConfigProvider';
import { PublicClientApplication /*InteractionRequiredAuthError, BrowserAuthError */ } from '@azure/msal-browser';
import { TokenRetrievalError } from 'utils/errors';
import * as misc from 'utils/misc';
import { urls, urlCombine, getBaseUriWithoutParameters } from 'logic/urls';
import log from 'loglevel';

// Enum defines the possible states of the authenticationState
export const authenticationStates = {
    NOT_AUTHENTICATED: 'NotAuthenticated', // No user is currently logged-in
    LOGIN_IN_PROGRESS: 'LoginInProgress', // The login at Microsoft has started
    AUTHENTICATING: 'Authenticating', // The redirect back from Microsoft has taken place, but the process of exchanging the authentication code for the tokens is still in progress.
    AUTHENTICATED: 'Authenticated', // The user is successfully logged in. The first time this state is reached, the check for the authorization will be started.    
    AUTHENTICATED_CONTEXT_USER_MISMATCH: 'AuthenticatedContextUserMismatch', // The user is successfully logged in. But the logged in user is different than the supplied context user. 
    AUTHENTICATION_ERROR: 'AuthenticationError', // The user did an attempt to log-in, but some failure has occured.
}

const keys = {
    lastAuthenticationErrorMessage: 'lastErorrMessageKey',
    contextUser: 'contextUserKey',
    postRedirectUrl: 'postRedirectUrlKey'
}

let loginInProgress = false;

const msalConfig = {
    auth: {
        authority: appConfig.authority,
        clientId: appConfig.clientId,
        navigateToLoginRequestUrl: appConfig.navigateToLoginRequestUrl,
        redirectUri: urlCombine(getBaseUriWithoutParameters(), urls.postLogin)
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level,
                message,
                containsPii
            ) => {
                if (containsPii) {
                    return;
                }
                
                console.info(message);
            },
            piiLoggingEnabled: false,
        }
    }
}

// After startup of the application it is not immediately known if there is a logged-in user or not, because the PublicClientApplication
// might still be busy with requesting an access token based upon the received authorization code. Only when the handleRedirectPromise
// has been invoked, the logged-in accounts can be queried reliably. 
// There seems to be no way in the MSAL library to query if this work is still in progresss or not. This variable is a work-around 
// to have this state available.
let isInProgress = true;

const msalAuth = new PublicClientApplication(msalConfig);
msalAuth.initialize().then(() => {
    // Register for the callbacks from the MSAL library.
    // The success callback ('then' part) is invoked immediately after registration when the user is either not authorized or already authorized. 
    // In that case no token is provided as parameter.
    // When the application is starting up because of a redirect back from Azure, the MSAL library will start to resolve the OAuth authorization code for
    // an id_token and an access_token. When this process is finished the 'then' part is invoked and the tokenResponse parameter will contain 
    // all details from the response from Azure AD.
    msalAuth.handleRedirectPromise().then(async (tokenResponse) => {
        isInProgress = false;
        if (tokenResponse === null) {
            log.info("[handleRedirectPromise] No change in logged-in state of user.");

            // Report that the state is no longer 'authenticating', but either 'authenticated' or 'not_authenticated'.
            scheduleReportUpdatedAuthenticationState();
            return;     
        }

        let account = tokenResponse.account;
        log.info(`[handleRedirectPromise] User '${account.username}' successfully logged in.`);
        
        msalAuth.setActiveAccount(account);

        scheduleReportUpdatedAuthenticationState();

        return;
    }).catch((error) => {
        // Error occurred during log-in...
        isInProgress = false;
        log.error(`[handleRedirectPromise] ${error.message}`);
        
        msalAuth.setActiveAccount(null);

        persistLastAuthenticationErrorMessage("Error occurred during authentication at Microsoft.");
        scheduleReportUpdatedAuthenticationState();
    });
});

const loginScopes = [".default"];

// The collection of callbacks registered which are invoked upon every change of the authentication state.
let authenticationStateChangeCallbacks = [];

// The last reported state is used in the mechanism to prevent the same state is reported more than once.
let lastReportedState = "";

function clearPersistedLastAuthenticationErrorMessage() {
    sessionStorage.removeItem(keys.lastAuthenticationErrorMessage);
}

function persistLastAuthenticationErrorMessage(errorMessage) {
    sessionStorage.setItem(keys.lastAuthenticationErrorMessage, errorMessage);
}

function retrieveLastAuthenticationErrorMessage() {
    return sessionStorage.getItem(keys.lastAuthenticationErrorMessage);
}

// Method invokes all the registered callbacks to notify the current authentication state out of the current loop.
function scheduleReportUpdatedAuthenticationState() {
    setTimeout(reportUpdatedAuthenticationState, 1);
}

// Method invokes all the registered callbacks to notify the current authentication state.
function reportUpdatedAuthenticationState() {
    const currentAuthenticationState = getAuthenticationState();

    // Prevent a state is reported more than once.
    if (currentAuthenticationState === lastReportedState) {
        return;
    }

    log.trace(`[authentication, reportUpdatedAuthenticationState] currentAuthenticationState: ${currentAuthenticationState}, lastReportedState: ${lastReportedState}`);
    lastReportedState = currentAuthenticationState;

    // Invoke all the registered callbacks to inform the client-code about the state change.    
    authenticationStateChangeCallbacks.forEach(callback => {
        callback(currentAuthenticationState);
    });
}

// Method retrieves the user account of the user that has logged.
// The MSAL library supports more than 1 user to be logged in. In this situation the account of the user that has logged in last will be returned.
// In case it cannot be determined which user has logged in last, the first account is returned.
//
// The user account that is returned is the object from the MSAL library and contains the following members:
//  {
//     "homeAccountId": "16832178-9755-4dff-a975-88d471a185ce.0c1fb16c-0190-47e0-b839-5ec8665eb699",
//     "environment": "login.windows.net",
//     "tenantId": "0c1fb16c-0190-47e0-b839-5ec8665eb699",
//     "username": "ronald.vanleeuwen@ip-lease.com",
//     "localAccountId": "16832178-9755-4dff-a975-88d471a185ce",
//     "name": "ronald van leeuwen"
//  }
function getUserAccount() {
    return msalAuth.getActiveAccount();
}

// Method returns all user accounts that have logged in to this application.
function getUserAccounts() {
    return msalAuth.getAllAccounts();
}

/// Method returns the current authentication state.
function getAuthenticationState() {
    if (loginInProgress) {
        return authenticationStates.LOGIN_IN_PROGRESS;
    }

    if (retrieveLastAuthenticationErrorMessage()) {
        return authenticationStates.AUTHENTICATION_ERROR;
    }
    
    // The check for the 'authenticating' state must be done before the 'authenticated' state to
    // handle the situation of switching accounts correctly. In that case the getUserAccount method
    // returns the previous user although the login of the new user is still in progress.     
    if (isInProgress) {
        return authenticationStates.AUTHENTICATING;
    }

    let userAccount = getUserAccount();
    if (userAccount) {
        const contextUser = sessionStorage.getItem(keys.contextUser);

        // When the application is running in the Teams environment the name of the context user (=user logged into Teams)
        // was stored when the login was started. Although this name is provided as a login-hint, the user can still select
        // a different account to log-in. This situation is undesirable and is blocked for this reason.
        if (contextUser && contextUser !== 'undefined' && contextUser !== userAccount.username) {
            return authenticationStates.AUTHENTICATED_CONTEXT_USER_MISMATCH;
        }

        return authenticationStates.AUTHENTICATED; 
    }
    
    return authenticationStates.NOT_AUTHENTICATED;
}

// Method return the last authentication error object.
function getLastAuthenticationErrorMessage() {
    return retrieveLastAuthenticationErrorMessage();
}

// Method sets the last authentication error object and triggers the invocation of the callbacks.
// This method is used when an authentication error occurred outside of this module. For example
// when the Teams authentication pop-up is closed.
function setLastAuthenticationErrorMessage(errorMessage) {
    // Prevent an error can be cleared using this method.
    if (!errorMessage) {
        return;
    }

    persistLastAuthenticationErrorMessage(errorMessage);
    scheduleReportUpdatedAuthenticationState();
}

// Method clears the last occurred authentication error object and triggers the invocation of the callbacks.
// This method is used when an authentication was successful outside of this module. This situation happens 
// when the previous Teams login failed and the last one was successful. The last erorr is cleared before the
// login starts, but because this Teams authentication happens in a separate session, the error in this
// session is not cleared automatically.  
function clearLastAuthenticationErrorMessage() {
    clearPersistedLastAuthenticationErrorMessage();
    scheduleReportUpdatedAuthenticationState();
}

// Method registers the provided callback which is invoked upon every change of the authentication state.
// The signatue of the method is: callback(state)
// The state parameter is a value from the 'authenticationStates' enum.
function registerAuthenticationStateChangeCallback(callback) {
    if (!callback || !misc.isFunction(callback)) {
        return;
    }

    log.info(`[authentication, registerAuthenticationStateChangeCallback] Adding callback.`);

    // Add the provided callback to the collection of callbacks.
    authenticationStateChangeCallbacks.push(callback);
    // Immediately invoke the callback so that the client code is in-sync with the state of this module.
    const currentState = getAuthenticationState();
    
    callback(currentState);    
}

// Method unregisters the provided callback. 
// This callback has to match the callback that was provided to the 'registerAuthenticationStateChangeCallback' method.
function unregisterAuthenticationStateChangeCallback(callback) {
    authenticationStateChangeCallbacks = authenticationStateChangeCallbacks.filter((item) => item !== callback);
}

// Method starts a login using the OAuth 2.0 explicit flow with PKCE extension using a redirect to Azure Active Directory. 
// The context user name is used as a login-hint, which means that the step for selecting/entering the account to use
// is skipped. In case the user has already an active logged-in session at Azure the login is finished without user interaction,
// otherwise the password window is shown. It is however still possible (in case there was no active logged-in session) to login 
// with a different account than the login-hint.
// When no context user name is known (because the application is not running in Teams), Azure is requested (using the prompt property)
// to always show the account selection dialog. Without this setting, it is impossible to switch to another account in case there is 
// already 1 logged-in account (Azure immediately redirects back to the application).
// Because of the redirect to Azure the React application is ended and will have a fresh restart when the redirect URL is invoked.
// The postRedirectUrl is the URL to which a client-side redirect is performed when the React App has started and opened the redirectUrl.
function login(contextUsername, postRedirectUrl) {
    loginInProgress = true;
    clearPersistedLastAuthenticationErrorMessage();

    const loginRequest = {
        scopes: loginScopes
    }

    if (contextUsername) {
        sessionStorage.setItem(keys.contextUser, contextUsername);
        loginRequest.loginHint = contextUsername;
    } else {
        sessionStorage.removeItem(keys.contextUser);
        loginRequest.prompt = 'select_account';
    }

    if (postRedirectUrl) {
        sessionStorage.setItem(keys.postRedirectUrl, postRedirectUrl);
    } else {
        sessionStorage.removeItem(keys.postRedirectUrl);
    }
        
    msalAuth.loginRedirect(loginRequest);
}

// Method starts a logout using the redirect flow. The current logged-in account is provided in the logout request to prevent
// the account selection is shown.
// The MSAL cache is cleared immediately and after successful logout the session will also be cleared on Azure AD.
function logout(redirectUrl) {
    const request = {
        account: getUserAccount(),
        postLogoutRedirectUri: redirectUrl
    }

    msalAuth.logoutRedirect(request);
}

async function getApiTokenAsync() {
    const accessTokenRequest = {
        scopes: appConfig.apiScopes,
        account: getUserAccount()
    }

    try {
        log.trace("[AuthenticationProvider, getApiTokenAsync] getApiTokenAsync invoked..."); 
 
        const accessTokenResponse = await msalAuth.acquireTokenSilent(accessTokenRequest);               

        log.trace("[AuthenticationProvider, getApiTokenAsync] Accesstoken for back-end API successfully retrieved!");        

        return accessTokenResponse;
    }
    catch (err) {
        log.error(`[AuthenticationProvider, getApiTokenAsync] Error occurred while retrieving token for back-end API: ${err}`);

        // After setting this error the registered callbacks are automatically invoked with the new error state.
        // The ProtectedRoute component automatically redirect the user back to the home page will as a result.
        setLastAuthenticationErrorMessage("Re-authentication required");
        
        throw new TokenRetrievalError(err, "Error occurred while retrieving token for back-end API");                   
    }
}

async function getGraphTokenAsync() {
    const accessTokenRequest = {
        scopes: ['User.Read'],
        account: getUserAccount()
    }

    try {
        log.trace("[AuthenticationProvider, getGraphTokenAsync] getGraphTokenAsync invoked..."); 
 
        const accessTokenResponse = await msalAuth.acquireTokenSilent(accessTokenRequest);               

        log.trace("[AuthenticationProvider, getGraphTokenAsync] Accesstoken for back-end API successfully retrieved!");        

        return accessTokenResponse;
    }
    catch (err) {
        log.error(`[AuthenticationProvider, getGraphTokenAsync] Error occurred while retrieving token for back-end API: ${err}`);

        // After setting this error the registered callbacks are automatically invoked with the new error state.
        // The ProtectedRoute component automatically redirect the user back to the home page will as a result.
        setLastAuthenticationErrorMessage("Re-authentication required");
        
        throw new TokenRetrievalError(err, "Error occurred while retrieving token for back-end API");                   
    }
}

function refreshAuthenticationState() {
    scheduleReportUpdatedAuthenticationState();
}

// Convenience method to easily determine if the current user is authenticated.
function isAuthenticated() {
    return getAuthenticationState() === authenticationStates.AUTHENTICATED;
}

// Convience method to easily determine if the authorization is in progress.
function isAuthenticationInProgress() {
    return getAuthenticationState() === authenticationStates.AUTHENTICATING;
}

export const authentication = {
    login: login,
    logout: logout,
    getUserAccount: getUserAccount,
    getUserAccounts: getUserAccounts,
    getAuthenticationState: getAuthenticationState,    
    getLastAuthenticationErrorMessage: getLastAuthenticationErrorMessage,    
    setLastAuthenticationErrorMessage: setLastAuthenticationErrorMessage,
    clearLastAuthenticationErrorMessage: clearLastAuthenticationErrorMessage,
    getApiTokenAsync: getApiTokenAsync,
    getGraphTokenAsync: getGraphTokenAsync,
    isAuthenticated: isAuthenticated,
    isAuthenticationInProgress: isAuthenticationInProgress,
    refreshAuthenticationState: refreshAuthenticationState,    
    registerAuthenticationStateChangeCallback: registerAuthenticationStateChangeCallback,
    unregisterAuthenticationStateChangeCallback: unregisterAuthenticationStateChangeCallback
}