import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

interface SpinnerWithDescriptionProps {
  description: string;
}

export const SpinnerWithDescription: FC<SpinnerWithDescriptionProps> = ({ description }) => {
    return (
        <>
            <div className="mt-3 d-flex justify-content-center">
                <Spinner animation="border" role="status" />
            </div>
            <div className="mt-3 d-flex justify-content-center">
                {description}
            </div>
        </>
    );
};