export function isFunction(functionToCheck: any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function isTrue(value: any) {
    if (value === undefined) {
        return false;
    }

    if (typeof (value) === "boolean") {
        return value;
    }

    if (typeof (value) !== "string") {
        return false;
    }

    const lowerCaseValue = value.toLowerCase();
    return lowerCaseValue === 'true' || lowerCaseValue === 'yes';
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function trimRight(input: string, charlist: string = '\\s') {
    return input.replace(new RegExp("[" + charlist + "]+$"), "");
};

export function trimLeft(input: string, charlist: string = '\\s') {
    return input.replace(new RegExp("^[" + charlist + "]+"), "");
};

export function isRunningInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }    
}

export function isEmpty(value: any) {
    return value === undefined || value === null;
}

export function b64toBlob(b64Data: string, contentType: string = '', sliceSize : number = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export function isDebugEnabled() {
    return window.sessionStorage['debug'] === 'true';
}

export function toggleDebug() {
    const newToggledValue = isDebugEnabled() ? 'false' : 'true';

    window.sessionStorage['debug'] = newToggledValue;
}

function range(size: number, startAt: number = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

function characterRange(startChar: string, endChar: string) {
    return String.fromCharCode(...range(endChar.charCodeAt(0) -
            startChar.charCodeAt(0), startChar.charCodeAt(0)));
}

const randomCharacters = characterRange('a', 'z') + 
                         characterRange('A', 'Z') + 
                         characterRange('0', '9');

export function generateRandomString(length : number) {
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);

    const outputCharArray = [];
    for (const value of randomValues) {
        const character = randomCharacters[value % randomCharacters.length];
        outputCharArray.push(character);
    }
    
    return outputCharArray.join('');
}

export function compareCaseInsensitive(a: string, b: string) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
}

/** Method compares if every property of the first object is the same in the second object.
 *  The second object may have more properties than the first object which don't affect the result of this method.
  */
export const oneWayCompare = (obj1: any, obj2: any) => {
    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
}

/**
 * Method converts the provided number of seconds to a readable string like '1Y 50D'.
 */
export const secondsToDisplayString = (retention: number) => {
    let total = retention
    let displayString = "";

    const yearInSeconds = 365 * 24 * 60 * 60;
    const totalYears = Math.floor(total/yearInSeconds);
    
    if (totalYears > 0) {
        displayString += `${totalYears} years`;
        total -= (yearInSeconds * totalYears);
    }

    const dayInSeconds = 24 * 60 * 60;
    const totalDays = Math.floor(total/dayInSeconds);

    if (totalDays > 0) {
        displayString += ` ${totalDays} days`;
        total -= (dayInSeconds * totalDays);
    }

    if (total > 0) {
        displayString += ` ${total} seconds`;
    }

    return displayString;
}

export function round(value: number, digits: number) : number {
    const factor = 10 ** digits;
    return Math.round((value + Number.EPSILON) * factor) / factor;
}