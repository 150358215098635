import { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { UserLogin } from './UserLogin';
import { CompanyLogo } from 'components/CompanyLogo';

import * as skinning from 'logic/skinning';
import css from './TopMenu.module.scss';

type TopMenuState = {
    collapsed: boolean   
}

export class TopMenu extends Component<{}, TopMenuState> {
  static displayName = TopMenu.name;

  state = {
    collapsed: true
  };
  
  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    const navLogoStyle = skinning.isKpn() ? css.navLogoKpn : css.navLogoCyberTwice;

    return (
      <header className={css.navMenu}>
        <Navbar className={`${css.topbar} navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3`} light>
          <Container fluid={true}>
            <NavbarBrand>
              <CompanyLogo className={`${navLogoStyle} text-left`}/>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <UserLogin />
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
