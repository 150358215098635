import { Component, createContext } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import * as misc from 'utils/misc';

import css from './NotificationContext.module.scss';

export type IconType = "success" | "danger";

export type Notification = {
    key: string,
    headerText: string,
    bodyText: string,
    icon: IconType,
    timeoutTimer: NodeJS.Timeout | null
}

export type NotificationContextProps = {
    children: React.ReactNode
}

export type NotificationContextState = {
    notifications: Notification[],    
    setNotification: (headerText: string, bodyText: string, icon: IconType) => void,
    setCommunicationFailureNotification: () => void
}

export const NotificationContext = createContext<NotificationContextState | null>(null);

const notificationKeyBase = 'notification';
const communicationFailureKey = 'communicationFailureKey';

export class NotificationContextProvider extends Component<NotificationContextProps, NotificationContextState> {

    state: NotificationContextState = {
        notifications: [],
        setNotification: (headerText, bodyText, icon) => this.setNotification(headerText, bodyText, icon),
        setCommunicationFailureNotification: () => this.setCommunicationFailureNotification()
    }  

    setNotification = (headerText: string, bodyText: string, icon: IconType) => {
        this.setNotificationInternal(headerText, bodyText, icon);
    }

    setNotificationInternal = (headerText: string, bodyText: string, icon: IconType, key?: string) => {
        const id = misc.generateRandomString(16);
        
        const notificationKey = key ? key : `${notificationKeyBase}_${id}`;

        const notification: Notification = {
            key: notificationKey,
            headerText: headerText,
            bodyText: bodyText,
            icon: icon,
            timeoutTimer: null
        }

        if (icon === "success") { // Success notfication have an auto clear.
            notification.timeoutTimer = setInterval(() => this.clearNotification(notificationKey), 5000);
        }

        this.setState({ notifications: [...this.state.notifications, notification] })
    }

    clearNotification = (key: string) => {
        const notificationToClear: Notification | undefined = this.state.notifications.find(notification => notification.key === key);
        if (!notificationToClear) {
            return;
        }

        if (notificationToClear.timeoutTimer) {
            clearInterval(notificationToClear.timeoutTimer);
        }

        this.setState({ notifications: [...this.state.notifications.filter(notification => notification.key !== key)] });
    }

    setCommunicationFailureNotification = () => {
        if (this.state.notifications.find(item => item.key === communicationFailureKey)) {
            console.log("[setCommunicationFailureNotification] communication failure already reported");
            return;
        }

        this.setNotificationInternal('Communication failure', 'Please try again later', 'danger', communicationFailureKey);
    }

    clearsetCommunicationFailureNotification = () => {
        this.clearNotification(communicationFailureKey);
    }
    
    render() {
        const notificationsRendered = this.state.notifications.map(item => {
            return (
                <Toast key={item.key} className={css.notification} isOpen={true} position={"bottom-end"} >
                    <ToastHeader toggle={() => this.clearNotification(item.key)} icon={item.icon} >
                        {item.headerText}
                    </ToastHeader>
                    <ToastBody>
                        {item.bodyText}
                    </ToastBody>
                </Toast>
            );
        });        

        return (
            <NotificationContext.Provider value={this.state}>
                <div className={css.notifier}>
                    {notificationsRendered}
                </div>
                {this.props.children}
            </NotificationContext.Provider>
        )
    }
}