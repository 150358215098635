import { Component, useContext } from 'react';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { Alert, Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { adminConsent } from 'logic/adminConsent';

import css from '../TabConsent.module.scss';

const adminConsentType = "Teams";

type TabConsentTeamsRecordingProps = {
    tenantContext: TenantContextState
}

class TabConsentTeamsRecordingInternal extends Component<TabConsentTeamsRecordingProps, {}> {
    redirect = () => {
        var appId = this.props.tenantContext.getAdminConsentAppId(adminConsentType);
        adminConsent.redirect(adminConsentType, appId);
    }

    renderContent = () => {
        let contentRendered;
        if (!this.props.tenantContext.isAdminConsentGranted(adminConsentType)) {
            // Only show the Admin Consent button when the admin consent has not been granted yet.
            contentRendered = 
                <>
                    <Alert color="warning">
                        The Teams Recording service needs to be granted a limited set of access rights to your Azure environment to function correctly, for example to access the raw audio and video.
                        These rights have to be provided by an administrator of your organization.
                    </Alert>
                    <p>By clicking the link below, you will be redirected to Microsoft to grant these access rights.</p>
                    <Button color="primary" className="mb-3" onClick={this.redirect}>Provide admin consent</Button>
                </>
        } else {
            contentRendered = 
                <Alert color="success">
                    Admin consent has been provided for the Teams recording service.
                </Alert>
        }

        return <div className={css.tabConsent}>
            <h2 className="my-3">Teams recording access</h2>
            {contentRendered}  
        </div>
    }
  
    render() {
        return <Loader isLoading={!this.props.tenantContext.isTenantInfoRetrieved} showApiError={false} render={this.renderContent} />
    }
}

// Inject the required contexts.
export const TabConsentTeamsRecording = (props: any) => {
    const tenantContext = useContext(TenantContext);

    return (
        <TabConsentTeamsRecordingInternal {...props} tenantContext={tenantContext}/>
    )
}