import { Component } from 'react';
import { Button, CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { Loader } from 'components/Loader';
import { oneWayCompare } from 'utils/misc';

// This tab is currently not being showed, but was only added for demo-purposes.

export class TabProcessingVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showApiError: false,
            videoAnalyticsEnabled: false,
            stateInBackend: {
                videoAnalyticsEnabled: false,
            }            
        }   
    }

    async componentDidMount() {     
        // ToDo: Retrieve values from back-end...
        this.setState({
            isLoading: false
        })
    }    

    toggleEnableVideoAnalytics = () => {
        this.setState(
            {
                videoAnalyticsEnabled: !this.state.videoAnalyticsEnabled
            }
        )
    }

    isSaveButtonEnabled = () => {
        return !oneWayCompare(this.state.stateInBackend, this.state);
    }
   
    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <div className="m-3">
                    <div className="mt-3 mb-5">
                        <Form>
                            <FormGroup>
                                <Label for="enableVideoAnalyticsSwitch"><strong>Video analytics</strong></Label>
                                <p>The video analytics is a cognitive service that will extract all kinds of meta-data from the recorded video, like face-recognition and brand detection.</p>
                                <div className="mt-3">
                                    <CustomInput checked={this.state.videoAnalyticsEnabled} type="switch" onChange={this.toggleEnableVideoAnalytics} id="enableVideoAnalyticsSwitch" label="Enable video analytics" />
                                </div>
                            </FormGroup>
                        </Form>
                    </div>
                    <Button disabled={!this.isSaveButtonEnabled()} color='primary' >Save</Button>
                </div>
            </Loader>            
        )
    }
}