import React from 'react';
import { Table, Alert } from 'reactstrap';
import { EditButton } from 'components/EditButton';
import { DeleteButton } from 'components/DeleteButton';
import { compareCaseInsensitive } from 'utils/misc';
import * as arrayUtils from 'utils/arrayExtensions';
import css from './RecordedGroups.module.scss';

const style = {
    width: '40px',
    height: '40px'
}

export const RecordedGroups = ({ recordedGroups, onEdit, onDelete }) => {

    if (recordedGroups.length === 0) {
        return <Alert color='warning'>
            No groups are configured for recording.
        </Alert>
    }

    const recordedGroupEntriesRendered = arrayUtils.sortOnField(recordedGroups, item => item.name, compareCaseInsensitive).map(item => {

        const warningIcon = item.type.toLowerCase() === "nonexistent" ? 
            <div className={css.flexItemRight}>
                <img style={style} src='./images/warningIcon.png' alt='Warning icon'/>
            </div> : null;

        return <tr key={item.id} className="d-flex">
            <th className="col-3">
                <div className={css.flexContainer}>
                    <div>
                        {item.name}
                    </div>
                    {warningIcon}
                </div>
            </th>            
            <th className="col-3">{item.id}</th>
            <th className="col-2">{item.type}</th>
            <th className="col-2">{item.compliant ? "Yes" : "No"}</th>
            <th className="col-2">
                <DeleteButton className='float-right mr-3' onClick={() => onDelete(item)}/>
                <EditButton className='float-right mr-3' onClick={() => onEdit(item)}/>
            </th>
        </tr>;
    });

    return (
        <div className={css.recordedGroups}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-3">Groupname</th>
                        <th className="col-3">Id</th>
                        <th className="col-2">Type</th>
                        <th className="col-2">Compliant</th>
                        <th className="col-2"></th>                        
                    </tr>
                </thead>
                <tbody>
                    {recordedGroupEntriesRendered}
                </tbody>
            </Table>
        </div>
    )
}