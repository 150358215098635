import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DoneButton } from 'components/DoneButton';

interface ModalConfirmProps {
  isOpen: boolean;  
  onToggle: () => void;
  onConfirm: () => void;
  headerText?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  detailedText?: string;
}

interface ModalConfirmState {
    showBusy: boolean;
}

export class ModalConfirm extends Component<ModalConfirmProps, ModalConfirmState> {
    state: ModalConfirmState = {
        showBusy: false
    }

    componentDidUpdate(prevProps: ModalConfirmProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({ showBusy: false });
        }
    }

    onConfirm  = async (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ showBusy: true });
        this.props.onConfirm();
    }

    render() {
        const isOpen = this.props.isOpen;
        const showBusy = this.state.showBusy;
        const onToggle = this.props.onToggle;
        const headerText = this.props.headerText || 'Confirmation needed';
        const confirmButtonText = this.props.confirmButtonText || 'Yes';
        const cancelButtonText = this.props.cancelButtonText || 'No';
        const detailedText = this.props.detailedText || 'Are your sure you want to proceed?';

        return (
            <Modal isOpen={isOpen} toggle={onToggle}>
                <ModalHeader toggle={onToggle}>{headerText}</ModalHeader>
                <ModalBody>{detailedText}</ModalBody>
                <ModalFooter>
                    <DoneButton id="ModalConfirmDoneButton" showBusy={showBusy} buttonText={confirmButtonText} onClick={this.onConfirm} />                    
                    <Button color='secondary' onClick={onToggle}>
                        {cancelButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}