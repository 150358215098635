import { Component } from 'react';
import { Spinner } from 'reactstrap';
import { CommunicationError } from './CommunicationError';

type LoaderProps = {
    isLoading: boolean,
    showApiError: boolean,
    render?: () => JSX.Element,
    children?: JSX.Element | JSX.Element[]
};

type LoaderState = {
    showSpinner: boolean
}

/**
 * Component handles the displaying of the Spinner while data is being loaded, the displaying of an alert in case the loading failed and 
 * displaying of the actual contents in case the data has successfully been loaded.
 * The contents can either be provided as React children or using the 'render' property.
 */
export class Loader extends Component<LoaderProps, LoaderState> {
    timer: number | undefined;

    state: LoaderState = {
        showSpinner: false
    }

    componentDidMount(): void {
        this.updateTimer(false, this.props.isLoading);
    }

    componentDidUpdate(prevProps: Readonly<LoaderProps>, prevState: Readonly<LoaderState>, snapshot?: any): void {
        this.updateTimer(prevProps.isLoading, this.props.isLoading);
    }

    updateTimer = (previousIsLoading: boolean, currentIsLoading: boolean) => {
        if (currentIsLoading && !previousIsLoading) {
            this.timer = window.setInterval(() => this.setState({ showSpinner: true }),  500);
        }
        else if (!currentIsLoading && previousIsLoading) {
            window.clearInterval(this.timer);
        }
    }

    render() {
        if (this.props.isLoading) {
            if (this.state.showSpinner) {
                return (
                    <>
                        <Spinner className="mt-3" color="secondary"/>
                    </>
                );
            }

            return <></>
        }

        if (this.props.showApiError) {
            return (
                <CommunicationError detail=''/>
            )
        }

        if (this.props.render) {
            return this.props.render()
        }

        if (this.props.children) {
            return this.props.children
        }
        
        return null;
    }
}