import React, { Component } from 'react';

const defaultOnSurfaceActivityTimeoutMs = 5000;
const defaultOffSurfaceActivityTimeoutMs = 500;

type ActivityMeasurementProps = {
    initialIsActive: boolean,
    onActivityStateChange: (isActive: boolean) => void,
    onSurfaceActivityTimeoutMs: number | undefined,
    offSurfaceActivityTimeoutMs: number | undefined,
    children: React.ReactNode
}

// This component is used as parent component and measures if the user has performed activity within the childs components within the provided activity time-out period.
// Activity is when the user has moved its mouse, pushsed a button or scrolled the contents.
export class ActivityMeasurement extends Component<ActivityMeasurementProps, {}> {
    timerId: NodeJS.Timeout | undefined;
    isActive: boolean = this.props.initialIsActive
    isOnSurface: boolean = false;
    onSurfaceActivityTimeoutMs: number =  this.props.onSurfaceActivityTimeoutMs || defaultOnSurfaceActivityTimeoutMs;
    offSurfaceActivityTimeoutMs: number = this.props.offSurfaceActivityTimeoutMs || defaultOffSurfaceActivityTimeoutMs;
    isUnmounted: boolean = false;

    componentDidMount() {
        if (this.props.initialIsActive) {
            // Ensure the time-out timer is started when the initial state is active.
            this.handleUserActivity();
        }                
    }

    componentWillUnmount() {
        clearTimeout(this.timerId);
        this.isUnmounted = true;
    }

    handleUserActivity = () => {
        clearTimeout(this.timerId);
        
        this.reportActivityStateChange(true);
        
        const timeout = this.isOnSurface ? this.onSurfaceActivityTimeoutMs : this.offSurfaceActivityTimeoutMs;        

        this.timerId = setTimeout(() => {
            this.reportActivityStateChange(false);
        }, timeout);
    }

    handleMouseEnter = () => {
        this.isOnSurface = true;
        this.handleUserActivity();
    }

    handleMouseLeave = () => {
        this.isOnSurface = false;
        this.handleUserActivity();
    }

    reportActivityStateChange = (newIsActiveState: boolean) => {
        if (newIsActiveState !== this.isActive && !this.isUnmounted) {
            if (this.props.onActivityStateChange) {
                this.props.onActivityStateChange(newIsActiveState);
            }

            this.isActive = newIsActiveState;
        }
    }

    render() {
        return (
            <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onTouchMove={this.handleUserActivity} onMouseMove={this.handleUserActivity} onScroll={this.handleUserActivity} onKeyDown={this.handleUserActivity} >
                {this.props.children}
            </div>
        )      
    }
}
