import { Component, useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { TabRecordingTeamsStatus } from './Status/TabRecordingTeamsStatus';
import { TabRecordingTeamsGlobal } from './Global/TabRecordingTeamsGlobal';
import { TabRecordingTeamsGroupSettings } from './Groups/TabRecordingTeamsGroupSettings';
import { TabHeader } from '../TabHeader';
import { TenantContext, TenantContextState } from 'context/TenantContext';

type TeamsRecordingInternalProps = {
    tenantContext: TenantContextState
}

export class TeamsRecordingInternal extends Component<TeamsRecordingInternalProps, {}> {
    state = {
        forceRefresh: 0
    }

    handleGroupSettingsOnChange = () => {
        this.setState({
            forceRefresh: (this.state.forceRefresh + 1)
        });
    }

    render() {
        const tabInfos = [
            { name: "Status", tabId: "0" },
            { name: "Global", tabId: "1" },
            { name: "Groups", tabId: "2" }
        ]

        return (
            <>
                <h1>Teams recording settings</h1>

                <TabHeader tabInfos={tabInfos}>   
                    <TabContent>
                        <TabPane tabId="0">
                            <TabRecordingTeamsStatus forceRefresh={this.state.forceRefresh}/>
                        </TabPane>
                        <TabPane tabId="1">
                            <TabRecordingTeamsGlobal />
                        </TabPane>
                        <TabPane tabId="2">
                            <TabRecordingTeamsGroupSettings onChange={this.handleGroupSettingsOnChange} />
                        </TabPane>                  
                    </TabContent>    
                </TabHeader>           
            </>
        );
    }
}

// Inject the required contexts.
export const TeamsRecording = (props: any) => {
    const tenantContext = useContext(TenantContext);
  
    return (
        <TeamsRecordingInternal tenantContext={tenantContext} {...props}/>
    )
}