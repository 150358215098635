import { Component, useContext } from 'react';
import { Nav } from 'reactstrap';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuHeader } from './SideMenuHeader';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { UserContext, UserContextState } from 'context/UserContext';
import { urls } from 'logic/urls';
import css from './SideMenu.module.scss';

type SideMenuInternalProps = {
    tenantContext: TenantContextState,
    userContext: UserContextState
}

class SideMenuInternal extends Component<SideMenuInternalProps, {}> {
    render() {
        const shouldDisplaySubscriptionMenu = this.props.tenantContext.shouldDisplaySubscriptionsMenu();
        const shouldDisplayDashboard = this.props.tenantContext.shouldDisplayDashboard();
        const shouldDisplayMobileRecordingMenu = this.props.tenantContext.shouldDisplayMobileRecordingMenu();
        const shouldDisplayTeamsRecordingMenu = this.props.tenantContext.shouldDisplayTeamsRecordingMenu();
        const shouldDisplayProcessingMenu = this.props.tenantContext.shouldDisplayProcessingMenu();
        const shouldDisplayPortalAccessMenu = this.props.tenantContext.shouldDisplayPortalAccessMenu();
        const shouldDisplayReplayMenu = this.props.tenantContext.shouldDisplayReplayMenu();
        const isDistributor = this.props.userContext.isDistributor();

        const distributorMenuRendered = isDistributor ? 
            <>
                <SideMenuHeader text="Distributor" />
                <SideMenuItem text="Tenants" icon="fa fa-address-book" url={urls.distributorTenants}/>
                <SideMenuItem text="Mobile settings" icon="fa fa-mobile" url={urls.distributorMobileSettings}/>
                <SideMenuItem text="Audit trail" icon="fa fa-book" url={urls.distributorAuditTrail}/>
            </> : 
            null;

        return (
            <div className={css.sideMenu}>
                <Nav vertical={true} tabs={false}>
                    <ul className="navbar-nav flex-grow">
                        {distributorMenuRendered}

                        <SideMenuHeader text="Tenant" />
                        {shouldDisplaySubscriptionMenu && <SideMenuItem text="Subscription" icon="fa fa-list" url={urls.subscription}/>}
                        {shouldDisplayDashboard && <SideMenuItem text="Dashboard" icon="fa fa-dashboard" url={urls.dashboard}/>}
                        <SideMenuItem text="Consent" icon="fa fa-handshake-o" url={urls.consent}/>
                        {shouldDisplayMobileRecordingMenu && <SideMenuItem text="Mobile recording" icon="fa fa-mobile" url={urls.mobileRecording}/>}
                        {shouldDisplayTeamsRecordingMenu && <SideMenuItem text="Teams recording" icon="fa fa-cog" url={urls.teamsRecording}/>}                        
                        {shouldDisplayProcessingMenu && <SideMenuItem text="Processing" icon="fa fa-bar-chart" url={urls.processing}/>}                        
                        {shouldDisplayReplayMenu && <SideMenuItem text="Replay" icon="fa fa-play-circle-o" url={urls.replay}/> }
                        {shouldDisplayPortalAccessMenu && <SideMenuItem text="Portal access" icon="fa fa-key" url={urls.portalAccess}/>}                 
                    </ul>
                </Nav>
            </div>
        );
    }
}

// Inject the required contexts.
export const SideMenu = (props: any) => {
    const userContext = useContext(UserContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <SideMenuInternal userContext={userContext} tenantContext={tenantContext} {...props}/>
    )
}