import React, { PureComponent, ReactNode } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, Text } from 'recharts';
import { getColor } from 'logic/chartLogic';
import { Spinner } from 'react-bootstrap';

interface PieData {
  name: string;
  value: number;
}

interface ChartPieProps {
  isLoading: boolean;
  data: PieData[];
}

export class ChartPie extends PureComponent<ChartPieProps> {
  renderNameLabel = (e: any) => {
    const { x, y, cx, index } = e;

    return (
      <Text
        x={x}
        y={y}
        fill='black'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {this.props.data[index].name}
      </Text>
    );
  };

  // The ResponsiveContainer of the Recharts library has an resizing issue when used inside a flex container.
  // The work-around described here is applied:
  // https://github.com/recharts/recharts/issues/172#issuecomment-523362671
  render(): ReactNode {
    const loadingOverlayRendered = (
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spinner animation='border' variant='secondary' />
      </div>
    );

    return (
      <div style={{ position: 'relative', width: '100%', paddingBottom: '300px' }}>
        <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={this.props.data}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={this.renderNameLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {this.props.data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getColor(index, this.props.data.length)} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          {this.props.isLoading ? loadingOverlayRendered : null }
        </div>
      </div>
    );
  }
}