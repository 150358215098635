import React, { Component } from 'react';
import { Nav, NavItem, NavLink} from 'reactstrap';
import { WarningIcon } from 'components/WarningIcon';
import cx from 'classnames';
import css from './TabHeader.module.scss';

export type TabInfo = {
    name: string,
    tabId: string,
    alert?: boolean,
    alertReason?: string
}

type TabHeaderProps = {
    tabInfos: TabInfo[],
    children: React.ReactNode
}

type TabHeaderState = {
    activeTabIndex: number,
    activeTabId: string
}

export class TabHeader extends Component<TabHeaderProps, TabHeaderState> {
    state = {
        activeTabIndex: 0,
        activeTabId: this.props.tabInfos[0].tabId || ""
    }

    setActiveTab = (tabId: string, tabIndex: number) => {
        this.setState({
            activeTabIndex: tabIndex,
            activeTabId: tabId
        });
    }

    render() {
        const activeTabId = this.state.activeTabId;
        const activeTabIndex = this.state.activeTabIndex

        // Render the children and assign the current active TabId to the 'activeTab' prop.
        // With correct usage of this component the only child is a 'TabContent' element from the 'reactstrap' library  
        // which uses this 'activeTab' property to show/hide the correct tab.
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child as React.ReactElement<any>, { activeTab: activeTabId });
            }

            return child;
        });

        return (
            <>
                <Nav tabs className={cx('mt-3', css.tabheader)} >
                    { this.props.tabInfos?.map((tabInfo, tabIndex) => {
                        return (
                            <NavItem key={`${tabIndex}|${tabInfo.name}`}>
                                <NavLink className={cx('text-dark', { active: activeTabIndex === tabIndex })} onClick={() => { this.setActiveTab(tabInfo.tabId, tabIndex); }}>
                                    <div>
                                        {tabInfo.name}
                                        <WarningIcon id={`${tabIndex}-warning`} tooltip={tabInfo.alertReason || ""} showWarning={tabInfo.alert || false} />                                        
                                    </div>
                                </NavLink>
                            </NavItem>
                        )
                    }) }
                </Nav>
                
                {childrenWithProps}
            </>
        )
    }
}