import { Component } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { TabProcessingTranscription } from './Transcription/TabProcessingTranscription';
import { TabProcessingRetention } from './Retention/TabProcessingRetention';
import { TabProcessingVideo } from './Video/TabProcessingVideo';
import { TabHeader } from '../TabHeader';

export class Processing extends Component {   
    render() {
        // The Video Processing tab is currently now shown. This tab was added for demo-purposes.
        const tabInfos = [
            { name: "Transcription", tabId: "0" },            
            { name: "Retention", tabId: "2" },            
        ]

        return (
            <>
                <h1>Processing</h1>

                <TabHeader tabInfos={tabInfos}>
                    <TabContent>
                        <TabPane tabId="0">
                            <TabProcessingTranscription />
                        </TabPane>
                        <TabPane tabId="1">
                            <TabProcessingVideo />
                        </TabPane>
                        <TabPane tabId="2">
                            <TabProcessingRetention />
                        </TabPane>
                    </TabContent>   
                </TabHeader>
            </>
        );
    }
}
