export interface AppConfig {
    authority: string;
    clientId: string;
    navigateToLoginRequestUrl: boolean;
    logLevel: string;
    apiScopes: string[];
    managementPortalApiAddress: string;
    noAuthorizationUrls: string[];
    skin: string;
}

export const appConfig: AppConfig = {
    authority: 'https://login.microsoftonline.com/common',
    clientId: '__START__ClientAppId__END__',
    navigateToLoginRequestUrl: false,    
    logLevel: 'trace',
    apiScopes: [
        'api://__START__ManagementApiAppId__END__/Content.All',
    ],
    managementPortalApiAddress: '__START__managementPortalApiAddress__END__',
    // List of URLs of this app for which no authorization should be performed.
    noAuthorizationUrls: [],
    skin: '__START__skin__END__',
};
