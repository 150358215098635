import { Component } from 'react';
import { AuditEventData } from 'logic/apiCalls';
import dayjs from 'dayjs'
import { Collapse } from 'reactstrap';

type AuditEventProps = {
    event: AuditEventData
}

type AuditEventState = {
    isDetailOpen: boolean
}

export class AuditEvent extends Component<AuditEventProps, AuditEventState> {
    state: AuditEventState = {
        isDetailOpen: false
    }

    detailToggle = () => {
        this.setState({
            isDetailOpen: !this.state.isDetailOpen
        });
    }

    render() {
        const event = this.props.event;        
        const timeStamp = dayjs(event.metaData.timeStamp);

        return (
            <>
                <tr onClick={this.detailToggle} key={event.metaData.id} className='d-flex'>
                    <td className="col-2">{timeStamp.format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td className="col-3">{event.metaData.tenantName}</td>
                    <td className="col-3">{event.fullName}</td>
                    <td className="col-4">{event.metaData.initiatedBy.userName}</td>
                </tr>
                <tr>
                    <Collapse isOpen={this.state.isDetailOpen}>
                        <td colSpan={12} >
                            <pre>
                                {JSON.stringify(event, null, 4)}
                            </pre>
                        </td>
                    </Collapse>
                </tr>
            </>
        )
    }
}
