import React from 'react';
import { Button } from 'reactstrap';
import { MobileRecorder } from 'logic/apiCalls';

import css from './MobileRecorderEntry.module.scss';

type MobileRecorderEntryProps = {
    mobileRecorder: MobileRecorder, 
    handleOperationalStatusChange: (mobileRecorderToToggle: MobileRecorder) => void,    
}

export const MobileRecorderEntry = ({ mobileRecorder, handleOperationalStatusChange } : MobileRecorderEntryProps) => {
    const buttonText = mobileRecorder.retired ?
        "Enable" :
        "Retire";

    const statusClass = mobileRecorder.status !== "Active" ? css.warning : "";

    return (
        <tr key={mobileRecorder.id} className={`d-flex ${mobileRecorder.retired ? css.disabledMobileRecorder : "" }`}>
            <td className="col-8">{mobileRecorder.id}</td>
            <td className={`col-2 ${statusClass}`}>{mobileRecorder.status}</td>
            <td className="col-2">
                <Button color='primary' onClick={() => handleOperationalStatusChange(mobileRecorder)}>{buttonText}</Button>                 
            </td>
        </tr>
    )
}