import { Component, useContext } from 'react';
import { Button } from 'reactstrap';
import { RecordedGroups } from './RecordedGroups';
import { ModalRecordedGroupAddOrUpdate } from './ModalRecordedGroupAddOrUpdate';
import { ModalConfirm } from 'components/ModalConfirm';
import { Loader } from 'components/Loader';
import { NotificationContext } from 'context/NotificationContext';
import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

class TabRecordingTeamsGroupSettingsInternal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showApiError: false,
            groupInfos: [],
            recordedGroupSettings: [],
            isDeleteModalOpen: false,
            groupInfoToUpdate: null,         
            groupInfoToDelete: {},
            isAddModalOpen: false,
            groupInfoToAdd: {}            
        }
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    loadPageData = async () => {
        try {
            const groupInfosPromise = apiCalls.getAzureGroups();
            const groupSettingsPromise = apiCalls.getTeamsRecordedGroupSettings();
            
            const [groupInfos, groupSettings] = await Promise.all([groupInfosPromise, groupSettingsPromise]);

            this.setState({
                isLoading: false,
                showApiError: false,
                groupInfos: groupInfos,
                recordedGroupSettings: groupSettings,
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);
            this.setState({
                isLoading: false,
                showApiError: true
            });

        }
    }

    toggleDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    }

    toggleAddOrUpdateModal = () => {
        this.setState({
            isAddModalOpen: !this.state.isAddModalOpen
        });
    }
    
    handleAssignRecordedGroup = () => {
        this.setState({
            groupInfoToUpdate: null
        });
        this.toggleAddOrUpdateModal();
    }

    handleRecorderGroupEdit = (groupInfoToEdit) => {
        this.setState({
            groupInfoToUpdate: groupInfoToEdit
        });
        this.toggleAddOrUpdateModal();
    }

    handleRecorderGroupDelete = (groupInfoToDelete) => {
        this.setState({
            groupInfoToDelete: groupInfoToDelete
        });
        this.toggleDeleteModal();
    }

    executeDelete = async () => {
        const groupInfoToDelete = this.state.groupInfoToDelete;

        log.debug(`[executeDelete] Removing the group '${groupInfoToDelete.name} (${groupInfoToDelete.id})' from the recorded groups.`);

        try {
            await apiCalls.removeTeamsRecordingFromGroup(groupInfoToDelete.id);
            await this.loadPageData();
            this.props.notificationContext.setNotification("Recording configuration modified", `Successfully removed group '${groupInfoToDelete.name}' from the recorded groups.`, 'success');
        }
        catch (error)
        {
            log.error(`[executeDelete] Error during delete of group '${groupInfoToDelete.name} (${groupInfoToDelete.id})': ${error}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        finally {
            this.toggleDeleteModal();
            this.props.onChange?.();
        }
    }

    executeAddOrUpdate = async (doneInfo) => {
        log.debug(`[executeAddOrUpdate] Adding the group '${doneInfo.groupInfo.name} (${doneInfo.groupInfo.id})' (compliant: ${doneInfo.isCompliant}) to the recorded groups.`);

        try {
            if (doneInfo.isUpdate) {
                await apiCalls.updateTeamsRecordingGroup(doneInfo.groupInfo.id, doneInfo.isCompliant);
            } else {
                await apiCalls.addTeamsRecordingToGroup(doneInfo.groupInfo.id, doneInfo.isCompliant);
            }

            
            await this.loadPageData();

            const notificationText = doneInfo.isUpdate ? `Successfully updated group '${doneInfo.groupInfo.name}'.` : `Successfully added group '${doneInfo.groupInfo.name}' to the recorded groups.`;
            this.props.notificationContext.setNotification("Recording configuration modified", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        finally {
            this.toggleAddOrUpdateModal();
            this.props.onChange?.();
        }
    }

    render() {
        const unrecordedGroups = this.state.groupInfos.filter(
            groupInfo => !this.state.recordedGroupSettings.some(
                groupSetting => groupInfo.id === groupSetting.id));

        const groupToDeleteName = this.state.groupInfoToDelete?.name || '';

        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <div className="m-3">
                    <ModalConfirm
                        headerText="Confirm delete"
                        detailedText={`The users in group '${groupToDeleteName}' will no longer be recorded.\r\n
                                       Are you sure you want to proceed?`}
                        isOpen={this.state.isDeleteModalOpen}
                        onToggle={this.toggleDeleteModal}
                        onConfirm={this.executeDelete} />
                    <ModalRecordedGroupAddOrUpdate isOpen={this.state.isAddModalOpen} groupInfos={unrecordedGroups} groupInfoToUpdate={this.state.groupInfoToUpdate} onToggle={this.toggleAddOrUpdateModal} onAddOrUpdate={this.executeAddOrUpdate} />

                    <RecordedGroups recordedGroups={this.state.recordedGroupSettings} onEdit={this.handleRecorderGroupEdit} onDelete={this.handleRecorderGroupDelete} />
                    <Button className="mt-3" color='primary' onClick={this.handleAssignRecordedGroup}>Assign</Button>
                </div>
            </Loader>
        );
    }
}

// Inject the required contexts.
export const TabRecordingTeamsGroupSettings = (props) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabRecordingTeamsGroupSettingsInternal {...props} notificationContext={notificationContext} />
    )
}