import { HSLColour } from 'utils/Colour.src';
import { toDisplayString } from './displayString';
import moment from 'moment';

/** Type defines a single value of a facet.  */
type FacetValue = {
    value: string,
    count: number
}

/** Type defines the facet data which is retrieved by the name of the facet and returns an array of FacetValues. */
type Facets = {
    [key: string]: FacetValue[] | null
}

/** Type represents a statistic entry which contains data of a single period, which can be for example an hour, day, week or month. */
type Statistic = {
    /** The start time of the period of this statistic in ISO8601 format. */
    from: string,
    /** The end time of the period of this statistic in ISO8601 format. */
    to: string,
    /** The total number of calls in this period. */
    totalCount: number,
    /** The relevant facet data of this period. */
    facets: Facets
}

/** Type defines the possible resolutions of the statistics. */
type StatisticResolution = "all" | "month" | "week" | "day" | "hour";

/** Type defines the possible ranges for which the statistics can be determined. */
type StatisticRange = "full" | "lastYear" | "lastMonth" | "lastWeek" | "lastDay";

/** Get an HTML color string (like '#5c5cd6') for the provided index of the chart entry. This color is determined based upon the 
 *  total number of entries and the index of the current entry. The colors are divided equally over the HSL color scheme.
  */
export const getColor = (index: number, colorCount: number) : string => {    
    const stepSize = 360 / colorCount;
    const offset = 240;
    const hslValue = (offset + (stepSize * index)) % 360;
    
    const barColor = new HSLColour(hslValue, 60, 60) as any;
    return barColor.getCSSHexadecimalRGB();
}

/** Gets the data and bar definitions for the 'Total recording volume' graph. */
export const getTotalRecordingVolume = (statistics: Statistic[], resolution: StatisticResolution, range: StatisticRange) => {    
    const totalRecordingVolume = statistics.map(statistic => {
        const dateMoment = moment(statistic.from);
        const period = getPeriodDisplayName(dateMoment, resolution, range);

        return {
            date: period,
            totalCount: statistic.totalCount
        }
    });

    return {
        data: totalRecordingVolume.reverse(),
        definitions: [{displayName: "Total count", valueName: "totalCount"}]
    }
}

/** Gets the data and bar definitions for the 'Call volume per source type' graph. */
export const getVolumePerSourceType = (statistics: Statistic[], resolution: StatisticResolution, range: StatisticRange) => {
    const uniqueSourceTypes = new Map();
    const recordingVolumePerSourceType = statistics.map(statisticsOfSingleDay => {
        const dateMoment = moment(statisticsOfSingleDay.from);
        const dateString = getPeriodDisplayName(dateMoment, resolution, range);

        let data = new Map<string, number>();
        const typeOfMediaData = statisticsOfSingleDay.facets['Type of media'] || [];
        for (const mediaData of typeOfMediaData) {
            const typeOfMediaKey = normalizeSourceType(mediaData.value);

            // Note: Because of the normalization, multiple source entries may end-up under the same key after the normalization has been applied.
            // For example: 'video,audio' and 'Audio,Video' will both be placed under the 'Audio, Video' key.
            const existingValue = data.get(typeOfMediaKey) || 0;
            data.set(typeOfMediaKey, existingValue + mediaData.count);
            uniqueSourceTypes.set(typeOfMediaKey, true);
        }

        return {
            date: dateString,
            ...Object.fromEntries(data.entries())
        }
    });

    return {
        data: recordingVolumePerSourceType.reverse(),
        definitions: [...uniqueSourceTypes.keys()].map(sourceType => { return {displayName: sourceType, valueName: sourceType} })
    }    
}

/** Gets the Pie chart data for the 'Calls per user' graph. */
export const getCallsPerUser = (statistics: Statistic[]) => {
    return {
        data: createPieChartData(statistics, "User", 3, 3)
    }
}

/** Gets the Pie chart data for the 'Direction' graph. */
export const getDirection = (statistics: Statistic[]) => {
    return {
        data: createPieChartData(statistics, "Call direction", 3, 3)
    }
}

const getPeriodDisplayName = (dateMoment: moment.Moment, resolution: StatisticResolution, range: StatisticRange) => {   
    switch (resolution) {
        case 'all':
            return `From ${dateMoment.format('YYYY-MM-DD')} to today`
        case 'hour':
            return dateMoment.format('HH:mm');
        case 'day':            
            if (range === 'lastWeek') {
                return dateMoment.format('ddd');
            }
            else {
                return dateMoment.format('YYYY-MM-DD');
            }
        case 'month':
            return dateMoment.format('MMM');
        default:
            return dateMoment.format('YYYY-MM-DD');
    }
}

// This method is a work-around to hide inconsistencies in the source data.
// For example the 'source type' data has different casing and different ordering, 
// which are categorized differently even though they represent the same data.
// For example 'video,audio' and 'audio,video' and 'Audio,Video' are now all converted to 'Audio, Video'.
const normalizeSourceType = (sourceTypeString: string) : string => {
    if (!sourceTypeString) {
        return sourceTypeString;
    }

    const splitted = sourceTypeString.toLowerCase().split(',');
    const ordered = splitted.sort().join(',');

    return toDisplayString(ordered);
}

const createPieChartData = (statistics: Statistic[], facetName: string, minimumEntries: number, minimumPercentage: number) => {
    let totals = new Map<string, number>();

    for (const statistic of statistics) {
        const facetEntries = statistic.facets[facetName];
        
        if (!facetEntries) {
            continue;                
        }

        for (const facetEntry of facetEntries) {
            const currentTotal = totals.get(facetEntry.value) || 0;
            totals.set(facetEntry.value, currentTotal + facetEntry.count);
        }
    }

    const sortedMap = new Map([...totals.entries()].sort((a,b) => b[1] - a[1]));

    const output = [];
    let currentTotal = 0;
    let otherValues = 0;
    for (const [currentName, currentValue] of sortedMap.entries()) {
        if (output.length >= minimumEntries) {
            // Verify that the current value has enough significance to meet the minimum percentage.            
            const percentage = currentValue / currentTotal * 100;
            if (percentage < minimumPercentage) {
                otherValues += currentValue;
                // Note: Because the entries in the map are sorted in descending order, all next entries will
                // have equal or less significance than this entry and thus will all end-up on the 'otherValues'.
                continue;
            }
        }

        output.push({
            name: currentName,
            value: currentValue
        });

        currentTotal += currentValue;
    }

    if (otherValues > 0) {
        output.push({
            name: 'Other',
            value: otherValues
        });
    }

    return output;
}