import React, { Component, createRef, RefObject } from 'react';

interface AspectRatioProps {
  className: string;
  aspectRatio: string;
  children: React.ReactNode;
}

export class AspectRatio extends Component<AspectRatioProps> {
  private containerRef: RefObject<HTMLDivElement>;
  private ratio: number;

  constructor(props: AspectRatioProps) {
    super(props);

    this.containerRef = createRef<HTMLDivElement>();

    // Calculate the ratio with the width as 1.
    if (!props.aspectRatio) {
      console.error("[AspectRatio, constructor] No aspect ratio provided.");
      this.ratio = 1;
      return;
    }

    const splitted = props.aspectRatio.split(':');
    if (splitted.length !== 2) {
      console.error("[AspectRatio, constructor] Invalid aspect ratio provided.");
      this.ratio = 1;
      return;
    }

    const widthRatio = parseFloat(splitted[0]);
    const heightRatio = parseFloat(splitted[1]);

    this.ratio = heightRatio / widthRatio;
  }

  componentDidMount() {
    this.updateDimensions();
  }

  updateDimensions = () => {
    if (!this.containerRef.current) {
      return;
    }

    this.containerRef.current.style.paddingTop = `${this.ratio * 100}%`;
  };

  render() {
    return <div className={this.props.className} ref={this.containerRef}>{this.props.children}</div>;
  }
}