import { FC } from 'react';
import { Table, Alert } from 'reactstrap';
import { DeleteButton } from 'components/DeleteButton';
import { EditButton } from 'components/EditButton';
import { toDisplayString } from 'logic/displayString';
import { compareCaseInsensitive } from 'utils/misc';
import { PermissionGroup } from 'logic/apiCalls';
import * as arrayUtils from 'utils/arrayExtensions';
import css from './GroupPermissions.module.scss';

const style = {
    width: '40px',
    height: '40px'
}

type GroupPermissionProps = {
    permissionGroups: Array<PermissionGroup>,
    name: string,
    handleEdit: (item: PermissionGroup) => void,
    handleDelete: (item: PermissionGroup) => void
}

const permissionsToDisplayString = (permissions: string[]) => {
    return permissions.map(permission => toDisplayString(permission)).sort();
}

export const GroupPermissions : FC<GroupPermissionProps> = ({ permissionGroups, name, handleEdit, handleDelete }) => {

    if (permissionGroups.length === 0) {
        return <Alert color='warning'>
            {`No ${name} permissisons are configured.`}
        </Alert>
    }
   
    const groupSettingsOrdered = arrayUtils.sortOnField(permissionGroups, item => item.name, compareCaseInsensitive) as PermissionGroup[];

    const groupSettingsRendered = groupSettingsOrdered.map(item => {
        const warningIcon = item.type.toLowerCase() === "nonexistent" ? 
            <div className={css.flexItemRight}>
                <img style={style} src='./images/warningIcon.png' alt='Warning icon'/>
            </div> : null;

        return <tr key={item.id} className="d-flex">
            <th className="col-3">
                <div className={css.flexContainer}>
                    <div>
                        {item.name}
                    </div>
                    {warningIcon}
                </div>
            </th>            
            <th className="col-3">{item.id}</th>
            <th className="col-2">{item.type}</th>
            <th className="col-2">
                <ul>
                    { permissionsToDisplayString(item.permissions).map(permission => 
                        <li key={permission}>{permission}</li>
                    ) }
                </ul>
            </th>
            <th className="col-2">
                <DeleteButton className='float-right mr-3' onClick={() => handleDelete(item)}/>
                <EditButton className='float-right mr-3' onClick={() => handleEdit(item)}/>
            </th>
        </tr>;
    })

    return (
        <div className={css.groupPermissions}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-3">Groupname</th>
                        <th className="col-3">Id</th>
                        <th className="col-2">Type</th>
                        <th className="col-2">Permissions</th>
                        <th className="col-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {groupSettingsRendered}
                </tbody>
            </Table>
        </div>
    )
}