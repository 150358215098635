import { AppConfig, appConfig as appConfigStaticImport } from './appConfig';
import { appConfig as appConfigDevelopmentImport } from './appConfig.development';

declare const window: {
    config: object;
  } & Window;

// merge file based config with static config
const appConfigImport = {...appConfigStaticImport, ...window.config};
// force the noAuthorizationUrls from the static config
appConfigImport.noAuthorizationUrls = appConfigStaticImport.noAuthorizationUrls;

//console.log(appConfigImport)

// The development configuration only contains the values that needs to be overridden in the appConfig.
// The complete development configuration is created by merging these two configurations.
const developmentConfig: AppConfig = { ...appConfigImport, ...appConfigDevelopmentImport }

const runsInDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const appConfigOutput = runsInDevelopment ? developmentConfig : appConfigImport;

export const appConfig: AppConfig = appConfigOutput;