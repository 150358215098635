import React from 'react';
import { Table, Alert } from 'reactstrap';
import { MobileRecorderEntry }  from './MobileRecorderEntry'
import { MobileRecorder } from 'logic/apiCalls';

import css from './MobileRecorderEntries.module.scss';

type MobileRecorderEntriesProps = {
    mobileRecorders: MobileRecorder[], 
    handleOperationalStatusChange: (mobileRecorderToToggle: MobileRecorder) => void,    
}

export const MobileRecorderEntries = ({ mobileRecorders, handleOperationalStatusChange } : MobileRecorderEntriesProps) => {

    if (mobileRecorders.length === 0) {
        return <Alert className='my-3' color='warning'>
            No recorders are configured
        </Alert>
    }

    return (
        <div className={`${css.mobileRecorderTable} my-3`}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-8">Name</th>
                        <th className="col-2">Status</th>
                        <th className="col-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        mobileRecorders.map((value) => {
                            return <MobileRecorderEntry key={value.id} mobileRecorder={value} handleOperationalStatusChange={handleOperationalStatusChange} />
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}