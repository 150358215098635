import { Component, useContext } from 'react';
import { Alert, Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { MobileRecordedUserEntries } from 'components/MobileRecordedUserEntries';
import { ModalConfirm } from 'components/ModalConfirm';
import { DoneInfo, ModalMobileUserAddOrUpdate } from 'components/ModalMobileUserAddOrUpdate';
import { TenantSelection } from './TenantSelection';
import { Tenant, MobileRecordedUser } from 'logic/apiCalls';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

type TabMobileSettingsProvisioningInternalProps = {
    notificationContext: NotificationContextState
}

type TabMobileSettingsProvisioningInternalState = {
    isLoading: boolean,
    showApiError: boolean,
    isLoadingMobileRecordedUsers: boolean,
    showMobileRecordedUsersApiError: boolean,
    tenants: Tenant[],
    selectedTenantId: string | null,
    mobileRecordedUsers: MobileRecordedUser[],
    mobileRecordedUserToDelete: MobileRecordedUser | null,
    mobileRecordedUserToUpdate: MobileRecordedUser | null,
    isDeleteModalOpen: boolean,
    isAddOrUpdateModalOpen: boolean    
}

class TabMobileSettingsProvisioningInternal extends Component<TabMobileSettingsProvisioningInternalProps, TabMobileSettingsProvisioningInternalState> {
    state: TabMobileSettingsProvisioningInternalState = {
        isLoading: true,
        showApiError: false,
        isLoadingMobileRecordedUsers: false,
        showMobileRecordedUsersApiError: false,
        tenants: [],
        selectedTenantId: null,
        mobileRecordedUsers: [],
        mobileRecordedUserToDelete: null,
        mobileRecordedUserToUpdate: null,
        isDeleteModalOpen: false,
        isAddOrUpdateModalOpen: false
    }   

    async componentDidMount() {
        await this.loadPageData();
    }

    toggleDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    }

    toggleAddOrUpdateModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: !this.state.isAddOrUpdateModalOpen
        });
    }

    openAddModal = () => {
        this.setState({
            isAddOrUpdateModalOpen: true,
            mobileRecordedUserToUpdate: null
        });
    }

    openUpdateModal = (mobileRecordedUserToUpdate: MobileRecordedUser) => {
        this.setState({
            isAddOrUpdateModalOpen: true,
            mobileRecordedUserToUpdate: mobileRecordedUserToUpdate
        });
    }

    openDeleteModal = (mobileRecordedUserToDelete: MobileRecordedUser) => {
        this.setState({
            isDeleteModalOpen: true,
            mobileRecordedUserToDelete: mobileRecordedUserToDelete
        });
    }

    handleEditButtonClicked = (mobileRecordedUserToUpdate: MobileRecordedUser) => {
        this.openUpdateModal(mobileRecordedUserToUpdate);
    }

    handleDeleteButtonClicked = (mobileRecordedUserToDelete: MobileRecordedUser) => {
        this.openDeleteModal(mobileRecordedUserToDelete);
    }

    handleTenantSelectionChanged = async (tenantId: string | null) => {
        this.setState(
            {
                selectedTenantId: tenantId
            }
        );

        if (tenantId) {
            await this.loadMobileUsers(tenantId, false);
        } else {
            this.setState(
                {
                    mobileRecordedUsers: []
                }
            );
        }        
    }

    handleTenantSelectionError = (description: string, detail: string) => {
        this.props.notificationContext.setNotification(description, detail, 'danger');
    }

    loadPageData = async () => {
        try {
            const tenants = await apiCalls.getTenantsByDistributor();
            
            // Only the tenants with the Mobile connector should be displayed.
            const filteredTenants = tenants.filter(tenant => tenant.connectors.some(connector => connector.name === "Mobile" && connector.enabled));

            this.setState({
                tenants: filteredTenants,
                isLoading: false,
            });            
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    loadMobileUsers = async (tenantId: string, isUpdate: boolean) => {
        try {
            this.setState({                
                isLoadingMobileRecordedUsers: !isUpdate
            });

            const mobileRecordedUsers = await apiCalls.getMobileRecordedUsersByDistributor(tenantId);

            this.setState({
                isLoadingMobileRecordedUsers: false,
                showMobileRecordedUsersApiError: false,
                mobileRecordedUsers: mobileRecordedUsers
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoadingMobileRecordedUsers: false,             
                showMobileRecordedUsersApiError: true
            });
        }
    }

    executeAddOrUpdate = async (doneInfo: DoneInfo) => {
        log.debug(`[executeAddOrUpdate] Adding/updating mobile user ${doneInfo.phoneNumber}...`);

        const tenantId = this.state.selectedTenantId;
        if (!tenantId) {
            return;
        }

        try {
            if (doneInfo.isUpdate) {
                await apiCalls.updateMobileRecordedUserByDistributor(tenantId, doneInfo.phoneNumber, doneInfo.name, doneInfo.azureUserId, doneInfo.recorded);
            }
            else
            {
                var response = await apiCalls.queryMobilePhoneNumberInfo(doneInfo.phoneNumber);
                
                if (response.isUsed) {
                    this.props.notificationContext.setNotification("Error", `The provided mobile phone number is already in use by tenant '${response.usedByTenantName}' (${response.usedByTenantId}).`, 'danger');
                    return;
                }

                await apiCalls.addMobileRecordedUserByDistributor(tenantId, doneInfo.phoneNumber, doneInfo.name, doneInfo.azureUserId);
            }
            
            await this.loadMobileUsers(tenantId, doneInfo.isUpdate);

            const notificationText = doneInfo.isUpdate ? `Successfully updated phone number info.` : `Successfully added phone number.`;
            this.props.notificationContext.setNotification("Phone number configuration modified", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the phone number configuration.`, 'danger');
        }
        finally {
            this.toggleAddOrUpdateModal();
        }
    }

    executeDelete = async () => {
        const tenantId = this.state.selectedTenantId;
        if (!tenantId) {
            return;
        }

        const mobileRecordedUserToDelete = this.state.mobileRecordedUserToDelete;

        if (mobileRecordedUserToDelete == null) {
            return
        }

        try {     
            const phoneNumber = mobileRecordedUserToDelete.mobilePhoneNumber;
            const name = mobileRecordedUserToDelete.name;
    
            log.debug(`[executeDelete] Deleting mobile recorded user with phone number ${phoneNumber} (${name}) of tenant ${tenantId}...`);

            await apiCalls.deleteMobileRecordedUserByDistributor(tenantId, mobileRecordedUserToDelete.mobilePhoneNumber);
            await this.loadMobileUsers(tenantId, true);

            const notificationText = `Successfully deleted phone number.`;
            this.props.notificationContext.setNotification("Phone number deleted", notificationText, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during deleting of the phone number.`, 'danger');
        }
        finally {
            this.toggleDeleteModal();
        }
    }

    getSelectedTenant = () : Tenant | null => {
        if (!this.state.selectedTenantId) {
            return null;
        }
        
        const tenant = this.state.tenants.find(tenant => tenant.id === this.state.selectedTenantId);
        return tenant || null;
    }

    renderMobileRecorderUserSection = () => {
        if (!this.state.selectedTenantId) {
            return null;
        }

        return (
            <div className="my-5">                    
                    <Loader isLoading={this.state.isLoadingMobileRecordedUsers} showApiError={this.state.showMobileRecordedUsersApiError}>
                        <>
                            {this.renderDisabledTenantWarning()}
                            {this.renderMobileRecordedUserEntries()}
                            {this.renderAddButton()}
                        </>
                    </Loader>
            </div>            
        )        
    }

    renderAddButton = () => {
        return <div className="my-3">
            <Button className='mt-3' color='primary' onClick={this.openAddModal}>Add phone number</Button>
        </div>
    }

    renderDisabledTenantWarning = () => {
        const tenant = this.getSelectedTenant();

        if (!tenant || tenant.enabled) {
            return null;
        }
        
        return (
            <Alert color='danger'>This tenant is disabled. No mobile phone calls will be recorded.</Alert>
        )
    }

    renderMobileRecordedUserEntries = () => {
        
        if (this.state.mobileRecordedUsers.length > 0) {
            return <MobileRecordedUserEntries mobileUserInfos={this.state.mobileRecordedUsers} handleEdit={this.handleEditButtonClicked} handleDelete={this.handleDeleteButtonClicked} showDelete={true}/>
        }

        if (this.state.selectedTenantId) {
            return <Alert color='danger'>
                No phone numbers are provisioned for this tenant.
            </Alert>
        }

        return null;
    }

    renderDeleteModal = () => {
        const phoneNumberToDelete = this.state.mobileRecordedUserToDelete?.mobilePhoneNumber || '';

        return <ModalConfirm
            headerText="Confirm delete"
            detailedText={`Are you sure you want to delete the phonenumber '${phoneNumberToDelete}'?`}
            isOpen={this.state.isDeleteModalOpen}
            onToggle={this.toggleDeleteModal}
            onConfirm={this.executeDelete} /> 
    }
 
    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <>                
                    {this.renderDeleteModal()}
                    <ModalMobileUserAddOrUpdate isOpen={this.state.isAddOrUpdateModalOpen} mobileUserToUpdate={this.state.mobileRecordedUserToUpdate} allowUpdateRecorded={true} onToggle={this.toggleAddOrUpdateModal} onAddOrUpdate={this.executeAddOrUpdate}/>
                    <TenantSelection tenants={this.state.tenants} onTenantSelectionChanged={this.handleTenantSelectionChanged} onError={this.handleTenantSelectionError} />
                    <hr/>
                    {this.renderMobileRecorderUserSection()}
                </>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabMobileSettingsProvisioning = (props: any) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabMobileSettingsProvisioningInternal {...props} notificationContext={notificationContext} />
    )
}