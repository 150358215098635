import { Component } from 'react';
import { Alert, Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { triggerDownloadBlob } from 'logic/download';
import * as apiCalls from 'logic/apiCalls';
import { urls } from 'logic/urls';
import log from 'loglevel';

export class TabRecordingTeamsStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showApiError: false,
            recordingConfigurationCreated: false,
            recordingConfigurationUpToDate: false,
        }
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.forceRefresh !== this.props.forceRefresh) {
            await this.loadPageData();
        }
    }

    loadPageData = async () => {
        try {
            const globalRecordingConfigurationPromise = apiCalls.getTeamsGlobalRecordingConfiguration();            

            const [globalRecordingConfiguration] = await Promise.all([globalRecordingConfigurationPromise]);

            this.setState({
                recordingConfigurationCreated: globalRecordingConfiguration.recordingConfigurationCreated,
                recordingConfigurationUpToDate: globalRecordingConfiguration.recordingConfigurationUpToDate,                
                isLoading: false,
                showApiError: false              
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({                
                isLoading: false,
                showApiError: true
            });
        }
    }

    downloadConfigurationScript = async () => {
        try {
            const configurationScriptBlob = await apiCalls.getConfigurationScript();
            triggerDownloadBlob(configurationScriptBlob, 'TeamsConfiguration.ps1');
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);
        }
    }

    renderContent = () => {
        // Note: The check for undefined is added as a temporary fallback in the case the API has not been updated yet.
        if (this.state.recordingConfigurationCreated !== undefined && !this.state.recordingConfigurationCreated) {
            return (
                <div className="m-3">
                    <p />
                    <Alert color="warning">
                        No Teams recording has been configured yet. Please assign the groups to be recorded in the Groups tab.
                    </Alert>                    
                </div>
            )
        }
        
        if (!this.state.recordingConfigurationUpToDate) {
            return (
                <div className="m-3">
                    <p />
                    <Alert color="warning">
                        The Teams recording configuration in this portal is not in-sync with the configuration in the Teams environment.
                        Please download and execute the script in your Teams environment to apply the recording settings.
                    </Alert>
                    <Button className='mb-3' color='primary' onClick={this.downloadConfigurationScript}>Download</Button>
                    <p>For help on common issues with the execution of the PowerShell script click <a href={urls.powerShellKnowledgeBaseArticle} target="_blank" rel="noopener noreferrer">here</a>.</p>
                </div>
            )
        } 
        
        return (
            <div className="m-3">
                <p />
                <Alert color="success">
                    The Teams recording configuration configured in this portal is in-sync with the configuration in the Teams environment.
                </Alert>
            </div>
        )      
    }

    render() {
        return <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError} render={this.renderContent} />
    }
}