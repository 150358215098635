import moment from 'moment';
import { WarningIcon } from 'components/WarningIcon';
import { Subscription } from 'logic/apiCalls';

type SubscriptionEntryProps = {
    subscription: Subscription
}

export const SubscriptionEntry = ({ subscription } : SubscriptionEntryProps) => {
    const subscriptionStateDisplay = DetermineSubscriptionStateDisplayText(subscription.subscriptionState);
    const paymentPeriodEndDisplay = moment(subscription.paymentPeriodEnd).format('LL');

    return (
        <tr>
            <td className="align-middle">
                {subscription.name}
            </td>
            <td className="align-middle">
                {String(subscription.creationTime).split('T')[0]}
            </td>           
            <td className="align-middle">
                {subscription.planId}
            </td>
            <td className="align-middle">
                {subscription.autoRenew ? "Yes" : "No"}
                <WarningIcon id={`${subscription.name}-autoRenewWarning`} tooltip={`Subscription will become inactive at ${paymentPeriodEndDisplay}`} showWarning={!subscription.autoRenew} />                
            </td>      
            <td className="align-middle">
                {subscriptionStateDisplay}
            </td>
        </tr>
    )
}

const DetermineSubscriptionStateDisplayText = (subscriptionState: string) => {
    switch (subscriptionState.toLowerCase()) {
        case "notstarted":
        case "pendingfulfillmentstart":
            return "Waiting for activation";
        case "subscribed":
            return "Active";
        case "suspended":
            return "Suspended";
        default:
            return "Inactive";
    }
}