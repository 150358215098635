// This file contains the settings of the appConfig that needs to be overridden when the application is run in development.

export const appConfig: any = {
    clientId: '70af619e-612b-4d3d-8338-0a0a8ce5f178', // Management Portal App Id development
    //managementPortalApiAddress: 'http://localhost:9000', // Local Mock-API, started with 'yarn run dev'
    managementPortalApiAddress: 'https://localhost:44352', // Local Management Portal API running from Visual Studio
    //managementPortalApiAddress: 'https://app-attest-man-api-d01.azurewebsites.net', // Development Management Portal API running in the Azure cloud
    apiScopes: [
        'api://24edb518-dd89-4d93-ac4f-6653c7c66a4a/Content.All', // CyberTwice-Management-Api-d01
    ],
    skin: 'kpn'
};
