import { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { urls, urlCombine, getBaseUriWithoutParameters } from 'logic/urls';
import cx from 'classnames';

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import css from './UserLogin.module.scss';

export const UserLogin = () => {
    const userContext = useContext(UserContext);

    const userAccount = userContext?.getUserAccount();
    const username = userAccount ? userAccount.username : "Unknown";
    const displayname = userAccount ? userAccount.name : username;
    const profilePictureUrl = userContext?.profilePicture || urls.defaultProfilePicture;
    const additionalStyling = userContext?.profilePicture ? null : css.defaultIcon;

    const handleLogoutClick = () => {
        const redirectUrl = urlCombine(getBaseUriWithoutParameters(), urls.postLogout);
        userContext?.logout(redirectUrl);
    }

    return (
        <UncontrolledDropdown className={css.userLogin}>
            <DropdownToggle nav caret>
                <img className={cx(css.accountIcon, additionalStyling)} src={profilePictureUrl} alt="Profile avatar" />                
                {displayname}
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header >
                    <strong className="mb-0">{username}</strong>
                    <p className="text-muted mb-0">{displayname}</p>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleLogoutClick()}>Sign Out</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}