import { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { Jumbotron, Alert } from 'reactstrap';
import { PublicLayout } from '../public/PublicLayout';
import { UserContext, UserContextState } from 'context/UserContext';
import { getQueryVariableFromUrl } from 'logic/urls';
import { adminConsent } from 'logic/adminConsent';
import { urls } from 'logic/urls';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import * as apiCalls from 'logic/apiCalls';

// ToDo: Move some of the state to localvariables for which no re-render is required.

type PostConsentInternalProps = {
    tenantContext: TenantContextState,
    userContext: UserContextState
    notificationContext: NotificationContextState
}

type PostConsentInternalState = {
    errorCode: string,
    errorDescription: string,
    isStateVerified: boolean,
    isStateMatching: boolean,
    consentedType: string,
    isTenantVerified: boolean,
    isTenantMatching: boolean,
    isPersisting: boolean,
    isPersisted: boolean,
    isWaitPeriodExpired: boolean,
    waitPeriodTimerCount: number
}

// This component is the landing page from the redirect back from the admin-consent page of Microsoft.
// The admin-consent endpoint of Azure AD does not provide authentication information, but only an indication if the consent was provided or not.
// Example of failure to provide admin consent:
// http://localhost:3000/post-consent?error=access_denied&error_description=AADSTS65004%3a+User+declined+to+consent+to+access+the+app.%0d%0aTrace+ID%3a+80051703-4180-441f-bb26-377b775f4c02%0d%0aCorrelation+ID%3a+9717b7d1-d30e-4bc5-b5f5-be525b1120f6%0d%0aTimestamp%3a+2020-06-19+09%3a26%3a59Z&error_uri=https%3a%2f%2flogin.microsoftonline.com%2ferror%3fcode%3d65004&admin_consent=True&state=LDRDEMF27QEVFT6UYI9J%7d
// Example of successfully providing admin consent:
// http://localhost:3000/post-consent?admin_consent=True&tenant=4507b4d5-9b9b-4b6f-b848-bd21b7aa1667&state=LDRDEMF27QEVFT6UYI9J%7d
export class PostConsentInternal extends Component<PostConsentInternalProps, PostConsentInternalState>  {
    waitPeriodTimerId: NodeJS.Timeout | undefined;
    

    state: PostConsentInternalState = {
        errorCode: "",
        errorDescription: "",
        isStateVerified: false,
        isStateMatching: false,
        consentedType: "",
        isTenantVerified: false,
        isTenantMatching: false,
        isPersisting: false,
        isPersisted: false,
        isWaitPeriodExpired: false,
        waitPeriodTimerCount: 30
    }

    componentDidMount() {
        this.verifyError();
        this.verifyState();
        this.verifyTenant(); 
        this.saveAdminConsentGrantedIfValid();
    }

    componentWillUnmount() {
        clearTimeout(this.waitPeriodTimerId);
    }

    componentDidUpdate() {
        this.verifyTenant();
        this.saveAdminConsentGrantedIfValid();
    }

    saveAdminConsentGrantedIfValid = async () => {
        if (this.state.errorCode === "" &&
            this.state.isStateMatching &&
            this.state.isTenantMatching &&
            this.props.userContext.isAccessAllowed() &&
            !this.state.isPersisting &&
            !this.state.isPersisted) {

            this.setState({
                isPersisting: true
            });

            try {
                await apiCalls.setAdminConsent(this.state.consentedType);

                this.props.notificationContext.setNotification("Admin consent", "Admin consent has successfully been granted.", "success");

                this.setState({
                    isPersisting: false,
                    isPersisted : true
                });

                this.waitPeriodTimerId = setTimeout(() => {
                    this.reportWaitPeriod();
                }, 1000);
            }
            catch (error) {
                this.setState({
                    errorCode: "Error saving changes in back-end",
                    errorDescription: "Could not save the changes in the back-end, please try again later."
                });
            }
        }
    }

    reportWaitPeriod = () => {
        if (this.state.waitPeriodTimerCount > 0) {
            this.waitPeriodTimerId = setTimeout(() => {
                this.reportWaitPeriod();
            }, 1000);

            this.setState({
                waitPeriodTimerCount: this.state.waitPeriodTimerCount - 1
            });

            return;
        }

        // Trigger the TenantContext to query the back-end again to request the current state of the
        // admin consent which should now be set because of the update just performed.
        this.props.tenantContext.verifyAdminConsent()

        this.setState({
            isWaitPeriodExpired: true
        });
    }

    // Method verifies if the tenant that has provided the consent is the tenant that is currently logged in.
    verifyTenant = () => {
        if (!this.state.isTenantVerified && // Prevent an endless loop when the state is updated from the componentDidUpdate method.
            !this.props.userContext.isAuthorizationInProgress() && 
            this.props.userContext.isAccessAllowed()) {    

            const consentedTenantId = getQueryVariableFromUrl('tenant');

            // User has succesfully been authenticated and authorized.
            // Now check if the tenant that has provided the consent (has been passed in the URL),
            // matches the logged-in tenant.
            const userAccount =  this.props.userContext.getUserAccount();
            if (!userAccount) {
                return;
            }

            const loggedInTenantId = userAccount.tenantId;

            this.setState({
                isTenantVerified: true,
                isTenantMatching: (loggedInTenantId === consentedTenantId)
            });            
        }
    }

    // Method verifies if the state that has been received in the URL matches the state that has been stored before
    // the user was redirected to Microsoft.
    verifyState = () => {
        if (!this.state.isStateVerified) {
            const stateFromUrl = getQueryVariableFromUrl('state') || "";
            const isStateMatching = adminConsent.verifyState(stateFromUrl);
            const consentedType = isStateMatching ? adminConsent.getConsentedType(stateFromUrl) : "";
    
            this.setState({
                isStateVerified: true,
                isStateMatching: isStateMatching,
                consentedType: consentedType
            });
        }        
    }

    // Method verifies if an error has occurred during the consent process.
    verifyError = () => {
        const errorCodeFromUrl = getQueryVariableFromUrl('error');
        const errorDescription = getQueryVariableFromUrl('error_description') || "";
        
        if (errorCodeFromUrl) {
            this.setState({
                errorCode: errorCodeFromUrl,
                errorDescription: errorDescription.replace(/\+/g, ' ') // Note: In the description received from Azure the spaces are replaced with + characters.
            });
        }        
    }

    render () {
        const userContext = this.props.userContext;

        if (this.state.errorCode) {
            return <PublicLayout>
                <Jumbotron className="text-center">
                    <Alert color='danger'>
                        <h1>Error providing consent</h1>
                        <p>Error code: <pre>{this.state.errorCode}</pre></p>
                        <p>Error description: <pre>{this.state.errorDescription}</pre></p>
                    </Alert>
                </Jumbotron>
            </PublicLayout>
        }

        if (!this.state.isStateVerified) {
            return <div>Waiting for state verification to complete...</div>
        }

        if (!this.state.isStateMatching) {
            return <PublicLayout>
                <Jumbotron className="text-center">
                    <Alert color='danger'>
                        <h1>Error providing consent</h1>
                        <p>State mismatch detected. Start the admin consent process from the admin portal to avoid this error.</p>
                    </Alert>
                </Jumbotron>
            </PublicLayout>
        }

        if (userContext.isAuthorizationInProgress()) {
            return <div>Waiting for authorization to complete...</div>
        }

        if (!userContext.isAccessAllowed()) {
            return <PublicLayout>
                <Jumbotron className="text-center">
                    <Alert color='danger'>
                        <h1>Error providing consent</h1>
                        <p>The admin consent should be provided from within the managent portal. Please login to the portal and start the admin consent from the Consent page.</p>
                    </Alert>                    
                </Jumbotron>
            </PublicLayout>
        }

        if (!this.state.isTenantVerified) {
            return <div>Waiting for tenant verification to complete...</div>
        }

        if (!this.state.isTenantMatching) {
            return <PublicLayout>
                <Jumbotron className="text-center">
                    <Alert color='danger'>
                        <h1>Error providing consent</h1>
                        <p>The tenant that provides the consent should match the tenant that is currently logged in.</p>
                    </Alert>
                </Jumbotron>
            </PublicLayout>
        }

        if (!this.state.isWaitPeriodExpired)
        {
            return <PublicLayout>
                <Jumbotron className="text-center">
                    <Alert color='success'>
                        <h1>Admin consent granted</h1>
                        <h6>The administration is being updated. Waiting for {this.state.waitPeriodTimerCount}s</h6>
                    </Alert>
                </Jumbotron>
            </PublicLayout>
        }

        if (this.state.isPersisted && this.state.isWaitPeriodExpired) {
            // All checks have passed, the user is now automatically redirected back to the portal.
            return (
                <Redirect to={urls.initialPortal}/>
            );
        }

        return <div>Waiting for updating the administration...</div>;        
  }
}

// Inject the required contexts.
export const PostConsent = () => {
    const tenantContext = useContext(TenantContext) as TenantContextState;
    const userContext = useContext(UserContext) as UserContextState;
    const notificationContext = useContext(NotificationContext) as NotificationContextState;

    return (
        <PostConsentInternal tenantContext={tenantContext} userContext={userContext} notificationContext={notificationContext} />
    )
}