import React from 'react';
import { DeleteButton } from 'components/DeleteButton';
import { EditButton } from 'components/EditButton';
import { Tenant } from 'logic/apiCalls';
import * as misc from 'utils/misc';

import css from './TenantEntry.module.scss';

type TenantEntryProps = {
    tenant: Tenant,
    showDeleteButton: boolean,
    handleEdit: (tenantToUpdate: Tenant) => void,
    handleDelete: (tenantToDelete: Tenant) => void
}

export const TenantEntry = ({ tenant, showDeleteButton, handleEdit, handleDelete } : TenantEntryProps) => {
    return (
        <tr key={tenant.id} className={`d-flex ${tenant.enabled ? "" : css.disabledTenant}`}>
            <td className="col-3">{tenant.label}</td>            
            <td className="col-3">{tenant.id}</td>        
            <td className="col-2">{tenant.reference}</td>
            <td className="col-2">{misc.secondsToDisplayString(tenant.defaultRetentionSeconds)}</td>
            <td className="col-1">{tenant.isTranscriptionEnabled ? "Yes" : "No"}</td>
            <td className="col-1">
                { showDeleteButton && <DeleteButton className='float-right mr-3' onClick={() => handleDelete(tenant)}/> }
                <EditButton className='float-right mr-3' onClick={() => handleEdit(tenant)}/>
            </td>
        </tr>
    )
}