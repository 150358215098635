import { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import { PublicLayout } from '../public/PublicLayout';

export class UserUnauthorized extends Component {
    render() {
        return (
            <PublicLayout>
                <Jumbotron className="text-center">
                    <p><strong>You are not authorized to use this application. The 'Global', 'Application', 'Cloud Application' or 'Teams' administrator role is required for access.</strong></p>
                </Jumbotron>
            </PublicLayout>
        )
    }
}