import { Component, useContext } from 'react';
import { Button, CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { ModalConfirm } from 'components/ModalConfirm';
import { Loader } from 'components/Loader';
import { oneWayCompare } from 'utils/misc';
import { NotificationContext } from 'context/NotificationContext';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

class TabRecordingTeamsGlobalInternal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showApiError: false,            
            captureVideo: false,
            captureScreenSharing: false,
            stateInBackend: {},
            isConfirmSaveModalOpen: false,
        }       
    }

    async componentDidMount() {
        await this.loadPageData();
    }    

    loadPageData = async () => {
        try {
            const globalTeamsRecordingConfigurationPromise = apiCalls.getTeamsGlobalRecordingConfiguration();

            const [globalTeamsRecordingConfiguration] = await Promise.all([globalTeamsRecordingConfigurationPromise]);

            this.setState({
                ...this.extractStateFromData(globalTeamsRecordingConfiguration),
                stateInBackend: this.extractStateFromData(globalTeamsRecordingConfiguration),
                isLoading: false,
                showApiError: false
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    /** Method returns a flat object from the hierarchical data received from the back-end. */
    extractStateFromData = (globalTeamsRecordingConfiguration) => {
        return {
            captureVideo: globalTeamsRecordingConfiguration.captureVideo,
            captureScreenSharing: globalTeamsRecordingConfiguration.captureScreenSharing 
        }
    }
    
    /** Method returns the hierarchical data to be sent to the back-end based upon the current flat state. */
    constructDataFromState = () => {
        return {           
            captureVideo: this.state.captureVideo,
            captureScreenSharing: this.state.captureScreenSharing           
        }
    }

    toggleConfirmSaveModal = () => {
        this.setState({
            isConfirmSaveModalOpen: !this.state.isConfirmSaveModalOpen
        });
    }

    toggleCaptureVideo = () => {
        this.setState(
            {
                captureVideo: !this.state.captureVideo
            }
        );
    }

    toggleCaptureScreenSharing = () => {
        this.setState(
            {
                captureScreenSharing: !this.state.captureScreenSharing
            }
        );
    }

    isSaveButtonEnabled = () => {
        return !oneWayCompare(this.state.stateInBackend, this.state);
    }

    handleSaveButtonClick = () => {
        this.toggleConfirmSaveModal();
    }

    executeSave = async () => {
        log.debug(`[executeSave] Saving the global Teams settings...`);

        try {
            const teamsGlobalConfiguration = this.constructDataFromState();
            await apiCalls.updateTeamsGlobalRecordingConfiguration(teamsGlobalConfiguration);
            await this.loadPageData();
            
            this.props.notificationContext.setNotification("Recording configuration saved", `Successfully updated the global Teams recording configuration.`, 'success');
        }
        catch (error)
        {
            log.error(`[executeSave] Error during save: ${error}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        finally {
            this.toggleConfirmSaveModal();            
        }
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <div className="m-3">
                    <ModalConfirm
                        headerText="Confirm save"
                        detailedText="Are you sure you want to update the tenant wide Teams recording configuration?"
                        isOpen={this.state.isConfirmSaveModalOpen}
                        onToggle={this.toggleConfirmSaveModal}
                        onConfirm={this.executeSave} />

                    <div className="mt-3 mb-5">
                        <Form>
                            <FormGroup>
                                <Label for="captureVideoSwitch"><strong>Video</strong></Label>
                                <div className="mt-3">
                                    <CustomInput checked={this.state.captureVideo} type="switch" onChange={this.toggleCaptureVideo} id="captureVideoSwitch" label="Capture video" />
                                    <CustomInput checked={this.state.captureScreenSharing} type="switch" onChange={this.toggleCaptureScreenSharing} id="captureScreensharingSwitch" label="Capture screensharing" />
                                </div>
                            </FormGroup>
                        </Form>
                    </div>
                    <Button disabled={!this.isSaveButtonEnabled()} color='primary' onClick={this.handleSaveButtonClick}>Save</Button>
                </div>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabRecordingTeamsGlobal = (props) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabRecordingTeamsGlobalInternal {...props} notificationContext={notificationContext} />
    )
}