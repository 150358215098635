import { Component, createContext } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { authentication, authenticationStates } from 'logic/authentication';
import { authorization, authorizationStates } from 'logic/authorization';
import { getProfilePictureObjectUrl } from 'logic/graphCalls';
import log from 'loglevel';

export { authenticationStates, authorizationStates }

export type UserContextState = {
    userName: string,
    authenticationState: string,
    authorizationState: string,
    profilePicture: string | null,
    isAuthenticated: () => boolean,
    isAuthenticationInProgress: () => boolean,
    isAuthorizationInProgress: () => boolean,
    isAuthorizationDone: () => boolean,

    isTenantKnown: () => boolean,
    isTenantEnabled: () => boolean,
    isTenantAuthorized: () => boolean,
    isAccessAllowed: () => boolean,
    getLastAuthenticationErrorMessage: () => string | null,
    getUserAccount: () => AccountInfo | null, 
    isDistributor: () => boolean,
    triggerAuthorize: () => Promise<void>
    login: (contextUsername: string | null, postRedirectUrl: string) => void,
    logout: (redirectUrl: string) => void
}

export const UserContext = createContext<UserContextState | null>(null);

type UserContextProps = {
    children: React.ReactNode
}

// Context is an adapter between the plain vanilla Javascript modules for the authentication/authorization 
// and the React environment which triggers rendering on state changes.
// This context also acts as a facade to hide the complexity of the underlying code. React components only need
// to access this context for all authentication and authorization related functionality.
export class UserContextProvider extends Component<UserContextProps, {}> {
    state: UserContextState = {
        userName: "",
        authenticationState: authentication.getAuthenticationState(),
        authorizationState: authorization.getAuthorizationState(),
        profilePicture: null,
        isAuthenticated: () => this.isAuthenticated(),
        isAuthenticationInProgress: () => this.isAuthenticationInProgress(),
        isAuthorizationInProgress: () => this.isAuthorizationInProgress(),
        isAuthorizationDone: () => this.isAuthorizationDone(),

        isTenantKnown: authorization.isTenantKnown,
        isTenantEnabled: authorization.isTenantEnabled,
        isTenantAuthorized: authorization.isTenantAuthorized,
        isAccessAllowed: authorization.isAccessAllowed,            
        getLastAuthenticationErrorMessage: authentication.getLastAuthenticationErrorMessage,
        getUserAccount: authentication.getUserAccount, 
        isDistributor: authorization.isDistributor,
        triggerAuthorize: authorization.triggerAuthorize,
        login: authentication.login,
        logout: authentication.logout,
    }

    componentDidMount() {
        authentication.registerAuthenticationStateChangeCallback(this.handleAuthenticationStateUpdate);
        authorization.registerAuthorizationStateChangeCallback(this.handleAuthorizationStateUpdate);
    }

    componentWillUnmount() {
        authentication.unregisterAuthenticationStateChangeCallback(this.handleAuthenticationStateUpdate);
        authorization.unregisterAuthorizationStateChangeCallback(this.handleAuthorizationStateUpdate);
    }
    
    handleAuthenticationStateUpdate = (newState: string) => {
        log.debug(`[UserContextProvider, handleAuthenticationStateUpdate] New state: ${newState}`);

        this.setState({ authenticationState: newState });

        if (newState === authenticationStates.AUTHENTICATED) {
            this.tryDownloadProfilePicture();
        }
    }

    handleAuthorizationStateUpdate = (newState: string) => {
        log.debug(`[UserContextProvider, handleAuthorizationStateUpdate] New state: ${newState}`);

        // Retrieve the latest value of the authentication state, because this authorization state change
        // might have been triggered by a change of the authentication state which has not yet been reported via the
        // handleAuthenticationStateUpdate callback handler, which could result in an illegal combination
        // of authentication and authorization state.
        const authenticationState = authentication.getAuthenticationState();

        this.setState({ 
            authenticationState: authenticationState,
            authorizationState: newState 
        });
    }

    // Convenience method to easily determine if the current user is authenticated.
    isAuthenticated = () => {
        return this.state.authenticationState === authenticationStates.AUTHENTICATED;
    }

    // Convience method to easily determine if the authorization is in progress.
    isAuthenticationInProgress = () => {
        return this.state.authenticationState === authenticationStates.AUTHENTICATING;
    }

    isAuthorizationInProgress = () => {
        const authenticationState = this.state.authenticationState;
    
        // Check if the authentication step is still in progress...
        if (authenticationState === authenticationStates.AUTHENTICATING) {
            return true;
        }
    
        // The authentication is not in progress (so either not started, failed, or successful),
        // check if the authorization is in progress...
        if (authenticationState === authenticationStates.AUTHENTICATED &&
            (this.state.authorizationState === authorizationStates.UNDETERMINED || // Authorization will start soon.
             this.state.authorizationState === authorizationStates.AUTHORIZING)) {
            return true;    
        }
    
        // In all other situations the authorization is not in progress.
        return false;
    }
    
    isAuthorizationDone = () => {
        return this.state.authorizationState === authorizationStates.AUTHORIZATION_DONE;
    }

    tryDownloadProfilePicture = async () => {
        try {
            const imageUrl = await getProfilePictureObjectUrl();

            this.setState({
                profilePicture: imageUrl
            });            
        }
        catch (error: any)
        {
            console.error('Could not download the profile picture: ' + error.message);
        }
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}