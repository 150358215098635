import { Component } from 'react';
import Select, { SingleValue }  from 'react-select'
import { Button, Input } from 'reactstrap';
import { Row, Col } from 'react-bootstrap';
import { Tenant } from 'logic/apiCalls';
import { SelectEntry, TenantEntry, TenantSelect } from 'components/TenantSelect';

import * as validation from 'utils/validation';
import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

import css from './TenantSelection.module.scss';


type QueryTypeEntry = SelectEntry;

type OnTenantSelectionChangedCallback = (tenantId: string | null) => void;

type onErrorCallback = (description: string, detail: string) => void;

type TenantSelectionProps = {
    tenants: Tenant[],
    onTenantSelectionChanged: OnTenantSelectionChangedCallback,
    onError: onErrorCallback
}

type TenantSelectionState = {
    selectedQueryTypeEntry: QueryTypeEntry,
    selectedTenantEntry: TenantEntry | null,
    phoneNumber: string,
    tenantNotFoundText: string | null,
    tenantFoundText: string | null,
    tenantEntries: TenantEntry[]    
}

const queryTypes: QueryTypeEntry[] = [
    {
        label: "By name",
        value: "Name"
    },
    {
        label: "By phone number",
        value: "PhoneNumber"
    },
]

export class TenantSelection extends Component<TenantSelectionProps, TenantSelectionState> {
    state: TenantSelectionState = {
        selectedQueryTypeEntry: queryTypes[0],
        selectedTenantEntry: null,
        phoneNumber: "",
        tenantNotFoundText: null,
        tenantFoundText: null,
        tenantEntries: []
    }   

    handleSelectedQueryTypeChange = async (selectedQueryTypeEntry: SingleValue<QueryTypeEntry>) => {
        if (selectedQueryTypeEntry?.value === this.state.selectedQueryTypeEntry?.value || !selectedQueryTypeEntry)
        {
            return; // Selection has not changed.
        }

        this.setState({
            selectedQueryTypeEntry: selectedQueryTypeEntry,
            selectedTenantEntry: null,
            phoneNumber: "",
            tenantNotFoundText: null,
            tenantFoundText: null
        })

        this.props.onTenantSelectionChanged(null);
    }

    handleSelectedTenantEntryChange = async (selectedTenantEntry: SingleValue<TenantEntry>) => {
        if (!selectedTenantEntry?.value || selectedTenantEntry?.value === this.state.selectedTenantEntry?.value)
        {
            return; // Selection has not changed.
        }

        this.setState({
            selectedTenantEntry: selectedTenantEntry
        })

        this.props.onTenantSelectionChanged(selectedTenantEntry.value);
    }

    handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0 && !validation.isPartialDutchMobilePhoneNumber(e.target.value)) {
            return;
        }

        this.setState({
            phoneNumber: e.target.value,
            tenantNotFoundText: null,
            tenantFoundText: null
        })

        this.props.onTenantSelectionChanged(null);
    }

    shouldSearchButtonBeEnabled = () => {
        return validation.isDutchMobilePhoneNumber(this.state.phoneNumber);
    }

    executePhoneNumberSearch = async () => {
        try {
            const phoneNumberInfo = await apiCalls.queryMobilePhoneNumberInfo(this.state.phoneNumber);

            if (!phoneNumberInfo.isUsed) {
                this.setState(
                    {
                        tenantNotFoundText: "Provided phone number is not used by any tenant."
                    }
                )

                return;
            } else {
                this.setState(
                    {
                        tenantFoundText: `${phoneNumberInfo.usedByTenantName} (${phoneNumberInfo.usedByTenantId})`,
                    }
                )

                this.props.onTenantSelectionChanged(phoneNumberInfo.usedByTenantId);                
            }
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.onError("Error", `Error during execution of the phone number search.`);            
        }       
    }

    renderQueryTypeSelectionSection = () => {
        return <>
            <div className="my-3">Choose how to select the tenant:</div>
            <div>
                <Select className={css.rangeSelect} isSearchable={true} value={this.state.selectedQueryTypeEntry} options={queryTypes} onChange={this.handleSelectedQueryTypeChange}/>
            </div>            
        </>
    }

    renderTenantSelectionSection = () => {
        return <>
            <div className="my-3">Please select the tenant:</div>
            <div>
                <TenantSelect 
                    className={css.rangeSelect} 
                    placeholder='Select tenant...'
                    isClearable={false} 
                    tenants={this.props.tenants}
                    onTenantSelectionChanged={this.handleSelectedTenantEntryChange} />
            </div>
        </>
    }

    renderPhoneNumberSearch = () => {
        return <>
            <div className="my-3">Please enter phone number:</div>
            <Row>
                <Col sm={3}>
                    <Input className={css.rangeSelect} type="text" placeholder="+31612345678" value={this.state.phoneNumber} onChange={this.handlePhoneNumberChange}/>
                </Col>
                <Col>
                    <Button disabled={!this.shouldSearchButtonBeEnabled()} color="primary" onClick={this.executePhoneNumberSearch} >Search</Button>           
                </Col>
            </Row>
            <div className="my-4">
                {this.state.tenantNotFoundText && <div className={`my-3 ${css.warningMessage}`}>{this.state.tenantNotFoundText}</div>}
                {this.state.tenantFoundText && <div className={`my-3 ${css.tenantFoundMessage}`}>{this.state.tenantFoundText}</div>}
            </div>
        </>
    }
   
    render() {
        return (
            <>
                {this.renderQueryTypeSelectionSection()}
                {
                    this.state.selectedQueryTypeEntry.value === "Name" ?
                        this.renderTenantSelectionSection() :
                        this.renderPhoneNumberSearch()
                }
            </>
        )
    }
}