import { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { TenantEntry, TenantSelect } from 'components/TenantSelect';
import { Loader } from 'components/Loader';

import { Tenant } from 'logic/apiCalls';
import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

import "react-datepicker/dist/react-datepicker.css";
import css from './Filters.module.scss';

type OnApplyCallback = (filterInfo: FilterInfo) => void;

type FiltersProps = {
    onApply: OnApplyCallback
}

type FiltersState = {
    isLoading: boolean,
    showApiError: boolean,
    startDate: Date,    
    tenants: Tenant[],
    selectedTenantEntry: TenantEntry | null    
}

export type FilterInfo = {
    startDate: Date,
    tenant: TenantEntry
}

export class Filters extends Component<FiltersProps, FiltersState> {
    state: FiltersState = {
        isLoading: true,
        showApiError: false,
        startDate: new Date(),
        tenants: [],
        selectedTenantEntry: null,
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    loadPageData = async () => {
        try {
            const tenantsPromise = apiCalls.getTenantsByDistributor();
            const tenantsWithAuditTrailPromise = apiCalls.getAuditTrailTenants();

            const [tenants, tenantsWithAuditTrail] = await Promise.all([tenantsPromise, tenantsWithAuditTrailPromise]);
            const filteredTenants = tenants.filter(tenant => tenantsWithAuditTrail.includes(tenant.id));

            this.setState({
                tenants: filteredTenants,
                isLoading: false,
            });            
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    setStartDate = (date: Date | null) => {
        if (date === null) 
        {
            return;
        }
        
        this.setState({
            startDate: date
        });
    }

    handleSelectedTenantEntryChange = async (selectedTenantEntry: TenantEntry | null) => {
        this.setState({
            selectedTenantEntry: selectedTenantEntry
        })        
    }

    handleApplyClick = async () => {
        if (!this.state.selectedTenantEntry) {
            return;
        }

        const filterInfo: FilterInfo = {
            startDate: this.state.startDate,
            tenant: this.state.selectedTenantEntry
        }

        this.props.onApply(filterInfo);
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <Form.Group className='my-5' controlId="fromDate">
                    <Row>
                        <Col sm={2}>
                            <Form.Label>Date</Form.Label>
                        </Col>
                        <Col sm={3} className={css.alignmentFix}>
                            <DatePicker
                                showIcon
                                minDate={new Date(2023, 0, 1)}
                                maxDate={new Date()}
                                showTimeSelect
                                timeFormat="HH:mm"
                                selected={this.state.startDate} 
                                onChange={(date) => this.setStartDate(date)} 
                                dateFormat={"yyyy-MM-dd HH:mm"}
                                strictParsing
                                />
                        </Col>
                    </Row>    
                    <Row className='my-3'>
                        <Col sm={2}>
                            <Form.Label>Tenant</Form.Label>
                        </Col>
                        <Col sm={2}>
                            <TenantSelect
                                className={css.rangeSelect}
                                placeholder='Select tenant...'
                                isClearable={true}
                                tenants={this.state.tenants}
                                onTenantSelectionChanged={this.handleSelectedTenantEntryChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className='mt-3' color='primary' disabled={!this.state.selectedTenantEntry} onClick={this.handleApplyClick}>Apply filter</Button>
                        </Col>
                    </Row>     
                </Form.Group>
            </Loader>
        );
    }
}