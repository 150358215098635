import { Component } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { TabReplayGlobal } from './Global/TabReplayGlobal';
import { TabReplayGroups } from './Groups/TabReplayGroups';
import { TabHeader } from '../TabHeader';

type ReplayState = {
    forceRefresh : number
}

export class Replay extends Component<{}, ReplayState> {
    state: ReplayState = {
        forceRefresh: 0
    }

    handleGroupSettingsOnChange = () => {
        this.setState({
            forceRefresh: (this.state.forceRefresh + 1)
        });
    }

    render() {
        const tabInfos = [
            { name: "Groups", tabId: "0" },
            { name: "Global", tabId: "1" }
        ]

        return (
            <>
                <h1>Replay settings</h1>

                <TabHeader tabInfos={tabInfos}>   
                    <TabContent>
                        <TabPane tabId="0">
                            <TabReplayGroups />
                        </TabPane>
                        <TabPane tabId="1">
                            <TabReplayGlobal />
                        </TabPane>
                    </TabContent>    
                </TabHeader>           
            </>
        );
    }
}