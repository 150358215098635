import { Table, Alert } from 'reactstrap';
import { ClientCertificateFingerprintEntry }  from './ClientCertificateFingerprintEntry'

import css from './ClientCertificateFingerprintEntries.module.scss';

type ClientCertificateFingerprintEntriesProps = {
    fingerprints: string[], 
    handleDelete: (fingerprint: string) => void,
    showDelete: boolean
}

export const ClientCertificateFingerprintEntries = ({ fingerprints, handleDelete, showDelete } : ClientCertificateFingerprintEntriesProps) => {

    if (fingerprints.length === 0) {
        return <Alert color='warning'>
            No fingerprints are configured
        </Alert>
    }

    return (
        <div className={css.fingerprintTable}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-10">Fingerprint</th>
                        <th className="col-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fingerprints.map((value) => {
                            return <ClientCertificateFingerprintEntry key={value} fingerprint={value} handleDelete={handleDelete} showDelete={showDelete}/>
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}