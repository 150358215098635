import { SubscriptionEntries } from './SubscriptionEntries';
import { Subscription } from 'logic/apiCalls';
import css from './SubscriptionEntriesWithHeader.module.scss';

type SubscriptionEntriesWithHeaderProps = {
    headerName: string,
    subscriptions: Subscription[],
    showInfo?: boolean
}

export const SubscriptionEntriesWithHeader = ({ headerName, subscriptions, showInfo } : SubscriptionEntriesWithHeaderProps) => {
    if (subscriptions.length === 0) {
        return <></>
    }

    return (
        <div className={css.subscription}>
            <h2>{headerName}</h2>
            <SubscriptionEntries subscriptions={subscriptions} showInfo={showInfo}/>
        </div>
    );
}