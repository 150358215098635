import { authentication } from './authentication';
import * as graph from '@microsoft/microsoft-graph-client';
import * as misc from 'utils/misc'
import log from 'loglevel';

export async function getProfilePictureObjectUrl() {
    const client = await getGraphClientOrThrow();
    const result = await client.api('me/photo/$value').get();

    return URL.createObjectURL(result);
}

export async function getUserDetails() {
    const client = await getGraphClientOrThrow();
    const result = await client.api('/me').get();
    return result;
}

export async function getOrganizationDetails() {
    const client = await getGraphClientOrThrow();
    const result = await client.api('/organization').get();
    return result.value[0];
}

// When the Graph API is accessed directly after a user has provided consent for the application, 
// it happens often that these calls fails because these access rights are not yet distributed throughout Microsoft.
// This method will invoke the provided method until either no exception has occurred or the maximum number of attempts
// has been reached.
export async function invokeMethodWithRetry(method: Function, maxAttempts: number, intervalMs: number) {
    let attempt = 0;

    while (attempt < maxAttempts) {
        try {
           return await method();
        }
        catch (error: any) {
            if (attempt + 1 === maxAttempts) {
                throw error;
            }

            log.info(`Error while retrieving the details, attempt ${attempt + 1}`);
            await misc.sleep(intervalMs)
        }

        attempt++;       
    }
}

async function getGraphClientOrThrow() {
    const accessToken = await authentication.getGraphTokenAsync();

    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        },
        defaultVersion: 'beta'
    });

    return client;
}