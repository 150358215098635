import { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { ChartPie } from 'components/ChartPie';
import { ChartBar } from 'components/ChartBar';
import { Loader } from 'components/Loader';
import * as apiCalls from 'logic/apiCalls';
import * as chartLogic from 'logic/chartLogic';
import cx from 'classnames';
import Select from 'react-select'
import log from 'loglevel';
import css from './TabDashboardOverview.module.scss';

const rangeOptions = [
    { value: "full", label: "All", resolution: "all", stacked: false },
    { value: "lastYear", label: "Last year", resolution: "month", stacked: false },
    { value: "lastMonth", label: "Last month", resolution: "day", stacked: true },
    { value: "lastWeek", label: "Last week", resolution: "day", stacked: false },
    { value: "lastDay", label: "Last day", resolution: "hour", stacked:  true },
]

export class TabDashboardOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isUpdating: false,
            showApiError: false,
            selectedGroupOption: rangeOptions[3],
            selectedRange: rangeOptions[3].value,
            resolution: rangeOptions[3].resolution,
            showStacked: rangeOptions[3].stacked
        };

        this.totalRecordingVolume = {};
        this.volumePerSourceType = {};
        this.callsPerUser = {};
        this.direction = {};        
    }

    async componentDidMount() {
        try {
            const statisticsPromise = apiCalls.getStatistics(this.state.selectedRange);

            const [statistics] = await Promise.all([statisticsPromise]);

            this.processStatistics(statistics);            

            this.setState({                             
                isLoading: false,
                showApiError: false
            });            
        }
        catch (error) {
            log.error(`Error during retrieval of the statistics: ${error}`);

            this.setState({                             
                isLoading: false,
                showApiError: true
            });
        }
    }

    processStatistics = (statistics) => {
        this.totalRecordingVolume = chartLogic.getTotalRecordingVolume(statistics, this.state.resolution, this.state.selectedRange);
        this.volumePerSourceType = chartLogic.getVolumePerSourceType(statistics, this.state.resolution, this.state.selectedRange);
        this.callsPerUser = chartLogic.getCallsPerUser(statistics);
        this.direction = chartLogic.getDirection(statistics);        
    }

    handleRangeChange = async (e) => {
        try {
            this.setState({
                selectedGroupOption: e,
                selectedRange: e.value,
                resolution: e.resolution,
                showStacked: e.stacked,
                isUpdating: true,
                showApiError: false             
            });
            
            const statistics =  await apiCalls.getStatistics(e.value);            

            this.processStatistics(statistics);

            this.setState({
                isUpdating: false                
            });
        }
        catch (error) {
            log.error(`Error during updating of the statistics: ${error}`);

            this.setState({                             
                isUpdating: false,
                showApiError: true
            });
        }
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <div className={css.tabDashboardOverview}>
                    <div className={css.rangeSelect} >
                        <Select className="my-3" inputValue='' value={this.state.selectedGroupOption} options={rangeOptions} onChange={this.handleRangeChange} placeholder='Select range...' />
                    </div>

                    <Row>
                        <Col lg>
                            <Card className={cx("my-3", css.card)} >
                                <Card.Header><h5>Calls per user</h5></Card.Header>
                                <Card.Body>
                                    <ChartPie data={this.callsPerUser.data} isLoading={this.state.isUpdating} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg>
                            <Card className={cx("my-3", css.card)} >
                                <Card.Header><h5>Call volume</h5></Card.Header>
                                <Card.Body>
                                    <ChartBar data={this.totalRecordingVolume.data} keyName="date" bars={this.totalRecordingVolume.definitions} isLoading={this.state.isUpdating} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg>
                            <Card className={cx("my-3", css.card)} >
                                <Card.Header><h5>Direction</h5></Card.Header>
                                <Card.Body>
                                    <ChartPie data={this.direction.data} isLoading={this.state.isUpdating} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg>
                            <Card className={cx("my-3", css.card)} >
                                <Card.Header><h5>Call volume per type</h5></Card.Header>
                                <Card.Body>
                                    <ChartBar data={this.volumePerSourceType.data} keyName="date" bars={this.volumePerSourceType.definitions} stacked={this.state.showStacked} isLoading={this.state.isUpdating} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Loader>
        )
    }
}