import { Component } from 'react';
import { Alert } from 'react-bootstrap';

type CommunicationErrorProps = {
    detail: string;
};

export class CommunicationError extends Component<CommunicationErrorProps> {
    render() {
        return (
            <Alert className="mt-3" variant="danger">
                <Alert.Heading>Error</Alert.Heading>
                <p>Failed to access the server. Please try again later.</p>
                <p><i>{this.props.detail ? `Detail: ${this.props.detail}` : null}</i></p>
            </Alert>
        );
    }
}