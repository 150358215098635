import { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext, UserContextState } from 'context/UserContext';
import { Container } from 'reactstrap';
import { PublicLayout } from './PublicLayout';
import { SpinnerWithDescription } from 'components/SpinnerWithDescription';
import { AspectRatio } from 'components/AspectRatio';
import { urls } from 'logic/urls';
import log from 'loglevel';
import css from './KpnHome.module.scss';

type KpnHomeInternalProps = {    
    userContext: UserContextState    
}

class KpnHomeInternal extends Component<KpnHomeInternalProps, {}> {
    static displayName = KpnHomeInternal.name;

    render() {
        const userContext = this.props.userContext;

        if (userContext.isAuthorizationInProgress()) {
            log.debug("[KpnHome] Waiting for authorization to complete...");
            return <SpinnerWithDescription description="Checking user account..." />
        }

        if (userContext.isAccessAllowed()) {
            log.debug("[KpnHome] Authorized user is logged-in, redirecting to the portal...");
            return <Redirect to={urls.initialPortal} />
        }

        return (
            <div className={css.home}>
                <PublicLayout hideHomeButton>
                    <Container fluid='true'>
                        <AspectRatio className={css.topImageContainer} aspectRatio="16:9">
                            <img src="./images/KPN-background.jpg" alt="Background"></img>
                            <div className={css.overlayHeader}>
                                <p>ATTEST Management Portal</p>
                            </div>
                            <div className={css.overlayRevision}>
                                <p>Revision 1.16</p>
                            </div>
                        </AspectRatio>
                    </Container>
                </PublicLayout>
            </div>
        );
    }
}

// Inject the required contexts.
export const KpnHome = () => {    
    const userContext = useContext(UserContext) as UserContextState;
    
    return (
        <KpnHomeInternal userContext={userContext} />
    )
}