import { Component, useContext } from 'react';
import { Alert, Button } from 'reactstrap';
import { Loader } from 'components/Loader';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { ClientCertificateFingerprintEntries } from './ClientCertificateFingerprintEntries';
import { TenantSelection } from './TenantSelection';
import { ModalFingerprintAdd, DoneInfo } from './ModalFingerprintAdd';
import { Tenant, MobileConnector } from 'logic/apiCalls';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';

type TabMobileSettingsDsApiInternalProps = {
    notificationContext: NotificationContextState
}

type TabMobileSettingsDsApiInternalState = {
    isLoading: boolean,
    showApiError: boolean,
    isLoadingMobileConnector: boolean,
    showMobileConnectorApiError: boolean,
    tenants: Tenant[],
    selectedTenantId: string | null,
    mobileConnector: MobileConnector | null,
    fingerprintToDelete: string | null,  
    isAddModalOpen: boolean   
}

class TabMobileSettingsDsApiInternal extends Component<TabMobileSettingsDsApiInternalProps, TabMobileSettingsDsApiInternalState> {
    state: TabMobileSettingsDsApiInternalState = {
        isLoading: true,
        showApiError: false,
        isLoadingMobileConnector: false,
        showMobileConnectorApiError: false,
        tenants: [],
        selectedTenantId: null,
        mobileConnector: null,
        fingerprintToDelete: null,
        isAddModalOpen: false
    }   

    async componentDidMount() {
        await this.loadPageData();
    }

    toggleAddModal = () => {
        this.setState({
            isAddModalOpen: !this.state.isAddModalOpen
        });
    }

    openAddModal = () => {
        this.setState({
            isAddModalOpen: true,
        });
    }

    handleTenantSelectionChanged = async (tenantId: string | null) => {
        this.setState(
            {
                selectedTenantId: tenantId
            }
        );

        if (tenantId) {
            await this.loadMobileConnector(tenantId, false);
        } else {
            this.setState(
                {
                    mobileConnector: null
                }
            );
        }        
    }

    handleTenantSelectionError = (description: string, detail: string) => {
        this.props.notificationContext.setNotification(description, detail, 'danger');
    }

    loadPageData = async () => {
        try {
            const tenants = await apiCalls.getTenantsByDistributor();
            
            // Only the tenants with the Mobile connector should be displayed.
            const filteredTenants = tenants.filter(tenant => tenant.connectors.some(connector => connector.name === "Mobile" && connector.enabled));

            this.setState({
                tenants: filteredTenants,
                isLoading: false,
            });            
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    loadMobileConnector = async (tenantId: string, isUpdate: boolean) => {
        try {
            this.setState({                
                isLoadingMobileConnector: !isUpdate
            });

            const mobileConnector = await apiCalls.getMobileConnectorByDistributor(tenantId);

            this.setState({
                isLoadingMobileConnector: false,
                showMobileConnectorApiError: false,
                mobileConnector: mobileConnector
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoadingMobileConnector: false,             
                showMobileConnectorApiError: true
            });
        }
    }

    getSelectedTenant = () : Tenant | null => {
        if (!this.state.selectedTenantId) {
            return null;
        }
        
        const tenant = this.state.tenants.find(tenant => tenant.id === this.state.selectedTenantId);
        return tenant || null;
    }

    executeAdd = async (doneInfo: DoneInfo) => {
        log.debug(`[executeAdd] Adding fingerprint ${doneInfo.fingerprint}...`);

        const tenantId = this.state.selectedTenantId;
        if (!tenantId) {
            return;
        }
        
        try {
            var response = await apiCalls.queryMobileConnectorInfo(doneInfo.fingerprint);
            
            if (response.isUsed) {
                this.props.notificationContext.setNotification("Error", `The provided client certificate fingerprint is already in use by another tenant.`, 'danger');
                return;
            }
            
            const fingerprints = this.state.mobileConnector?.clientCertificateFingerprints || [];
            fingerprints.push(doneInfo.fingerprint);

            await apiCalls.updateMobileConnectorByDistributor(tenantId, fingerprints);
            
            await this.loadMobileConnector(tenantId, true);

            this.props.notificationContext.setNotification("Fingerprint configuration modified", "Successfully added fingerprint.", 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the fingerprint configuration.`, 'danger');
        }
        finally {
            this.toggleAddModal();
        }
    }

    executeDelete = async (fingerprint: string) => {
        log.debug(`[executeDelete] Deleting fingerprint ${fingerprint}...`);

        const tenantId = this.state.selectedTenantId;
        if (!tenantId) {
            return;
        }
        
        try {
            const fingerprints = this.state.mobileConnector?.clientCertificateFingerprints || [];
            if (!fingerprints.includes(fingerprint)) {
                log.error(`Fingerprint ${fingerprint} not found in the list of fingerprints.`);
                return;
            }
            
            fingerprints.splice(fingerprints.indexOf(fingerprint), 1);

            await apiCalls.updateMobileConnectorByDistributor(tenantId, fingerprints);
            
            await this.loadMobileConnector(tenantId, true);

            this.props.notificationContext.setNotification("Fingerprint configuration modified", "Successfully deleted fingerprint.", 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the fingerprint configuration.`, 'danger');
        }
    }

    renderMobileConnectorSection = () => {
        if (!this.state.selectedTenantId) {
            return null;
        }

        return (
            <div className="my-5">                    
                    <Loader isLoading={this.state.isLoadingMobileConnector} showApiError={this.state.showMobileConnectorApiError}>
                        <>
                            {this.renderDisabledTenantWarning()}
                            {this.renderClientCertificateFingerprintEntries()}
                            {this.renderAddButton()}
                        </>
                    </Loader>
            </div>            
        )        
    }

    renderAddButton = () => {
        return <div className="my-3">
            <Button className='mt-3' color='primary' onClick={this.openAddModal}>Add</Button>
        </div>
    }

    renderDisabledTenantWarning = () => {
        const tenant = this.getSelectedTenant();

        if (!tenant || tenant.enabled) {
            return null;
        }
        
        return (
            <Alert color='danger'>This tenant is disabled. No mobile phone calls will be recorded.</Alert>
        )
    }

    renderClientCertificateFingerprintEntries = () => {
        if (this.state.mobileConnector && this.state.mobileConnector.clientCertificateFingerprints?.length > 0) {
            return (
                <ClientCertificateFingerprintEntries
                    fingerprints={this.state.mobileConnector.clientCertificateFingerprints}
                    handleDelete={this.executeDelete}
                    showDelete={true}
                />
            );
        }

        if (this.state.selectedTenantId) {
            return (
                <Alert color="warning">
                    No fingerprints are provisioned for this tenant.
                </Alert>
            );
        }

        return null; // Add return null for the case when mobileConnector is null
    }
 
    render() {
        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <>    
                    <ModalFingerprintAdd isOpen={this.state.isAddModalOpen} onToggle={this.toggleAddModal} onAdd={this.executeAdd}/>                    
                    <TenantSelection tenants={this.state.tenants} onTenantSelectionChanged={this.handleTenantSelectionChanged} onError={this.handleTenantSelectionError} />
                    <hr/>
                    {this.renderMobileConnectorSection()}
                </>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabMobileSettingsDsApi = (props: any) => {    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabMobileSettingsDsApiInternal {...props} notificationContext={notificationContext} />
    )
}