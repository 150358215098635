import { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext, UserContextState } from 'context/UserContext';
import { Container, Row, Col } from 'reactstrap';
import { PublicLayout } from './PublicLayout';
import { SpinnerWithDescription } from 'components/SpinnerWithDescription';
import { AspectRatio } from 'components/AspectRatio';
import { urls } from 'logic/urls';
import log from 'loglevel';
import css from './Home.module.scss';


type HomeInternalProps = {    
    userContext: UserContextState    
}

class HomeInternal extends Component<HomeInternalProps, {}> {
    static displayName = HomeInternal.name;

    render() {
        const userContext = this.props.userContext;

        if (userContext.isAuthorizationInProgress()) {
            log.debug("[Home] Waiting for authorization to complete...");
            return <SpinnerWithDescription description="Checking user account..." />                
        }

        if (userContext.isAccessAllowed()) {
            log.debug("[Home] Authorized user is logged-in, redirecting to the portal...");
            return <Redirect to={urls.initialPortal} />
        }

        return (
            <div className={css.home}>
                <PublicLayout hideHomeButton>
                    <Container fluid='true'>
                        <AspectRatio className={css.topImageContainer} aspectRatio="1:0.21">
                            <img src="./images/calling-women.jpg" alt="Background" ></img>
                            <div className={css.overlayHeader}>
                                <p>ATTEST Management Portal</p>
                            </div>
                        </AspectRatio>
                    </Container>
                    <Container fluid='true' className="my-5">
                        <img className={css.attestOverview} src="./images/Attest-overview.png" alt="Schematic overview of the ATTEST solution"></img>
                        <div>
                            <p>Revision 1.16</p>
                        </div>
                    </Container>
                    <Container fluid='true' className="bg-dark">
                        <Container className={`text-white ${css.footerList}`}>
                            <Row>
                                <Col className="text-center">
                                    <ul>
                                        <li><h5>Contact information</h5></li>
                                        <li>+31 72 210 02 04</li>
                                        <li>info@cybertwice.com</li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <p>
                                        <span className="ml-1">© 2023 <a href="https://cybertwice.com" target="_blank" rel="noopener noreferrer">CyberTwice</a></span>
                                        <span>
                                            <a href="https://cybertwice.com/disclaimer.html" target="_blank" rel="noopener noreferrer"><span className="ml-1">Disclaimer</span></a>
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </PublicLayout>
            </div>
        );
    }
}

// Inject the required contexts.
export const Home = () => {    
    const userContext = useContext(UserContext) as UserContextState;
    
    return (
        <HomeInternal userContext={userContext} />
    )
}