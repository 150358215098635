import { Component } from 'react';
import { SingleValue }  from 'react-select'
import { Tenant } from 'logic/apiCalls';
import { TenantEntry, TenantSelect } from 'components/TenantSelect';

import css from './TenantSelection.module.scss';


type OnTenantSelectionChangedCallback = (tenantId: string | null) => void;

type onErrorCallback = (description: string, detail: string) => void;

type TenantSelectionProps = {
    tenants: Tenant[],
    onTenantSelectionChanged: OnTenantSelectionChangedCallback,
    onError: onErrorCallback
}

type TenantSelectionState = {
    selectedTenantEntry: TenantEntry | null,
    phoneNumber: string,
    tenantNotFoundText: string | null,
    tenantFoundText: string | null,
    tenantEntries: TenantEntry[]    
}

export class TenantSelection extends Component<TenantSelectionProps, TenantSelectionState> {
    state: TenantSelectionState = {
        selectedTenantEntry: null,
        phoneNumber: "",
        tenantNotFoundText: null,
        tenantFoundText: null,
        tenantEntries: []
    }   

    handleSelectedTenantEntryChange = async (selectedTenantEntry: SingleValue<TenantEntry>) => {
        if (!selectedTenantEntry?.value || selectedTenantEntry?.value === this.state.selectedTenantEntry?.value)
        {
            return; // Selection has not changed.
        }

        this.setState({
            selectedTenantEntry: selectedTenantEntry
        })

        this.props.onTenantSelectionChanged(selectedTenantEntry.value);
    }

    renderTenantSelectionSection = () => {
        return <>
            <div className="my-3">Please select the tenant:</div>
            <div>
                <TenantSelect 
                    className={css.rangeSelect} 
                    placeholder='Select tenant...'
                    isClearable={false} 
                    tenants={this.props.tenants}
                    onTenantSelectionChanged={this.handleSelectedTenantEntryChange} />
            </div>
        </>
    }
   
    render() {
        return (
            <>
                {this.renderTenantSelectionSection()}
            </>
        )
    }
}