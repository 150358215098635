import { BillingMetricDefinitionDto, BillingMetricsDto } from './apiCalls';
import * as misc from 'utils/misc';

/** Type contains the information for a single point of data on a chart. */
export type ChartData = {
   date: string,
   value: number | string
}

/** Type contains the information for a single definition of a value in a chart. */
export type ChartDefinition = {
    displayName: string,
    valueName: string
}

/** Type contains the information to be displayed on a single chart. */
export type ChartInfo = {
    data : ChartData[],
    definitions: ChartDefinition[]
}

/** Type contains all information required to display a single metric. */
export type BillingMetricInfo = {
    displayName: string, 
    metric: ChartInfo
}

export const convertToChartData = (metricDefinitions: BillingMetricDefinitionDto[], metrics: BillingMetricsDto) : BillingMetricInfo[] => {    
    const billingInfos: BillingMetricInfo[] = [];

    for (const metricDefinition of metricDefinitions) {
        const metric = extractChartInfo(metricDefinition.metric, metricDefinition.unitOfMeasure, metrics);

        if (metric) {
            billingInfos.push({
                displayName: metricDefinition.displayName,
                metric: metric
            });
        }            
    }
    
    return billingInfos;
}

const extractChartInfo = (metricName: string, unitOfMeasure: string, metrics: BillingMetricsDto) : ChartInfo | null => {
    let dayIndex = 1;

    const rawSingleMetricData = metrics.data[metricName];
    if (!rawSingleMetricData) {
        return null;
    }

    const chartData: ChartData[]  = rawSingleMetricData.map(statistic => {
        return {
            date: (dayIndex++).toString(),
            value: misc.round(statistic, 2)
        }
    });

    return {
        data: chartData,
        definitions: [{displayName: unitOfMeasure, valueName: "value"}]
    };
}
