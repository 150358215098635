import { appConfig } from './appConfigProvider';
import { authentication, authenticationStates } from './authentication';
import * as apiCalls from './apiCalls';
import * as misc from 'utils/misc';
import log from 'loglevel';

// Enum defines the possible states of the authorizationState property of the state of the AuthenticationProvider.
export const authorizationStates = {
    UNDETERMINED: 'Undetermined', // The authorization has not been checked. This check is triggered when the authentication state reaches the AUTHENTICATED state.
    AUTHORIZING: 'Authorizing',
    AUTHORIZATION_DONE: 'Authorization done', // The currently logged-in user is authorized to use this application. This means that the tenant has been signed up. No user-based checks are currently performed.
    AUTHORIZATION_ERROR: 'AuthorizationError'
}

const userPermissions = {
    READ_WRITE_ALL: "ReadWriteAll"
}

// The result of the last authorize invocation at the back-end.
let authorizeResult = null;

// The collection of callbacks registered which are invoked upon every change of the authorization state.
let authorizationStateChangeCallbacks = [];

let currentAuthorizationState = authorizationStates.UNDETERMINED;

function shouldAuthorizationBePerformed() {
    const pathName = window.location.pathname;

    if (appConfig.noAuthorizationUrls.includes(pathName)) {
        return false;
    }

    return true;
}

function handleAuthenticationStateChange(newState) {
    log.debug(`[authorization, handleAuthenticationStateChange] New state reported: ${newState}`);

    if (newState === authenticationStates.AUTHENTICATED) {
        if (shouldAuthorizationBePerformed()) {
            log.info(`[authorization, handleAuthenticationStateChange] User is authenticated, starting authorization...`);

            updateAuthorizationState(authorizationStates.AUTHORIZING);
            performAuthorizationCheckOnBackendAsync();
        } else {
            log.info(`[authorization, handleAuthenticationStateChange] User is authenticated, but on the current location authorization should not be performed.`);
            updateAuthorizationState(authorizationStates.UNDETERMINED);
        }        
    } else {
        updateAuthorizationState(authorizationStates.UNDETERMINED);
    }
}

async function performAuthorizationCheckOnBackendAsync() {
    try {
        const tenantId = authentication.getUserAccount().tenantId;      

        authorizeResult = await apiCalls.getAuthorize(tenantId);

        updateAuthorizationState(authorizationStates.AUTHORIZATION_DONE);        
    }
    catch (error) {        
        updateAuthorizationState(authorizationStates.AUTHORIZATION_ERROR);
    }
}

function updateAuthorizationState(newState) {
    if (newState === currentAuthorizationState) {
        return; // Prevent unneccessary callbacks to the client-code.
    }

    currentAuthorizationState = newState;

    setTimeout(() => reportAuthorizationState(newState), 1);
}

function reportAuthorizationState(newState) {
    // Invoke all the registered callbacks to inform the client-code about the state change.
    authorizationStateChangeCallbacks.forEach(callback => {
        callback(newState);
    });
}

function getAuthorizationState() {
    return currentAuthorizationState;
}

// Method allows a callback method to be registered which is invoked upon every change of the authorization state.
// The signature of the method is: callback(state)
// The state parameter is a value from the 'authorizationStates' enum.
function registerAuthorizationStateChangeCallback(callback) {
    if (!callback || !misc.isFunction(callback)) {
        return;
    }

    // Add the provided callback to the collection of callbacks.
    authorizationStateChangeCallbacks.push(callback);

    // Immediately invoke the callback so that the client code is in-sync with the state of this module.
    callback(getAuthorizationState());
}

function unregisterAuthorizationStateChangeCallback(callback) {
    authorizationStateChangeCallbacks = authorizationStateChangeCallbacks.filter((item) => item !== callback);
}

function isAuthorizationInProgress() {
    const authenticationState = authentication.getAuthenticationState();

    // Check if the authentication step is still in progress...
    if (authenticationState === authenticationStates.AUTHENTICATING) {
        return true;
    }

    // The authentication is not in progress (so either not started, failed, or successful),
    // check if the authorization is in progress...
    if (authenticationState === authenticationStates.AUTHENTICATED &&
        currentAuthorizationState === authorizationStates.AUTHORIZING) {
        return true;    
    }

    // In all other situations the authorization is not in progress.
    return false;
}

function isAuthorizationDone() {
    return currentAuthorizationState === authorizationStates.AUTHORIZATION_DONE;
}

function isTenantKnown() {
    if (!isAuthorizationDone()) {
        return false;
    }

    return authorizeResult.isTenantKnown;
}

function isTenantEnabled() {
    if (!isAuthorizationDone()) {
        return false;
    }

    return authorizeResult.isTenantEnabled;
}

function isTenantAuthorized() {
    if (!isAuthorizationDone()) {
        return false;
    }

    return authorizeResult.isTenantKnown && authorizeResult.isTenantEnabled;
}

function isDistributor() {
    if (!isAuthorizationDone()) {
        return false;
    }

    return authorizeResult.isDistributor;
}

function isAccessAllowed() {
    if (!isAuthorizationDone()) {
        return false;
    }
    
    if (!isTenantAuthorized()) {
        return false;
    }

    return authorizeResult.userPermissions.includes(userPermissions.READ_WRITE_ALL);
}

async function triggerAuthorize() {
    const authenticationState = authentication.getAuthenticationState();
    if (authenticationState !== authenticationStates.AUTHENTICATED) {
        throw new Error("Authorization can only be performed when the user has been authenticated.");
    }

    await performAuthorizationCheckOnBackendAsync();
}

// Register this module for the authentication state change.
authentication.registerAuthenticationStateChangeCallback(handleAuthenticationStateChange);

export const authorization = {
    getAuthorizationState: getAuthorizationState,    
    isAuthorizationInProgress: isAuthorizationInProgress,
    isAuthorizationDone: isAuthorizationDone,
    isTenantKnown: isTenantKnown,
    isTenantEnabled: isTenantEnabled,
    isTenantAuthorized: isTenantAuthorized,
    isDistributor: isDistributor,
    isAccessAllowed: isAccessAllowed,
    triggerAuthorize: triggerAuthorize,
    registerAuthorizationStateChangeCallback: registerAuthorizationStateChangeCallback,
    unregisterAuthorizationStateChangeCallback: unregisterAuthorizationStateChangeCallback    
}