import { Component, useContext } from 'react';
import Select from 'react-select'
import { Alert, Button, CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { Loader } from 'components/Loader';
import { oneWayCompare } from 'utils/misc';
import { ModalConfirm } from 'components/ModalConfirm';
import { TenantContext } from 'context/TenantContext';
import { NotificationContext } from 'context/NotificationContext';

import * as apiCalls from 'logic/apiCalls';
import log from 'loglevel';
import css from './TabProcessingTranscription.module.scss';

class TabProcessingTranscriptionInternal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showApiError: false,
            isConfirmSaveModalOpen: false,
            stateInBackend: {
                transcriptionEnabled: true,
                selectedLanguageOption: null,
            },
            transcriptionEnabled: true,
            selectedLanguageOption: null,
            languageOptions: [],
        }   
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    loadPageData = async () => {
        try {
            const transcriptionSettingsPromise = apiCalls.getTranscriptionSettings();
            const transcriptionLanguagesPromise = apiCalls.getTranscriptionLanguages();

            const [transcriptionSettings, transcriptionLanguages] = await Promise.all([transcriptionSettingsPromise, transcriptionLanguagesPromise]);

            const extractedState = this.extractStateFromData(transcriptionSettings, transcriptionLanguages);

            this.setState({
                isLoading: false,
                showApiError: false,
                ...extractedState,
                stateInBackend: this.extractStateInBackendFromData(transcriptionSettings, extractedState.languageOptions)
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);

            this.setState({
                isLoading: false,
                showApiError: true
            });
        }
    }

    /** Method returns a flat object from the hierarchical data received from the back-end. */
    extractStateFromData = (transcriptionSettings, transcriptionLanguages) => {

        const transcriptionLanguagesOptions = [];
        let selectedTranscriptionLanguageOption;
        
        transcriptionLanguages.forEach(transcriptionLanguage => {
            const languageOption = {
                value:transcriptionLanguage.code, 
                label:transcriptionLanguage.displayName
            };

            transcriptionLanguagesOptions.push(languageOption);

            if (languageOption.value.toLowerCase() === transcriptionSettings.language.toLowerCase())
            {
                selectedTranscriptionLanguageOption = languageOption;
            }
        });

        return {
            transcriptionEnabled: transcriptionSettings.enabled,
            selectedLanguageOption: selectedTranscriptionLanguageOption,
            languageOptions: transcriptionLanguagesOptions,
        }
    }
    
    extractStateInBackendFromData = (transcriptionSettings, languageOptions) => {
        return {
            transcriptionEnabled: transcriptionSettings.enabled,
            selectedLanguageOption: languageOptions.find(language => language.value.toLowerCase() === transcriptionSettings.language.toLowerCase()),
        }
    }

    /** Method returns the hierarchical data to be sent to the back-end based upon the current flat state. */
    constructDataFromState = () => {
        return {           
            enabled: this.state.transcriptionEnabled, 
            language: this.state.selectedLanguageOption.value           
        }
    }

    toggleConfirmSaveModal = () => {
        this.setState({
            isConfirmSaveModalOpen: !this.state.isConfirmSaveModalOpen
        });
    }

    toggleEnableTranscription = () => {
        this.setState(
            {
                transcriptionEnabled: !this.state.transcriptionEnabled
            }
        )
    }
   
    handleSelectedLanguageChange = (selectedLanguageOption) => {
        this.setState(
            {
                selectedLanguageOption: selectedLanguageOption
            }
        );  
    }

    isSaveButtonEnabled = () => {
        return !oneWayCompare(this.state.stateInBackend, this.state);
    }

    executeSave = async () => {
        log.debug(`[executeSave] Saving the transcription settings...`);

        try {
            const transcriptionSettings = this.constructDataFromState();
            await apiCalls.updateTranscriptionSettings(transcriptionSettings);
            await this.loadPageData();

            this.props.notificationContext.setNotification("Recording configuration saved", `Successfully updated the transcription settings.`, 'success');
        }
        catch (error)
        {
            log.error(`Something went wrong: ${error}`);
            this.props.notificationContext.setNotification("Error", `Error during saving of the transcription settings.`, 'danger');            
        }
        finally {
            this.toggleConfirmSaveModal();            
        }
    }
   
    render() {
        const readOnly = !this.props.tenantContext.hasTeamsRecordingSubscription();

        return (
            <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError}>
                <div className="m-3">
                    <ModalConfirm
                        headerText="Confirm save"
                        detailedText="Are you sure you want to update the transcription settings?"
                        isOpen={this.state.isConfirmSaveModalOpen}
                        onToggle={this.toggleConfirmSaveModal}
                        onConfirm={this.executeSave} />

                    <div className="mt-3 mb-5">
                        {
                            readOnly ?
                                <Alert color="warning">These settings are read-only for CyberGate recording users. To enable this feature an Attest subscription is required.</Alert> :
                                <></>
                        }
                        <Form>
                            <FormGroup>
                                <Label for="enableTranscriptionSwitch"><strong>Media processing</strong></Label>
                                <p>The transcription is the speech-to-text process that creates readable and searchable text for every recorded media.</p>
                                <div className="mt-3">
                                    <CustomInput disabled={readOnly} checked={this.state.transcriptionEnabled} type="switch" onChange={this.toggleEnableTranscription} id="enableTranscriptionSwitch" label="Enable transcription" />
                                </div>
                                <div className="mt-3">
                                    <div className={css.processingTranscription}>
                                        <p>Transcription language</p>
                                        <Select isDisabled={readOnly} isSearchable={true} value={this.state.selectedLanguageOption} options={this.state.languageOptions} onChange={this.handleSelectedLanguageChange} placeholder='Select language...' />
                                    </div>
                                </div>
                            </FormGroup>
                        </Form>
                    </div>
                    <Button disabled={!this.isSaveButtonEnabled() || readOnly} onClick={this.toggleConfirmSaveModal} color='primary' >Save</Button>
                </div>
            </Loader>
        )
    }
}

// Inject the required contexts.
export const TabProcessingTranscription = (props) => {
    const tenantContext = useContext(TenantContext);    
    const notificationContext = useContext(NotificationContext);

    return (
        <TabProcessingTranscriptionInternal {...props} tenantContext={tenantContext} notificationContext={notificationContext} />
    )
}