import { SubscriptionEntry } from './SubscriptionEntry'; 
import { InfoIcon }  from 'components/InfoIcon';
import { Subscription } from 'logic/apiCalls';

type SubscriptionEntriesProps = {
    subscriptions: Subscription[],
    showInfo?: boolean
}

export const SubscriptionEntries = ({ subscriptions, showInfo }: SubscriptionEntriesProps) => {
    return (
        <div>
            <table className="table table-striped table-bordered my-2">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Created</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Auto renew</th>
                        <th scope="col">
                            State
                            {
                                showInfo ? 
                                    <InfoIcon id='SubscriptionStateInfo' tooltip='The Attest service only works when the subscription is Active. This value is controlled by the Microsoft Marketplace.'/> :
                                    <></>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        subscriptions.map((value) => {
                            return <SubscriptionEntry key={value.id} subscription={value} />
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}