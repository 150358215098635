import React, { FC, MouseEventHandler } from 'react';

interface DeleteButtonProps {
  className: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const DeleteButton: FC<DeleteButtonProps> = ({ className, onClick }) => {
  return (
    <button onClick={onClick} className={`btn btn-danger btn-sm ${className}`} type="button">
      <span className="fa fa-trash"></span>
    </button>
  );
};