import React from 'react';
import { Connector } from 'logic/apiCalls';
import { Form, Row, Col } from 'react-bootstrap';

type ReadOnly = {
    readonly: boolean
}

export type ConnectorWithReadOnlyFlag = Connector & ReadOnly;

type ConnectorEntryProps = {
    connector: ConnectorWithReadOnlyFlag,
    index: number,
    handleChange: (connector: Connector, value: boolean, index: number) => void    
}

export const ConnectorEntry = ({ connector, index, handleChange } : ConnectorEntryProps) => {
    return (
        <>
            <Row>
                <Col>
                    <Form.Text className="text-muted">
                        {connector.name} recording
                    </Form.Text>
                </Col>
                <Col>
                    <Form.Switch id={connector.name} type="switch" disabled={connector.readonly} checked={connector.enabled} onChange={(e) => handleChange(connector, e.target.checked, index)} />                     
                </Col>
            </Row>            
        </>
    )
}