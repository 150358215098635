import React, { PureComponent, ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { getColor } from 'logic/chartLogic';

interface BarData {
  valueName: string;
  displayName: string;
}

interface ChartBarProps {
  data: any[];
  keyName: string;
  bars: BarData[];
  stacked?: boolean;
  isLoading: boolean;
}

export class ChartBar extends PureComponent<ChartBarProps> {
  // The ResponsiveContainer of the Recharts library has an resizing issue when used inside a flex container.
  // The work-around described here is applied:
  // https://github.com/recharts/recharts/issues/172#issuecomment-523362671
  render(): ReactNode {
    const loadingOverlayRendered = (
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spinner animation='border' variant='secondary' />
      </div>
    );

    return (
      <div style={{ position: 'relative', width: '100%', paddingBottom: '300px' }}>
        <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}>
          <ResponsiveContainer>
            <BarChart
              width={500}
              height={300}
              data={this.props.data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey={this.props.keyName} />
              <YAxis />
              <Legend />
              <Tooltip />
              {this.props.bars.map((barData, index, array) => {
                return (
                  <Bar
                    key={barData.valueName}
                    stackId={this.props.stacked ? '1' : index}
                    name={barData.displayName}
                    dataKey={barData.valueName}
                    fill={getColor(index, array.length)}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
          {this.props.isLoading ? loadingOverlayRendered : null }
        </div>
      </div>
    );
  }
}