import { Component, useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { TabHeader } from '../TabHeader';
import { NotificationContext, NotificationContextState } from 'context/NotificationContext';
import { TabMobileSettingsProvisioning } from './Provisioning/TabMobileSettingsProvisioning';
import { TabMobileSettingsDownload } from './Download/TabMobileSettingsDownload';
import { TabMobileSettingsRecorders } from './Recorders/TabMobileSettingsRecorders';
import { TabMobileSettingsDsApi } from './DsApi/TabMobileSettingsDsApi';

import log from 'loglevel';

type MobileSettingsPageState = {
    isLoading: boolean,
    showApiError: boolean    
}

type MobileSettingsInternalProps = {
    notificationContext: NotificationContextState
}

export class DistributorMobileSettingsInternal extends Component<MobileSettingsInternalProps, MobileSettingsPageState>  {
    state: MobileSettingsPageState ={
        isLoading: true,
        showApiError: false    
    }

    async componentDidMount() {
        await this.loadPageData();
    }

    loadPageData = async () => {
        try {
            this.setState({
                isLoading: false
            });
        }
        catch (error) {
            log.error(`Something went wrong: ${error}`);
        }
    }
    render() {
        const tabInfos = [
            { name: "Provisioning", tabId: "0" },
            { name: "DS-API", tabId: "1" }, 
            { name: "Download", tabId: "2" },
            { name: "Recorders", tabId: "3" },
        ]

        let pageHeaderRendered = <h1>Mobile settings</h1>

        return (
            <>
                {pageHeaderRendered}
                <TabHeader tabInfos={tabInfos}>
                    <TabContent>
                        <TabPane tabId="0">
                            <TabMobileSettingsProvisioning />
                        </TabPane>
                        <TabPane tabId="1">
                            <TabMobileSettingsDsApi />
                        </TabPane>  
                        <TabPane tabId="2">
                            <TabMobileSettingsDownload />
                        </TabPane>
                        <TabPane tabId="3">
                            <TabMobileSettingsRecorders />
                        </TabPane>                      
                    </TabContent>
                </TabHeader>      
            </>
        );
    }
}

// Inject the required contexts.
export const DistributorMobileSettings = (props: any) => {
    const notificationContext = useContext(NotificationContext);

    return (
        <DistributorMobileSettingsInternal {...props} notificationContext={notificationContext} />
    )
}