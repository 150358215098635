import { DeleteButton } from 'components/DeleteButton';

import css from './ClientCertificateFingerprintEntry.module.scss';

type ClientCertificateFingerprintEntryProps = {
    fingerprint: string, 
    handleDelete: (fingerprint: string) => void,
    showDelete: boolean
}

export const ClientCertificateFingerprintEntry = ({ fingerprint, handleDelete, showDelete } : ClientCertificateFingerprintEntryProps) => {

    return (
        <tr key={fingerprint} className='d-flex' >
            <td className={`col-10 ${css.courierFont}`}>{fingerprint}</td>
            <td className="col-2">
                { showDelete && <DeleteButton className='float-right mr-3' onClick={() => handleDelete(fingerprint)} /> }
            </td>
        </tr>
    )
}