import { Component } from 'react';

export class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>404 Not found</h1>                
                <p>Sorry, nothing to be found here...</p>
            </div>
        );
    }
}
