import React from 'react';
import { Table, Alert } from 'reactstrap';
import { TenantEntry }  from './TenantEntry'
import { Tenant } from 'logic/apiCalls';

import css from './TenantEntries.module.scss';

type TenantEntriesProps = {
    tenants: Tenant[], 
    showDeleteButton: boolean,
    handleEdit: (tenantToUpdate: Tenant) => void,
    handleDelete: (tenantToDelete: Tenant) => void
}

export const TenantEntries = ({ tenants, showDeleteButton, handleEdit, handleDelete } : TenantEntriesProps) => {

    if (tenants.length === 0) {
        return <Alert color='warning'>
            No tenants are configured
        </Alert>
    }

    return (
        <div className={css.tenantTable}>
            <Table className="table-striped">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-3">Name</th>
                        <th className="col-3">Azure Id</th>
                        <th className="col-2">Reference</th>
                        <th className="col-2">Retention</th>
                        <th className="col-1">Transcription</th>
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tenants.map((value) => {
                            return <TenantEntry key={value.id} tenant={value} showDeleteButton={showDeleteButton} handleEdit={handleEdit} handleDelete={handleDelete} />
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}