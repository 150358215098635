import React from 'react';
import { Redirect } from 'react-router';

// This page is loaded after the login at Azure is finished and the user is redirected back.
// This page is shown briefly until the authentication and authorization is finished. In case the authorization was successful the user 
// is redirected to the settings page otherwise the user is redirected to either a page that shown the tenant is not known are that the user 
// has not enough access rights.
export class PostLogin extends React.Component {
    render() {
        const postRedirectUrl = sessionStorage.getItem('postRedirectUrlKey');
        
        if (postRedirectUrl) {
            // Add the hash part of the current location to the post redirect URL. This hash contains the information that is
            // required by the MSAL library.
            const postRedirectUrlWithHash = postRedirectUrl + window.location.hash;
            return <Redirect to={postRedirectUrlWithHash} />
        }

        return <div>No post redirect URL set at login...</div>
    }
}