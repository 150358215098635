import React, { FC } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import css from './WarningIcon.module.scss';

interface WarningIconProps {
  tooltip: string;
  showWarning: boolean;
  id: string;
}

export const WarningIcon: FC<WarningIconProps> = ({ tooltip, showWarning, id }) => {
  const imageStyle = showWarning ? css.warningIcon : css.placeholder;

  return (
    <OverlayTrigger placement='right' overlay={<Tooltip id={id}>{tooltip}</Tooltip>}>
      <img className={imageStyle} src='./images/warningIcon.png' alt='warning icon' />
    </OverlayTrigger>
  );
};