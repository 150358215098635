import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import { DoneButton } from 'components/DoneButton';
import * as validation from 'utils/validation';

type ModalFingerprintAddProps = {
    isOpen: boolean,
    onToggle: ToggleCallBack,
    onAdd: AddCallback
}

type ModalFingerprintAddState = {
    fingerprint: string,
    isBusy: boolean
}

export type DoneInfo = {
    fingerprint: string,
}

export type ToggleCallBack = () => void

export type AddCallback = (doneInfo: DoneInfo) => void

export class ModalFingerprintAdd extends Component<ModalFingerprintAddProps, ModalFingerprintAddState> {
    state : ModalFingerprintAddState = {
        fingerprint: "",
        isBusy: false       
    }

    componentDidUpdate(prevProps: ModalFingerprintAddProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        this.setState(
            {
                fingerprint: "",
                isBusy: false
            }
        );
    }

    handleDoneButtonClicked = () => {
        const doneInfo: DoneInfo = {
            fingerprint: this.state.fingerprint,
        }

        this.setState({
            isBusy: true
        });

        this.props.onAdd(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.setState({            
        });
        this.props.onToggle();
    }

    handleFingerprintChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newInput = e.target.value.trim();

        if (newInput.length > 0 && !validation.isPartialFingerprint(newInput)) {
            return;
        }

        this.setState({
            fingerprint: newInput
        })
    }

    shouldAddButtonBeEnabled = () : boolean => {
        // A valid fingerprint is required.
        return (validation.isFingerprint(this.state.fingerprint));
    }

    determinAddButtonTooltip = () : string | undefined => {
        if (!validation.isFingerprint(this.state.fingerprint)) {  
            return "A valid fingerprint consisting of 40 hex characters is required";
        }

        return undefined;
    }

    render() {
        const enableAddButton = this.shouldAddButtonBeEnabled();
        const addButtonTooltip = enableAddButton ? "" : this.determinAddButtonTooltip();

        return <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>Add DS-API client certificate fingerprint</ModalHeader>
            <ModalBody>
                <Form className="mt-3">
                    <Form.Group controlId="fingerprint">
                        <Form.Label>Client certificate fingerprint</Form.Label>
                        <Form.Control autoComplete="off" type="text" placeholder="00112233445566778899aabbccddeeff00112233" value={this.state.fingerprint} onChange={this.handleFingerprintChange} />
                        {
                            <Form.Text className="text-muted">
                                The client certificate sha1 fingerprint/thumbprint as hex string<br/> 
                                (without spaces or colons)
                            </Form.Text> 
                        }
                    </Form.Group>
                </Form>
            </ModalBody>

            <ModalFooter>
                <DoneButton id="fingerprintDoneButton" showBusy={this.state.isBusy} buttonText='Add' onClick={this.handleDoneButtonClicked} disabled={!enableAddButton} disabledTooltip={addButtonTooltip}/>                
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

