import { urls, urlCombine, getBaseUriWithoutParameters, constructAdminConsentUrl } from './urls';
import { generateRandomString } from 'utils/misc';

const adminConsentStateKey = "adminConsentState";

type AdminConsentStorageItem = {
    state: string,
    type: string,
    appId: string
}

function redirect(type: string, appId: string) : void {
    const redirectUri = urlCombine(getBaseUriWithoutParameters(), urls.postConsent);
    const state = generateRandomString(32);

    var stateAndAppId : AdminConsentStorageItem = {
        state: state,
        type: type,
        appId: appId
    }

    sessionStorage.setItem(adminConsentStateKey, JSON.stringify(stateAndAppId));
    const adminConsentUri = constructAdminConsentUrl(redirectUri, state, appId);

    window.location.href = adminConsentUri;
}

function verifyState(receivedState: string) : boolean {
    var storedAdminConsent = getStoredAdminConsent(receivedState);
    
    return !!storedAdminConsent && storedAdminConsent.state === receivedState;
}

function getConsentedType(receivedState: string) : string {
    var storedAdminConsent = getStoredAdminConsent(receivedState);

    return storedAdminConsent?.type || "";
}

function getStoredAdminConsent(receivedState: string) : AdminConsentStorageItem | null {
    var stateAndAppIdJson = sessionStorage.getItem(adminConsentStateKey);
    if (!stateAndAppIdJson) {
        return null;
    }

    var stateAndAppId = JSON.parse(stateAndAppIdJson) as AdminConsentStorageItem;
    return stateAndAppId || null;
}

function clearState() : void {
    sessionStorage.removeItem(adminConsentStateKey);
}

export const adminConsent = {
    redirect: redirect,
    verifyState: verifyState,
    clearState: clearState,
    getConsentedType: getConsentedType
}