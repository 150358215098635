import { Component } from 'react';
import { Card, Row, Col, Alert } from 'react-bootstrap';
import { ChartBar } from 'components/ChartBar';
import { Loader } from 'components/Loader';
import * as apiCalls from 'logic/apiCalls';
import * as billingMetricLogic from 'logic/billingMetricLogic';
import * as misc from 'utils/misc';
import cx from 'classnames';
import Select from 'react-select'
import log from 'loglevel';
import css from './TabDashboardUsage.module.scss';

const rangeOptions = [
    { value: "thisMonth", label: "This month", resolution: "day", stacked: false },
    { value: "lastMonth", label: "Last month", resolution: "day", stacked: false },
    { value: "ytd", label: "YTD", resolution: "day", stacked: false }    
]

export class TabDashboardUsage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isUpdating: false,
            showApiError: false,
            selectedGroupOption: rangeOptions[0],
            selectedRange: rangeOptions[0].value,
            resolution: rangeOptions[0].resolution,
            showStacked: rangeOptions[0].stacked
        };

        this.metricInfos = [];
        this.metricData = [];
        this.metricStartDate = null;
        this.metricEndDate = null;
    }

    async componentDidMount() {
        try {
            const metricsInfoPromise = apiCalls.getMetricInfos();
            const metricsPromise = apiCalls.getMetrics(this.state.selectedRange);

            const [metricInfos, metrics] = await Promise.all([metricsInfoPromise, metricsPromise]);

            this.metricInfos = metricInfos;
            this.metricData = billingMetricLogic.convertToChartData(metricInfos, metrics);            
            this.metricStartDate = metrics.startDate;
            this.metricEndDate = metrics.endDate;

            this.setState({                         
                isLoading: false,
                showApiError: false
            });            
        }
        catch (error) {
            log.error(`Error during retrieval of the billing information: ${error}`);

            this.setState({                             
                isLoading: false,
                showApiError: true
            });
        }
    }
    
    handleRangeChange = async (e) => {
        try {
            this.setState({
                selectedGroupOption: e,
                selectedRange: e.value,
                resolution: e.resolution,
                showStacked: e.stacked,
                isUpdating: true,
                showApiError: false            
            });
            
            const metrics =  await apiCalls.getMetrics(e.value);
            this.metricData = billingMetricLogic.convertToChartData(this.metricInfos, metrics);            
            this.metricStartDate = metrics.startDate;
            this.metricEndDate = metrics.endDate;

            this.setState({
                isUpdating: false,
                showApiError: false
            });
        }
        catch (error) {
            log.error(`Error during updating of the billing range: ${error}`);

            this.setState({                             
                isUpdating: false,
                showApiError: true
            });
        }
    }

    createChartSubText = (chartData, unitDisplayName) => {        
        if (unitDisplayName.toLowerCase() === "number" || unitDisplayName.toLowerCase() === "count") {
            const max = this.findMaxInValue(chartData)
            return <>Maximum {unitDisplayName.toLowerCase()} in this period: <b>{max}</b></>
        }

        if (unitDisplayName.toLowerCase() === "gib") {
            const average = this.averageValues(chartData);
            return <>Average {unitDisplayName} in this period: <b>{average}</b></>
        }

        const sum = this.sumValues(chartData);
        return <>Total {unitDisplayName.toLowerCase()} in this period: <b>{sum}</b></>
    }
    
    sumValues = (chartData) => {
        const total = chartData.map(item => item.value).reduce((partialSum, a) => partialSum + a, 0);
        return misc.round(total, 3);
    }

    findMaxInValue = (chartData) => {
        const max = Math.max(...chartData.map(item => item.value));
        return misc.round(max, 3);
    }

    averageValues = (chartData) => {
        var sum = this.sumValues(chartData);
        return misc.round(sum / chartData.length, 3);
    }

    renderContent = () => {
        const noUsageData = this.metricData.length === 0;

        if (noUsageData) {
            return (
                <Alert className="mt-3" variant="info">
                    No usage data is available yet. Note: It can take up to 24 hours before recordings are visible in the usage data.
                </Alert>
            )
        }

        return ( 
            <div className={css.tabDashboardUsage}>
                <div className={css.rangeSelect} >
                    <Select className="my-3" inputValue='' value={this.state.selectedGroupOption} options={rangeOptions} onChange={this.handleRangeChange} placeholder='Select range...' />
                </div>

                {this.metricData.map(entry =>
                    <Row key={entry.displayName}>

                        <Col lg>
                            <Card className={cx("my-3", css.card)} >
                                <Card.Header><h5>{entry.displayName}</h5>from {this.metricStartDate} until {this.metricEndDate}</Card.Header>
                                <Card.Body>
                                    <ChartBar data={entry.metric.data} keyName="date" bars={entry.metric.definitions} isLoading={this.state.isUpdating} />
                                    {this.createChartSubText(entry.metric.data, entry.metric.definitions[0].displayName)}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    render() {
        return <Loader isLoading={this.state.isLoading} showApiError={this.state.showApiError} render={this.renderContent} />
    }
}