export function unique(array: string[]) : string[] {
    let arr : any[] = [];
    for (let i = 0; i < array.length; i++) {
        if (!arr.includes(array[i])) {
            arr.push(array[i]);
        }
    }

    return arr;
}

export function sortOnField(array: any[], fieldSelector: (item: any) => any, comparer: (a:any, b: any) => number) : any[] {    
    return array.sort((a,b) => comparer(fieldSelector(a), fieldSelector(b)));
}
