import { Component, useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { TabInfo, TabHeader } from '../TabHeader';
import { TabDashboardOverview } from './Overview/TabDashboardOverview';
import { TabDashboardUsage } from './Usage/TabDashboardUsage';
import { TenantContext, TenantContextState } from 'context/TenantContext';
import { UserContext, UserContextState } from 'context/UserContext';

type DashboardPageInternalProps = {
    userContext: UserContextState,
    tenantContext: TenantContextState
}

export class DashboardInternal extends Component<DashboardPageInternalProps, {}> {
    render() {
        let tabInfos: TabInfo[] = [
            { name: "Overview", tabId: "0" },
            { name: "Usage", tabId: "1" }
       ];

        return (
            <>
                <h1>Dashboard</h1>
                
                <TabHeader tabInfos={tabInfos} >
                    <TabContent>
                        <TabPane tabId="0">
                            <TabDashboardOverview/>
                        </TabPane>
                        <TabPane tabId="1">
                            <TabDashboardUsage />
                        </TabPane>
                    </TabContent>
                </TabHeader>
            </>
        );
    }
}

// Inject the required contexts.
export const DashboardPage = (props: any) => {
    const userContext = useContext(UserContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <DashboardInternal userContext={userContext} tenantContext={tenantContext} {...props}/>
    )
}