import React, { Component, useContext } from 'react';
import { ActivityMeasurement } from './ActivityMeasurement';
import { UserContext, UserContextState } from 'context/UserContext';
import { urls, urlCombine, getBaseUriWithoutParameters } from 'logic/urls';

const logoutTimeoutMs = 15 * 60 * 1000;

type AutoLogoffInternalProps = {
    userContext: UserContextState,
    children?: React.ReactNode
};

export class AutoLogoffInternal extends Component<AutoLogoffInternalProps, {}> {
    handleActivityStateChange = (isActive: boolean) => {
        if (!isActive) {
            const redirectUrl = urlCombine(getBaseUriWithoutParameters(), urls.postLogout);
            this.props.userContext.logout(redirectUrl);
        }
    }

    render() {
        return (
            <ActivityMeasurement initialIsActive={true} onActivityStateChange={this.handleActivityStateChange} onSurfaceActivityTimeoutMs={logoutTimeoutMs} offSurfaceActivityTimeoutMs={logoutTimeoutMs}>
                {this.props.children}
            </ActivityMeasurement>
        )
    }
}

// Inject the required contexts.
export const AutoLogoff = (props: any) => {    
    const userContext = useContext(UserContext);

    return (
        <AutoLogoffInternal {...props} userContext={userContext} />
    )
}