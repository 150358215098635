// Base class for errors thrown in the application. Error can contain an inner error with is the reason this error was thrown.
export class AppError extends Error {
    innerError?: AppError;
  
    constructor(innerError?: AppError, ...params: any[]) {
      super(...params);
  
      this.name = "AppError";
      this.innerError = innerError;
    }
  
    toDetailedMessage(): string {
      // Walk up the inner error chain and aggregate the error messages.
      let innerErrorsAggregated = "";
      if (this && this.innerError) {
        let currentInnerError: AppError | undefined = this.innerError;
        while (currentInnerError) {
          const currentInnerErrorText = `\t${currentInnerError.name}: ${currentInnerError.message}\r\n`;
          innerErrorsAggregated = innerErrorsAggregated + currentInnerErrorText;
          currentInnerError = currentInnerError.innerError;
        }
      }
  
      let innerErrorText = innerErrorsAggregated.length > 0 ? `\r\nInner errors:\r\n${innerErrorsAggregated}` : "";
      return super.toString() + innerErrorText;
    }
  }
  
  // Error used when the retrieval of a token for an API failed.
  export class TokenRetrievalError extends AppError {
    constructor(innerError?: AppError, ...params: any[]) {
      super(innerError, ...params);
  
      this.name = "TokenRetrievalError";
      this.message = "Error occurred while retrieving an access token.";
    }
  }
  
  export class CommunicationError extends AppError {
    constructor(innerError?: AppError, ...params: any[]) {
      super(innerError, ...params);
  
      this.name = "CommunicationError";
      this.message = "Error occurred while communicating with the server.";
    }
  }

  
export class ServerErrorResponse extends AppError {
    statusCode: number;
    statusText: string;
    responseBody: any;
    
    constructor(innerError: AppError | undefined, statusCode: number, statusText: string, responseBody: any, ...params: any[]) {
        super(innerError, ...params);

        this.name = "ServerErrorResponse";
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.responseBody = responseBody;

        const messageDetail = responseBody ? `Error detail: ${responseBody}` : "";
        this.message = `Server responded with ${statusCode} '${statusText}'. ${messageDetail}`;
    }
}

export class InvalidOperationError extends AppError {
    constructor(innerError: AppError | undefined, description: string, ...params: any[]) {
        super(innerError, ...params);

        this.name = "InvalidOperationError";
        this.message = description;
    }
}