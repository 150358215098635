import { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { DoneButton } from 'components/DoneButton';
import { Connector, Tenant, TranscriptionLanguage } from 'logic/apiCalls';
import * as validation from 'utils/validation';
import { ConnectorEntry, ConnectorWithReadOnlyFlag } from './ConnectorEntry';

type LanguageOption = {
    value: string,
    label: string
}

type ModalTenantAddOrUpdateProps = {
    isOpen: boolean,
    tenantToUpdate: Tenant | null,
    transcriptionLanguages: TranscriptionLanguage[],
    connectorNames: string[],
    onToggle: ToggleCallBack,
    onAddOrUpdate: AddOrUpdateCallback
}

type ModalTenantAddOrUpdateState = {
    isUpdating: boolean,        
    tenantName: string,
    tenantId: string,
    tenantReference: string,
    retentionDays: string,
    isTranscriptionEnabled: boolean,
    selectedLanguageOption: LanguageOption | null,
    languageOptions: LanguageOption[],    
    connectors: ConnectorWithReadOnlyFlag[],
    isEnabled: boolean,
    isBusy: boolean
}

export type DoneInfo = {
    tenantName: string,
    tenantId: string,
    tenantReference: string,
    defaultRetentionSeconds: number,
    isTranscriptionEnabled: boolean,
    transcriptionLanguage: string,
    connectors: Connector[],
    isUpdate: boolean,
    isEnabled: boolean
}

export type ToggleCallBack = () => void

export type AddOrUpdateCallback = (doneInfo: DoneInfo) => void

export class ModalTenantAddOrUpdate extends Component<ModalTenantAddOrUpdateProps, ModalTenantAddOrUpdateState> {
    state : ModalTenantAddOrUpdateState = {
        isUpdating: false,        
        tenantName: "",
        tenantId: "",
        tenantReference: "",
        retentionDays: "",
        isTranscriptionEnabled: false,
        selectedLanguageOption: null,
        languageOptions: [],
        connectors: [],
        isEnabled: false,
        isBusy: false
    }

    componentDidUpdate(prevProps: ModalTenantAddOrUpdateProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const isUpdating = !!this.props.tenantToUpdate;
        
        const languageOptions = this.convertToLanguageOptions(this.props.transcriptionLanguages);
        const selectedLanguageOption = this.findSelectedOption(this.props.tenantToUpdate?.transcriptionLanguage || "", languageOptions);
        const connectors = this.createConnectors(this.props.tenantToUpdate?.connectors);
        
        this.setState(
            {
                isUpdating: isUpdating,
                tenantName: this.props.tenantToUpdate?.label || "",
                tenantId: this.props.tenantToUpdate?.id || "",
                tenantReference: this.props.tenantToUpdate?.reference || "",
                retentionDays: this.props.tenantToUpdate ? `${this.secondsToDays(this.props.tenantToUpdate?.defaultRetentionSeconds)}` : "",
                isTranscriptionEnabled: this.props.tenantToUpdate?.isTranscriptionEnabled || false,
                languageOptions: languageOptions,
                selectedLanguageOption: selectedLanguageOption,
                connectors: connectors,
                isEnabled: this.props.tenantToUpdate ? this.props.tenantToUpdate.enabled : true,
                isBusy: false
            }
        );
    }

    createConnectors = (existingConnectors: Connector[] | undefined) : ConnectorWithReadOnlyFlag[] => {
        if (existingConnectors) {
            // Create a deep clone of the connectors to prevent the original tenant info is modified by this modal.
            return existingConnectors.map(item => {
                return {
                    name: item.name,
                    enabled: item.enabled,
                    readonly: item.enabled
                }
            });
        }

        // Create the default list of connectors.
        return this.props.connectorNames.map(name => {
            return {
                name: name,
                enabled: false,
                readonly: false
            }
        });
    }

    convertToLanguageOptions = (transcriptionLanguages: TranscriptionLanguage[]) : LanguageOption[] => {
        const transcriptionLanguagesOptions: LanguageOption[] = [];        
        
        transcriptionLanguages.forEach(transcriptionLanguage => {
            const languageOption = {
                value:transcriptionLanguage.code, 
                label:transcriptionLanguage.displayName
            };

            transcriptionLanguagesOptions.push(languageOption);
        });

        return transcriptionLanguagesOptions;
    }

    findSelectedOption = (transcriptionLanguage: string, languageOptions : LanguageOption[]) : LanguageOption | null => {
        return languageOptions.find(item => item.value === transcriptionLanguage) || null;
    }

    handleDoneButtonClicked = () => {
        const doneInfo: DoneInfo = {
            tenantName: this.state.tenantName,
            tenantId: this.state.tenantId,
            tenantReference: this.state.tenantReference,
            defaultRetentionSeconds: this.daysToSeconds(parseInt(this.state.retentionDays)),
            isTranscriptionEnabled: this.state.isTranscriptionEnabled,
            transcriptionLanguage: this.state.selectedLanguageOption?.value || "en-US",
            connectors: this.state.connectors,
            isUpdate: this.state.isUpdating,
            isEnabled: this.state.isEnabled
        }

        this.setState({
            isBusy: true
        });

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.setState({            
        });
        this.props.onToggle();
    }

    handleTenantNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tenantName: e.target.value
        })
    }

    handleTenantIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tenantId: e.target.value
        })
    }

    handleReferenceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tenantReference: e.target.value
        })
    }

    handleRetenionDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue && !validation.isNumeric(newValue)) {
            return;
        }

        this.setState({
            retentionDays: newValue
        })
    }

    handleTranscriptionEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isTranscriptionEnabled: e.target.checked
        })
    }

    handleSelectedLanguageChange = (selectedLanguageOption: SingleValue<LanguageOption>) => {
        this.setState({
            selectedLanguageOption: selectedLanguageOption
        })
    }

    handleConnectorChange = (connector: Connector, value: boolean, index: number) => {
        console.log(`${connector.name} change value to ${value}...`)

        var connectors = [...this.state.connectors]
        connectors[index].enabled = value

        this.setState({
            connectors: connectors
        })
    }

    handleEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isEnabled: e.target.checked
        })
    }

    secondsToDays = (seconds: number | undefined) : number | undefined => {
        if (!seconds) {
            return seconds
        }

        return seconds / (24 * 60 * 60)
    }

    daysToSeconds = (days: number) : number => {
        return days * 24 * 60 * 60;
    }

    determineAddOrUpdateButtonEnabledState = () : boolean => {
        return !!this.state.tenantName && 
               validation.isNumeric(this.state.retentionDays) &&
               parseInt(this.state.retentionDays) >= 0 &&
               parseInt(this.state.retentionDays) < (365 * 10) &&
               validation.isValidGuid(this.state.tenantId) &&
               this.state.connectors.some(connector => connector.enabled) &&
               (!this.state.isTranscriptionEnabled || this.state.selectedLanguageOption != null)
    }

    determinAddOrUpdateButtonTooltip = () : string | undefined => {
        if (!this.state.tenantName) {  
            return "Tenant name is required";
        }

        if (!validation.isValidGuid(this.state.tenantId)) {
            return "Tenant ID is not valid";
        }

        if (!validation.isNumeric(this.state.retentionDays) || 
            parseInt(this.state.retentionDays) < 0 ||
            parseInt(this.state.retentionDays) >= (365 * 10)) {
            return "Retention must be between 1 and 3650 days";
        }

        if (this.state.isTranscriptionEnabled && !this.state.selectedLanguageOption) {
            return "Transcription language must be selected when transcription is enabled";
        }
        
        if (!this.state.connectors.some(connector => connector.enabled)) {
            return "At least one connector must be enabled";
        }

        return undefined;
    }

    renderTenantName = () => {
        return (
            <Form.Group controlId="tenantName">
                <Form.Label>Tenant name</Form.Label>
                <Form.Control autoComplete="off" type="text" placeholder="Enter tenant name" value={this.state.tenantName} onChange={this.handleTenantNameChanged} />
                <Form.Text className="text-muted">
                    This name is used as friendly name
                </Form.Text>
            </Form.Group>
        )
    }

    renderTenantId = () => {
        return (
            <Form.Group controlId="tenantId">
                <Form.Label>Tenant Id</Form.Label>
                <Form.Control autoComplete="off" disabled={this.state.isUpdating} type="text" placeholder="Azure AD tenant ID" value={this.state.tenantId} onChange={this.handleTenantIdChanged} />
                <Form.Text className="text-muted">
                    The ID of the tenant in Azure AD
                </Form.Text>
            </Form.Group>
        )
    }

    renderTenantReference = () => {
        return (
            <Form.Group controlId="tenantReference">
                <Form.Label>Reference</Form.Label>
                <Form.Control autoComplete="off" type="text" placeholder="Customer xyz" value={this.state.tenantReference} onChange={this.handleReferenceChanged} />
                <Form.Text className="text-muted">
                    Reference to your administration
                </Form.Text>
            </Form.Group>
        )
    }

    renderRetention = () => {
        return (
            <Form.Group controlId="retentionDays">
                <Form.Label>Retention</Form.Label>
                <Form.Control autoComplete="off" type="text" placeholder="Retention in days" value={this.state.retentionDays} onChange={this.handleRetenionDaysChange} />
                <Form.Text className="text-muted">
                    The number of days the recordings are kept
                </Form.Text>
            </Form.Group>
        )
    }

    renderTranscription = () => {
        return (
            <Form.Group controlId="transcriptionEnabled">
                <Form.Label>Transcription</Form.Label>
                <Row className="mb-3">
                    <Col sm={4}>
                        <Form.Text className="text-muted">
                            Enable
                        </Form.Text>
                    </Col>
                    <Col>
                        <Form.Switch id="transcriptionEnabled" type="switch" checked={this.state.isTranscriptionEnabled} onChange={this.handleTranscriptionEnabledChange} />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm={4}>
                        <Form.Text className="text-muted">
                            Transcription language
                        </Form.Text>
                    </Col>
                    <Col>
                        <Select menuPortalTarget={document.body} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isDisabled={!this.state.isTranscriptionEnabled} isSearchable={true} value={this.state.selectedLanguageOption} options={this.state.languageOptions} onChange={this.handleSelectedLanguageChange} placeholder='Select language...' />
                    </Col>
                </Row>
                <Form.Text className="text-muted">
                    Transcription of the recordings into searchable text
                </Form.Text>               
            </Form.Group>
        )
    }

    renderConnectors = () => {
        return (
            <Form.Group controlId="connectors">
                <Form.Label>Connectors</Form.Label>
                {this.state.connectors.map((connector, index) => <ConnectorEntry key={index} connector={connector} index={index} handleChange={this.handleConnectorChange} />)}
            </Form.Group>
        );
    }

    renderEnabled = () => {
        if (!this.props.tenantToUpdate) {
            return null;
        }

        return (<>           
            <Form.Group controlId="transcriptionEnabled">
                <Row>
                    <Col sm={4}>
                        <Form.Label>Enable</Form.Label>
                    </Col>
                    <Col>
                        <Form.Switch id="tenantEnabled" type="switch" checked={this.state.isEnabled} onChange={this.handleEnabledChange} />
                    </Col>
                </Row>
                <Form.Text className="text-muted">
                    No recording or replay is possible when the tenant is disabled.
                </Form.Text>
            </Form.Group>
        </>
        )
    }

    render() {
        const doneButtonText = this.state.isUpdating ? ' Update' : ' Add';

        const enableAddOrUpdateButton = this.determineAddOrUpdateButtonEnabledState();
        const addOrUpdateButtonTooltip = enableAddOrUpdateButton ? "" : this.determinAddOrUpdateButtonTooltip();

        return <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>Tenant info</ModalHeader>

            <ModalBody>
                <Form className="mt-3">
                    {this.renderTenantName()}
                    {this.renderTenantId()}
                    {this.renderTenantReference()}
                    {this.renderRetention()}
                    {this.renderTranscription()}
                    {this.renderConnectors()}
                    {this.renderEnabled()}
                </Form>
            </ModalBody>

            <ModalFooter>
                <DoneButton id="tenantDoneButton" buttonText={doneButtonText} disabled={!enableAddOrUpdateButton} disabledTooltip={addOrUpdateButtonTooltip} showBusy={this.state.isBusy} onClick={this.handleDoneButtonClicked} />                
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

