import { Component } from 'react';
import css from './SideMenuHeader.module.scss';

type SideMenuHeaderProps = {
    text: string
}

export class SideMenuHeader extends Component<SideMenuHeaderProps, {}> {
    render() {
        return (
            <div className={css.sideMenuHeader}>
                {this.props.text}
            </div>
        );
    }
}
