import { Component, ComponentType, useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { UserContext, UserContextState } from 'context/UserContext';
import { authenticationStates } from 'logic/authentication'
import { urls, getRelativeUrlWithParameters } from 'logic/urls';
import { SpinnerWithDescription } from './SpinnerWithDescription';
import log from 'loglevel';
import { isTrue } from 'utils/misc';

type ProtectedRouteInternalProps = {
    userContext: UserContextState,
    allowUnauthorized: boolean,
    component: ComponentType<any>
}

// Component handles the protection of the pages which are only accessible when the user is logged in and authorized to use the application.
// In case the user is not logged in the component will automatically start the (redirect) login process.
// When the login (authentication/authorization) is still in progress, the current state of the login process is shown.
// When the tenant is not authorized, a redirect is done to the 'tenantUnauthorized' page.
// When the user is not authorized, a redirect is done to the 'userUnauthorized' page.
// When all previous checks have passed, the tenant and user are authorized and a Route component is rendered.
export class ProtectedRouteInternal extends Component<ProtectedRouteInternalProps, {}> {
    componentDidMount() {
        this.redirectToMicrosoftIfNotLoggedIn();
    }

    componentDidUpdate() {
        this.redirectToMicrosoftIfNotLoggedIn();
    }

    redirectToMicrosoftIfNotLoggedIn = () => {
        if (this.isUserNotLoggedInOrAuthenticationError()) {
            // User has landed on a protected page while not logged-in. Start the login process automatically.
            const postRedirectUrl = getRelativeUrlWithParameters();

            this.props.userContext.login(null, postRedirectUrl);
        }
    }

    isUserNotLoggedInOrAuthenticationError = () => {
        return this.props.userContext.authenticationState === authenticationStates.NOT_AUTHENTICATED ||
        this.props.userContext.authenticationState === authenticationStates.AUTHENTICATION_ERROR;
    }

    render() {
        const userContext = this.props.userContext;
        const allowUnauthorizedAccess = isTrue(this.props.allowUnauthorized);        
        const isLoginInProgress = userContext.authenticationState === authenticationStates.LOGIN_IN_PROGRESS;

        log.info(`[ProtectedRoute, Render] AuthenticationState: ${userContext.authenticationState}, AuthorizationState: ${userContext.authorizationState}.`);

        if (isLoginInProgress || this.isUserNotLoggedInOrAuthenticationError())
        {
            log.info(`[ProtectedRoute, Render] Showing spinner...`);
            // In case the user is not logged in, the automatic redirect to the Microsoft login page will start soon.
            return <SpinnerWithDescription description="Waiting for redirect to Microsoft login..."/>
        }

        if (userContext.isAuthorizationInProgress()) {
            log.info(`[ProtectedRoute, Render] Showing checking user account...`);

            // When the authorization is in progress, the background logic is busy with exchanging the authentication-code
            // for and id/access token and invoking the back-end API to verify the authorization of the logged-in user.
            return <SpinnerWithDescription description="Checking user account..."/>
        }

        if (!allowUnauthorizedAccess) {
            if (!userContext.isTenantKnown()) {
                return <Redirect to={urls.tenantUnknown} />
            }

            if (!userContext.isTenantEnabled()) {
                return <Redirect to={urls.tenantDisabled} />
            }
    
            if (!userContext.isAccessAllowed()) {
                return <Redirect to={urls.userUnauthorized} />
            }           
        } else {
            // Note: Even though the page does not require an authorized user, the page still requires valid authentication.
            if (!userContext.isAuthenticated()) {
                log.info(`[ProtectedRoute, Render] Redirect to user unauthorized...`);

                // ToDo: Show some error page?
                return <Redirect to={urls.userUnauthorized} />
            }
        }
      
        if (allowUnauthorizedAccess || userContext.isAccessAllowed()) {
            return <Route {...this.props} component={this.props.component} />
        }
    }
}

export type ProtectedRouteProps = {
    allowUnauthorized: boolean,
    component: ComponentType<any>
}

// Inject the required contexts.
export const ProtectedRoute = (props: ProtectedRouteProps) => {
    const userContext = useContext(UserContext) as UserContextState;    

    return (
        <ProtectedRouteInternal userContext={userContext} allowUnauthorized={props.allowUnauthorized} component={props.component}/>
    )
}